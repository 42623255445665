import { computed } from 'vue';
import { useUserStore } from '@/store/userStore';

export function useRoleCheck() {
  const userStore = useUserStore();

  const isAdmin = computed(() => userStore.currentOrganization?.role === 'admin');
  const isMember = computed(() => userStore.currentOrganization?.role === 'member');

  const hasRole = (roles) => {
    const userRole = userStore.currentOrganization?.role;
    return Array.isArray(roles) ? roles.includes(userRole) : roles === userRole;
  };

  return {
    isAdmin,
    isMember,
    hasRole,
  };
}
