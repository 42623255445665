<template>
  <div class="toast-container">
    <transition-group name="toast-list">
      <Toast
        v-for="toast in toasts"
        :key="toast.id"
        :message="toast.message"
        :type="toast.type"
        :show="toast.show"
        @close="closeToast(toast.id)"
        @after-leave="removeToast(toast.id)"
      />
    </transition-group>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useToastStore } from '@/store/toastStore';
  import Toast from './Toast.vue';

  export default {
    components: { Toast },
    setup() {
      const toastStore = useToastStore();
      const toasts = computed(() => toastStore.toasts);

      const closeToast = (id) => {
        toastStore.closeToast(id);
      };

      const removeToast = (id) => {
        toastStore.removeToast(id);
      };

      return {
        toasts,
        closeToast,
        removeToast,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .toast-container {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }

  .toast-list-enter-active,
  .toast-list-leave-active {
    transition: all 0.3s ease;
  }

  .toast-list-enter-from,
  .toast-list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
