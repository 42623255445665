<template>
  <div class="oauth-callback">
    <p>Connecting Google Calendar...</p>
  </div>
</template>

<script>
  import { onMounted } from 'vue';

  export default {
    setup() {
      onMounted(() => {
        // Get the tokens from the URL
        const params = new URLSearchParams(window.location.search);
        const tokens = {
          access_token: params.get('access_token'),
          refresh_token: params.get('refresh_token'),
        };

        // Send tokens to parent window
        window.opener.postMessage(
          {
            type: 'googleCalendarCallback',
            tokens,
          },
          window.location.origin
        );
      });
    },
  };
</script>
