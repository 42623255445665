<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">
      {{ label }}
      <span v-if="info" class="form-group__info" v-tippy="{ content: info }">
        <BaseIcon name="info" size="14" />
      </span>
    </label>
    <input
      :type="type"
      :id="id"
      :value="modelValue"
      @input="handleInput"
      @blur="handleBlur"
      ref="inputRef"
      :disabled="disabled"
    />
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import { validateInput } from '@/utils/validation';
  import BaseIcon from '@/components/partials/BaseIcon.vue';

  export default {
    components: {
      BaseIcon,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'number',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      info: {
        type: String,
        default: null,
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const handleInput = (event) => {
        // touched.value = true;
        emit('update:modelValue', event.target.value);
        // if (props.realTimeValidation) {
        //   validate();
        // }
      };
      const handleBlur = () => {
        touched.value = true;
        // emit('blur');
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        handleInput,
        handleBlur,
        validate,
        localError,
      };
    },
  };
</script>

<style scoped></style>
