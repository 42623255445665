import axios from 'axios';
import { useUIStore } from '@/store/uiStore';

class UserService {
  async acceptInvite(token) {
    const uiStore = useUIStore();
    try {
      const response = await axios.get(`/public/verify-invite?token=${token}`);
      return response.data;
    } catch (error) {
      uiStore.addModal(null, {
        title: 'Invalid or expired invite',
        description: 'The invite you are trying to accept is invalid or has expired.',
      });
      throw error;
    }
  }

  async acceptNewInvite(token, userData) {
    try {
      const response = await axios.post(`/public/accept-invite/new?token=${token}`, userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default UserService;
