<template>
  <div
    class="avatar"
    :class="{
      ['avatar--size-' + size]: size,
      ['avatar--variant-color-' + variantColor]: variantColor,
    }"
    :style="{
      backgroundColor: color,
      color: textColor,
    }"
  >
    <img
      v-if="backgroundImage"
      :src="backgroundImage"
      alt="Avatar"
      referrerpolicy="no-referrer"
      crossorigin="anonymous"
    />
    {{ title }}
  </div>
</template>

<script>
  export default {
    name: 'AvatarElement',
    props: {
      title: {
        type: String,
      },
      backgroundImage: {
        type: String,
      },
      variantColor: {
        type: String,
        default: 'light',
      },
      size: {
        type: String,
        default: 'md',
      },
      color: {
        type: String,
      },
      textColor: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .avatar {
    border-radius: 50%;
    background-color: var(--brand-500);
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    &--variant-color {
      &-light {
        background-color: var(--brand-500);
      }
      &-gray {
        background-color: var(--slate-200);
        color: var(--slate-800);
      }
    }
    &--size {
      &-sm {
        width: 1.75rem;
        height: 1.75rem;
        font-size: 0.75rem;
      }
    }
  }
</style>
