import axios from 'axios';

class OrganizationService {
  async inviteMember(userData) {
    try {
      const response = await axios.post('/users/invite', userData);
      return response.data;
    } catch (error) {
      console.error('Error inviting user:', error);
      throw error;
    }
  }
  async getMembers(organizationId) {
    try {
      const response = await axios.get(`/organizations/${organizationId}/members`);
      return response.data;
    } catch (error) {
      console.error('Error fetching members:', error);
      throw error;
    }
  }

  async getInvites(organizationId) {
    try {
      const response = await axios.get(`/organizations/${organizationId}/invites`);
      return response.data;
    } catch (error) {
      console.error('Error fetching invites:', error);
      throw error;
    }
  }

  async resendInvite(organizationId, inviteId) {
    try {
      const response = await axios.post(
        `/organizations/${organizationId}/invites/${inviteId}/resend`
      );
      return response.data;
    } catch (error) {
      console.error('Error resending invite:', error);
      throw error;
    }
  }

  async update(organizationId, data) {
    try {
      const response = await axios.patch(`/organizations/${organizationId}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating organization:', error);
      throw error;
    }
  }
}

export default OrganizationService;
