import { useUserStore } from '@/store/userStore';

export const getDefaultDesign = () => {
  const userStore = useUserStore();
  return {
    id: null,
    name: '',
    font: 'Inter',
    font_id: null,
    colors: {
      background: {
        primary: '#e2e8f0',
      },
      brand: {
        primary: userStore.currentOrganization?.brand_settings?.primary_color || '#4338ca',
      },
      text: {
        default: '#0f172a',
        label: '#1e293b',
        element: '#334155',
      },
    },
    border_radius: 'medium',
    custom_css: '',
  };
};
