<template>
  <div class="app-wrapper" :class="pageClass">
    <router-view />
    <ModalHandler />
    <ToastHandler />
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import ModalHandler from '@/components/modal/ModalHandler.vue';
  import ToastHandler from '@/components/toast/ToastHandler.vue';

  export default {
    name: 'App',
    components: {
      ModalHandler,
      ToastHandler,
    },
    setup() {
      const route = useRoute();
      const pageClass = computed(() => {
        return `page-${route.name?.toLowerCase()}`;
      });

      return {
        pageClass,
      };
    },
  };
</script>

<style>
  .modal-backdrop-enter-active,
  .modal-backdrop-leave-active {
    transition: opacity 0.3s ease;
  }

  .modal-backdrop-enter,
  .modal-backdrop-leave-to {
    opacity: 0;
  }

  .modal-inner-enter-active,
  .modal-inner-leave-active {
    transition: transform 0.3s ease;
  }

  .modal-inner-enter,
  .modal-inner-leave-to {
    transform: translateY(100%);
  }
</style>
