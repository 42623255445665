<template>
  <button
    class="btn"
    :class="{
      'btn--loading': loading,
      ['btn--variant-' + variant]: variant,
      'btn--icon-only': isIconOnly,
    }"
    @click="handleClick"
    :disabled="isDisabled || loading"
  >
    <LoadingSpinner
      v-if="loading"
      :color="['primary', 'secondary'].includes(variant) ? 'light' : 'dark'"
    />
    <div class="btn__content">
      <slot>{{ text }}</slot>
      <BaseIcon v-if="icon" :name="icon" size="12" />
    </div>
  </button>
</template>

<script>
  import { computed } from 'vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  export default {
    name: 'ButtonEl',
    props: {
      text: {
        type: String,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
      },
      variant: {
        type: String,
        default: 'primary',
      },
    },
    components: {
      LoadingSpinner,
      BaseIcon,
    },
    emits: ['click'],
    setup(props, { emit, slots }) {
      const isDisabled = computed(() => (props.loading || props.disabled ? true : false));
      const hasSlot = computed(() => !!slots.default);
      const isIconOnly = computed(() => !props.text && !!hasSlot && !!props.icon);
      const handleClick = (event) => {
        emit('click', event);
      };
      return {
        isDisabled,
        handleClick,
        isIconOnly,
        hasSlot,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 6px;
    padding-bottom: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 500;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: var(--brand-500);
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    @include hover {
    }
    &__content {
      transition: padding 150ms ease-out;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .icon {
        margin-left: 0.5rem;
      }
    }
    &--loading {
      .btn__content {
        padding-left: 0.5rem;
      }
    }
    &--icon-only {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      .btn__content {
        .icon {
          margin: 0;
        }
      }
    }
    &:disabled {
      opacity: 0.8;
      pointer-events: none;
    }
    &--variant- {
      &primary {
        @include hover {
          background-color: var(--brand-600);
        }
      }
      &danger {
        background-color: var(--white);
        border-color: var(--slate-200);
        color: var(--red-500);
        @include hover {
          border-color: var(--red-500);
        }
      }
      &plain {
        background-color: transparent;
        color: var(--slate-500);
        box-shadow: none;
        @include hover {
          color: var(--slate-800);
        }
        padding-left: 0;
        padding-right: 0;
      }
      &secondary {
        background-color: var(--slate-800);
        color: var(--slate-100);
        box-shadow: none;
        @include hover {
          background-color: var(--slate-700);
        }
      }
      &tertiary {
        background-color: var(--slate-100);
        border-color: var(--slate-200);
        color: var(--slate-600);
        @include hover {
          color: var(--slate-800);
          background-color: var(--slate-200);
        }
      }
      &outlined {
        background-color: var(--white);
        color: var(--slate-600);
        border-color: var(--slate-300);
        box-shadow: none;
        @include hover {
          color: var(--slate-700);
          border-color: var(--slate-700);
        }
      }
      &success {
        background-color: var(--green-500);
        color: var(--slate-100);
      }
    }
  }
</style>
