<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">{{ label }}</label>
    <input
      :type="type"
      :id="id"
      :value="modelValue"
      :min="min"
      :max="max"
      @input="handleInput"
      @blur="handleBlur"
      ref="inputRef"
    />
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { validateInput } from '@/utils/validation';

  export default {
    name: 'DateInput',
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      min: {
        type: String,
        default: null,
      },
      max: {
        type: String,
        default: null,
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);
      const type = 'date';

      const handleInput = (event) => {
        touched.value = true;
        emit('update:modelValue', event.target.value);
        validate();
      };

      const handleBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        }
      );

      return {
        handleInput,
        handleBlur,
        localError,
        touched,
        type,
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-group {
    input[type='date'] {
      //   appearance: none;
      //   padding: 0.5rem;
      //   border: 1px solid var(--slate-300);
      //   border-radius: 0.25rem;
      //   width: 100%;
      //   font-size: 1rem;

      //   &:focus {
      //     outline: none;
      //     border-color: var(--brand-500);
      //     box-shadow: 0 0 0 1px var(--brand-500);
      //   }
    }
  }
</style>
