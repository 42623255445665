import axios from 'axios';

class GoogleCalendarService {
  constructor(accessToken) {
    this.accessToken = accessToken;
    this.baseUrl = 'https://www.googleapis.com/calendar/v3';
  }

  static async listCalendars(integration) {
    try {
      const response = await axios.get(`/integrations/google-calendar/${integration.id}/calendars`);
      return response.data;
    } catch (error) {
      console.error('Error fetching calendars:', error);
      throw error;
    }
  }
}

export default GoogleCalendarService;

/* <template>
  <div>
    <GoogleAuthButton 
      mode="calendar"
      :is-connected="hasCalendarAccess"
      @connected="handleCalendarConnected"
      @disconnect="handleCalendarDisconnect"
      @error="handleError"
    />

    <!-- Show calendars once connected -->
    <div v-if="hasCalendarAccess">
      <h3>Your Calendars</h3>
      <div v-if="loading">Loading...</div>
      <ul v-else>
        <li v-for="calendar in calendars" :key="calendar.id">
          {{ calendar.summary }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { GoogleCalendarService } from '@/services/googleCalendar';
import GoogleAuthButton from '@/components/oauth/GoogleAuthButton.vue';

export default {
  components: {
    GoogleAuthButton
  },

  setup() {
    const hasCalendarAccess = ref(false);
    const calendars = ref([]);
    const loading = ref(false);
    let calendarService = null;

    const handleCalendarConnected = async ({ tokens }) => {
      // Store tokens securely (preferably in your backend)
      localStorage.setItem('google_calendar_token', tokens.access_token);
      
      // Initialize calendar service
      calendarService = new GoogleCalendarService(tokens.access_token);
      hasCalendarAccess.value = true;
      
      // Load calendars
      await loadCalendars();
    };

    const handleCalendarDisconnect = () => {
      localStorage.removeItem('google_calendar_token');
      hasCalendarAccess.value = false;
      calendars.value = [];
      calendarService = null;
    };

    const loadCalendars = async () => {
      if (!calendarService) return;
      
      loading.value = true;
      try {
        const response = await calendarService.listCalendars();
        calendars.value = response.items;
      } catch (error) {
        console.error('Failed to load calendars:', error);
      } finally {
        loading.value = false;
      }
    };

    // Check for existing token on mount
    onMounted(() => {
      const token = localStorage.getItem('google_calendar_token');
      if (token) {
        calendarService = new GoogleCalendarService(token);
        hasCalendarAccess.value = true;
        loadCalendars();
      }
    });

    return {
      hasCalendarAccess,
      calendars,
      loading,
      handleCalendarConnected,
      handleCalendarDisconnect
    };
  }
};
</script> */
