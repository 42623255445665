<template>
  <div class="integration-setup-modal">
    <TextInput
      :id="inputs.name.id"
      :label="inputs.name.label"
      v-model="inputs.name.binding"
      :error="inputs.name.error"
      :rules="inputs.name.rules"
      placeholder="Enter integration name"
      @validate="onValidate('name', $event)"
    />
    <ToggleInput
      v-model="config.is_active"
      label="Active"
      id="is-active-toggle"
      :description="`Integration is ${config.is_active ? 'active' : 'inactive'}`"
      :disabled="!isEdit"
    />
    <template v-if="config.is_active">
      <div class="settings-section settings-section--integration">
        <h3>Integration Settings</h3>
        <template v-if="integration.type !== 'google_calendar'">
          <template v-for="(setting, key) in integrationType.settingsSchema" :key="key">
            <TextInput
              v-if="setting.type === 'string'"
              :id="`setting_${key}`"
              :label="setting.label"
              v-model="inputs[`settings.${key}`].binding"
              :error="inputs[`settings.${key}`].error"
              :rules="inputs[`settings.${key}`].rules"
              :placeholder="`Enter ${setting.label}`"
              @validate="onValidate(`settings.${key}`, $event)"
            />
            <NumberInput
              v-else-if="setting.type === 'number'"
              :id="`setting_${key}`"
              :label="setting.label"
              v-model="inputs[`settings.${key}`].binding"
              :error="inputs[`settings.${key}`].error"
              :rules="inputs[`settings.${key}`].rules"
              @validate="onValidate(`settings.${key}`, $event)"
            />
          </template>
        </template>
        <template v-else>
          <label :for="`setting_${integrationType.settingsSchema.connection.id}`">
            {{ integrationType.settingsSchema.connection.label }}
          </label>
          <GoogleAuthButton
            mode="calendar"
            :is-connected="hasCalendarAccess"
            @connected="handleCalendarConnected"
            @disconnect="handleCalendarDisconnect"
            @error="handleError"
          />
          <div v-if="hasCalendarAccess" class="mt-2">
            <label>Your calendars</label>
            <div v-if="loading"><LoadingSpinner size="sm" /></div>
            <div v-else class="calendar-list">
              <SelectInput
                :id="'calendar-select'"
                label="Select Calendar"
                :options="calendars.map((c) => ({ label: c.summary, value: c.id }))"
                v-model="inputs['settings.calendar_id'].binding"
                @update:model-value="
                  (value) => {
                    const calendar = calendars.find((c) => c.id === value);
                    inputs['settings.calendar_id'].binding = calendar.id;
                    inputs['settings.calendar_name'].binding = calendar.summary;
                  }
                "
                placeholder="Select a calendar"
              />
            </div>
          </div>
        </template>
      </div>
      <div v-if="integrationType.canAutoTrigger" class="settings-section settings-section--forms">
        <h3>Form Triggers</h3>
        <p>Selected forms that will automatically trigger this integration</p>
        <div class="connected-forms">
          <div v-if="loading" class="py-4 text-center">
            <LoadingSpinner size="sm" />
          </div>
          <template v-else>
            <div v-for="form in sortedForms" :key="form.id" class="form-trigger">
              <ToggleInput
                :id="`form-trigger-${form.id}`"
                :label="form.name"
                :model-value="pendingFormConnections[form.id]"
                @update:model-value="togglePendingConnection(form.id)"
                :disabled="!config.is_active"
              />
            </div>
            <div v-if="sortedForms.length === 0">No forms available</div>
          </template>
        </div>
        <div v-if="integrationType.canUseAutomation" class="settings-section__automation-note">
          This integration can also be used in custom automations for more complex workflows.
        </div>
      </div>
    </template>

    <div class="modal__actions">
      <Btn
        v-if="isEdit"
        text="Delete Integration"
        @click="deleteIntegration"
        variant="danger"
        class="mr-2"
      />
      <Btn
        :text="isEdit ? 'Save Changes' : 'Create Integration'"
        @click="handleSubmit"
        variant="secondary"
        :loading="loading"
        :disabled="loading"
      />
    </div>
  </div>
</template>

<script>
  import { ref, watch, computed, onMounted } from 'vue';
  import { useUIStore } from '@/store/uiStore';
  import { useIntegrationStore } from '@/store/integrationStore';
  import { useFormStore } from '@/store/formStore';
  import { useValidation } from '@/composables/useValidation';

  import TextInput from '@/components/inputs/TextInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  //   import NumberInput from '@/components/inputs/NumberInput.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  //   import Checkbox from '@/components/inputs/Checkbox.vue';
  import Btn from '@/components/partials/Btn.vue';

  import GoogleCalendarService from '@/services/calendarService';
  import GoogleAuthButton from '@/components/inputs/GoogleAuthButton.vue';

  export default {
    name: 'IntegrationSetupModal',

    components: {
      TextInput,
      SelectInput,
      ToggleInput,
      //   Checkbox,
      Btn,
      LoadingSpinner,
      GoogleAuthButton,
    },

    props: {
      integration: {
        type: Object,
        required: true,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },

    setup(props, { emit }) {
      const uiStore = useUIStore();
      const integrationStore = useIntegrationStore();
      const formStore = useFormStore();

      const hasCalendarAccess = ref(false);
      const calendars = ref([]);
      const loading = ref(false);
      let calendarService = null;

      const forms = computed(() => formStore.forms);
      const config = ref({ is_active: props.isEdit ? props.integration.is_active : true });

      const pendingFormConnections = ref({});

      // Get integration type definition
      const integrationType = computed(
        () => integrationStore.integrationTypes[props.integration.type]
      );

      // Initialize validation inputs
      const initializeValidationInputs = () => {
        const baseInputs = {
          name: {
            id: 'integration_name',
            label: 'Integration Name',
            binding: props.integration.name || '',
            error: '',
            rules: ['required', 'maxLength:255'],
          },
        };

        // Add settings fields dynamically
        const typeSettings = integrationType.value?.settingsSchema || {};
        Object.entries(typeSettings).forEach(([key, setting]) => {
          baseInputs[`settings.${key}`] = {
            id: `setting_${key}`,
            label: setting.label,
            binding: props.integration.settings?.[key] || '',
            error: '',
            rules: [
              ...(setting.required ? ['required'] : []),
              `type:${setting.type}`,
              ...(setting.maxLength ? [`maxLength:${setting.maxLength}`] : []),
            ],
            conditionalValidation: () => config.value.is_active,
          };
        });

        return baseInputs;
      };

      const { inputs, validateForm, onValidate } = useValidation(initializeValidationInputs());

      // Reset validation when integration changes
      watch(
        () => props.integration,
        () => {
          Object.keys(inputs.value).forEach((key) => {
            if (key === 'name') {
              inputs.value[key].binding = props.integration.name || '';
            } else if (key.startsWith('settings.')) {
              const settingKey = key.replace('settings.', '');
              inputs.value[key].binding = props.integration.settings?.[settingKey] || '';
            }
          });
        },
        { deep: true }
      );

      // Get sorted forms with connection status
      const sortedForms = computed(() => {
        return [...formStore.forms].sort((a, b) => {
          // Connected forms first
          const aConnected = isFormConnected(a.id);
          const bConnected = isFormConnected(b.id);
          if (aConnected !== bConnected) return bConnected ? 1 : -1;
          // Then alphabetically
          return a.name.localeCompare(b.name);
        });
      });

      // Initialize config with default values for new integration
      const initializeConfig = () => {
        const typeSettings = integrationType.value?.settingsSchema || {};

        // Create settings object with schema and values
        const settings = Object.keys(typeSettings).reduce((acc, key) => {
          acc[key] = {
            ...typeSettings[key], // Schema (type, required, label)
            value: props.integration.settings?.[key] || '', // Existing or empty value
          };
          return acc;
        }, {});

        return {
          id: props.integration.id || null,
          organizationId: props.integration.organizationId || null,
          name: props.integration.name || '',
          type: props.integration.type,
          settings,
          is_active: props.isEdit ? props.integration.is_active : true,
        };
      };

      // Reset config when integration prop changes
      watch(
        () => props.integration,
        () => {
          config.value = initializeConfig();
        },
        { deep: true }
      );

      // Check if form is connected to this integration
      const isFormConnected = (formId) => {
        return (
          props.integration.form_integrations?.find((fi) => fi.form_id === formId)?.enabled || false
        );
      };

      // Toggle pending connection (doesn't affect actual state yet)
      const togglePendingConnection = (formId) => {
        pendingFormConnections.value[formId] = !pendingFormConnections.value[formId];
      };

      const handleSubmit = async () => {
        try {
          if (!validateForm()) {
            return;
          }
          loading.value = true;
          const isValid = validateForm();

          if (!isValid) {
            loading.value = false;
            return;
          }

          // Convert validated inputs to submission format
          const submissionData = {
            name: inputs.value.name.binding,
            type: props.integration.type,
            is_active: config.value.is_active,
            settings: Object.keys(inputs.value)
              .filter((key) => key.startsWith('settings.'))
              .reduce((acc, key) => {
                const settingKey = key.replace('settings.', '');
                acc[settingKey] = inputs.value[key].binding;
                return acc;
              }, {}),
          };

          if (!props.isEdit) {
            submissionData.form_integrations = pendingFormConnections.value;
          }

          await emit('submit', submissionData);

          // Handle form connections for existing integrations
          if (props.isEdit && integrationType.value?.canAutoTrigger) {
            const updatePromises = Object.entries(pendingFormConnections.value)
              .filter(([formId, shouldBeEnabled]) => {
                const currentlyEnabled = isFormConnected(formId);
                shouldBeEnabled = Boolean(shouldBeEnabled);

                // Only include if there's an actual change
                const needsUpdate = currentlyEnabled !== shouldBeEnabled;
                return needsUpdate;
              })
              .map(([formId, shouldBeEnabled]) =>
                integrationStore.toggleIntegration(formId, props.integration.id, shouldBeEnabled)
              );

            if (updatePromises.length > 0) {
              await Promise.all(updatePromises);
            }
          }
        } catch (error) {
          console.error('Failed to submit integration:', error);
        } finally {
          loading.value = false;
        }
      };

      const deleteIntegration = () => {
        const modalId = uiStore.addModal(null, {
          title: 'Delete integration',
          description:
            'Are you sure you want to delete this integration? This action cannot be undone.',
          status: 'danger',
          onSubmit: async () => {
            emit('delete-integration', props.integration.id);
            uiStore.closeModal(modalId);
          },
        });
      };

      const handleCalendarConnected = async ({ tokens }) => {
        // Load calendars
        await loadCalendars();
      };

      const handleCalendarDisconnect = () => {
        localStorage.removeItem('google_calendar_token');
        hasCalendarAccess.value = false;
        calendars.value = [];
        calendarService = null;
      };

      const loadCalendars = async () => {
        if (!calendarService) return;

        loading.value = true;
        try {
          const response = await GoogleCalendarService.listCalendars(props.integration);
          calendars.value = response;
        } catch (error) {
          console.error('Failed to load calendars:', error);
        } finally {
          loading.value = false;
        }
      };

      // Load forms data on mount if needed
      onMounted(async () => {
        if (integrationType.value?.canAutoTrigger) {
          loading.value = true;
          try {
            await formStore.fetchForms();
            const formIntegrationsMap = (props.integration.form_integrations || []).reduce(
              (acc, integration) => {
                acc[integration.form_id] = integration.enabled;
                return acc;
              },
              {}
            );
            // Initialize pending connections with current state from the integration's formConnections
            sortedForms.value.forEach((form) => {
              pendingFormConnections.value[form.id] = formIntegrationsMap[form.id] || false;
            });
          } catch (error) {
            console.error('Failed to load data:', error);
          } finally {
            loading.value = false;
          }
        }
        if (props.integration.settings?.access_token) {
          calendarService = new GoogleCalendarService(props.integration.settings.access_token);
          hasCalendarAccess.value = true;
          loadCalendars();
        }
      });

      return {
        inputs,
        config,
        integrationType,
        forms,
        sortedForms,
        loading,
        isEdit: props.isEdit,
        onValidate,
        handleSubmit,
        deleteIntegration,
        isFormConnected,
        pendingFormConnections,
        togglePendingConnection,
        hasCalendarAccess,
        calendars,
        handleCalendarConnected,
        handleCalendarDisconnect,
      };
    },
  };
</script>

<style scoped lang="scss">
  .integration-setup-modal {
  }
  .modal__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .btn:only-child {
      margin-left: auto;
    }
  }
  .settings-section {
    margin: 1.5rem 0;
    &__automation-note {
      font-size: 0.875rem;
      color: var(--slate-700);
      font-style: italic;
      margin-top: 1.5rem;
    }
  }

  .oauth-setting {
    margin: 1rem 0;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    .oauth-status {
      display: flex;
      align-items: center;
      gap: 1rem;

      .connected-status {
        color: var(--green-600);
        font-weight: 500;
      }
    }
  }
</style>
