import { ref, reactive, computed } from 'vue';
import { validateInput, validateNestedInput } from '@/utils/validation.js';

export function useValidation(config) {
  const validationState = reactive({
    isValid: true,
    errors: new Map(),
    touched: new Set(),
    isDirty: false,
  });

  const inputs = ref(config);

  // Validate a single field
  const validateField = async (fieldName, value, rules) => {
    if (!rules) return null;

    // Handle nested validation
    if (typeof rules === 'object' && (rules._self || rules._each)) {
      const errors = validateNestedInput(value, rules);
      // Update the errors in the input structure
      if (errors && Array.isArray(value)) {
        value.forEach((item, index) => {
          if (errors[index]) {
            Object.entries(errors[index]).forEach(([field, error]) => {
              if (!item.error) item.error = {};
              item.error[field] = error;
            });
          } else {
            item.error = ''; // Set empty string if no error
          }
        });
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          item.error = '';
        });
      }

      return errors;
    }

    // Regular validation
    return validateInput(value, rules);
  };

  // Mark a field as touched
  const markTouched = (fieldName) => {
    validationState.touched.add(fieldName);
    validationState.isDirty = true;
  };

  // Check if a field is touched
  const isTouched = (fieldName) => validationState.touched.has(fieldName);
  // Validate the entire form
  const validateForm = async (show_errors = true) => {
    let isValid = true;
    for (const [fieldName, field] of Object.entries(inputs.value)) {
      if (field.rules) {
        const error = await validateField(fieldName, field.binding, field.rules);

        if (error) {
          if (show_errors) {
            // Handle nested errors
            if (Array.isArray(error)) {
              field.binding.forEach((item, index) => {
                if (error[index]) {
                  item.error = error[index];
                }
              });
            } else {
              field.error = error;
            }
          }
          validationState.errors.set(fieldName, error);
          isValid = false;
        } else {
          field.error = '';
          validationState.errors.delete(fieldName);
        }
      }
    }

    validationState.isValid = isValid;
    return isValid;
  };

  // Handle validation for a specific field
  const onValidate = async (fieldName, value) => {
    const field = inputs.value[fieldName];
    if (!field) return;

    const error = validateInput(value, field.rules);
    if (error) {
      validationState.errors.set(fieldName, error);
    } else {
      field.error = '';
      validationState.errors.delete(fieldName);
    }

    validationState.isValid = validationState.errors.size === 0;
    return error;
  };

  // Reset validation state
  const resetValidation = () => {
    validationState.errors.clear();
    validationState.touched.clear();
    validationState.isValid = true;
    validationState.isDirty = false;
  };

  // Computed properties for validation state
  const hasErrors = computed(() => validationState.errors.size > 0);
  const isDirty = computed(() => validationState.isDirty);
  const isValid = computed(() => validationState.isValid);

  return {
    inputs,
    validationState,
    validateField,
    validateForm,
    onValidate,
    markTouched,
    isTouched,
    resetValidation,
    hasErrors,
    isDirty,
    isValid,
  };
}
