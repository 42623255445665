<template>
  <div class="google-auth-button">
    <div v-if="isConnected" class="oauth-status">
      <span class="connected-status">✓ Connected with Google</span>
      <Btn text="Disconnect" variant="danger" size="sm" @click="handleDisconnect" />
    </div>
    <Btn
      v-else
      :id="buttonId"
      :text="buttonText"
      icon="google"
      @click="handleConnect"
      variant="tertiary"
      size="md"
      :loading="loading"
      class="width-100"
    />
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { supabase } from '@/services/supabase';
  import IntegrationService from '@/services/integrationService';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'GoogleAuthButton',

    components: {
      Btn,
    },

    props: {
      mode: {
        type: String,
        required: true,
        validator: (value) => ['calendar', 'auth'].includes(value),
      },
      buttonId: {
        type: String,
        default: 'google-auth-button',
      },
      isConnected: {
        type: Boolean,
        default: false,
      },
    },

    setup(props, { emit }) {
      const loading = ref(false);

      const buttonText = computed(() => {
        if (props.mode === 'calendar') {
          return 'Connect Google Calendar';
        }
        return 'Sign in with Google';
      });

      const handleConnect = async () => {
        loading.value = true;
        try {
          if (props.mode === 'calendar') {
            // Get the OAuth URL from backend
            const response = await IntegrationService.connectGoogleCalendar();
            const { url } = response;

            const authWindow = window.open(url, 'google-calendar-auth', 'width=600,height=600');

            if (authWindow) {
              const checkWindow = setInterval(() => {
                if (authWindow.closed) {
                  clearInterval(checkWindow);
                  window.location.reload();
                }
              }, 500);
            }
          } else {
            // Regular auth remains unchanged
            const { data, error } = await supabase.auth.signInWithOAuth({
              provider: 'google',
              options: {
                redirectTo: `${window.location.origin}/oauth/google-callback`,
                queryParams: {
                  access_type: 'offline',
                  prompt: 'consent',
                },
              },
            });
            if (error) throw error;
            emit('connected', data);
          }
        } catch (error) {
          console.error('OAuth error:', error);
          emit('error', error);
        } finally {
          loading.value = false;
        }
      };

      const handleDisconnect = async () => {
        loading.value = true;
        try {
          if (props.mode === 'calendar') {
            // Revoke calendar access
            emit('disconnect');
          } else {
            // Sign out from Supabase
            const { error } = await supabase.auth.signOut();
            if (error) throw error;
            emit('disconnect');
          }
        } catch (error) {
          console.error('Disconnect error:', error);
          emit('error', error);
        } finally {
          loading.value = false;
        }
      };

      return {
        loading,
        buttonText,
        handleConnect,
        handleDisconnect,
      };
    },
  };
</script>

<style scoped lang="scss">
  .google-auth-button {
    .oauth-status {
      display: flex;
      align-items: center;
      gap: 1rem;

      .connected-status {
        color: var(--green-600);
        font-weight: 500;
      }
    }
  }
</style>

<!-- For authentication -->
<!-- <GoogleAuthButton 
  mode="auth"
  @connected="handleAuthSuccess"
  @error="handleAuthError"
/>

<GoogleAuthButton 
  mode="calendar"
  :is-connected="hasCalendarAccess"
  @connected="handleCalendarConnected"
  @disconnect="handleCalendarDisconnect"
  @error="handleError"
/> -->
