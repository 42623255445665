<template>
  <div class="settings-page">
    <div class="settings-container">
      <div class="settings-sidebar">
        <div class="settings-tabs">
          <button
            :class="['tab', { active: activeTab === 'appearance' }]"
            @click="activeTab = 'appearance'"
          >
            Appearance
          </button>
          <button
            :class="['tab', { active: activeTab === 'behaviour' }]"
            @click="activeTab = 'behaviour'"
          >
            Behaviour
          </button>
          <button
            :class="['tab', { active: activeTab === 'notifications' }]"
            @click="activeTab = 'notifications'"
          >
            Notifications
          </button>
          <button
            :class="['tab', { active: activeTab === 'language' }]"
            @click="activeTab = 'language'"
          >
            Language
          </button>
        </div>
      </div>

      <div class="settings-content">
        <div v-if="activeTab === 'appearance'" class="form-section">
          <h2>Appearance</h2>

          <ToggleInput
            :id="inputs.displayFormTitle.id"
            :label="inputs.displayFormTitle.label"
            v-model="inputs.displayFormTitle.binding"
            :error="inputs.displayFormTitle.error"
            :rules="inputs.displayFormTitle.rules"
            @validate="onValidate('displayFormTitle', $event)"
          />

          <ToggleInput
            :id="inputs.displayPageTitle.id"
            :label="inputs.displayPageTitle.label"
            v-model="inputs.displayPageTitle.binding"
            :error="inputs.displayPageTitle.error"
            :rules="inputs.displayPageTitle.rules"
            @validate="onValidate('displayPageTitle', $event)"
          />

          <ToggleInput
            :id="inputs.displayPageTabs.id"
            :label="inputs.displayPageTabs.label"
            v-model="inputs.displayPageTabs.binding"
            :error="inputs.displayPageTabs.error"
            :rules="inputs.displayPageTabs.rules"
            @validate="onValidate('displayPageTabs', $event)"
          />

          <ToggleInput
            :id="inputs.displayProgress.id"
            :label="inputs.displayProgress.label"
            v-model="inputs.displayProgress.binding"
            :error="inputs.displayProgress.error"
            :rules="inputs.displayProgress.rules"
            @validate="onValidate('displayProgress', $event)"
          />

          <div class="form-row">
            <SelectInput
              label="Page progress position"
              v-model="inputs.progressPosition.binding"
              :options="[
                { label: 'Top of form', value: 'top' },
                { label: 'Bottom of form', value: 'bottom' },
              ]"
              @validate="onValidate('progressPosition', $event)"
            />
          </div>
        </div>

        <div v-if="activeTab === 'behaviour'" class="form-section">
          <h2>Behaviour</h2>

          <div class="form-row">
            <SelectInput
              label="Action on submit"
              v-model="inputs.submitAction.binding"
              :options="[
                { label: 'Redirect to URL', value: 'redirect' },
                { label: 'Display Message', value: 'message' },
              ]"
              @validate="onValidate('progressPosition', $event)"
            />
          </div>

          <div v-if="inputs.submitAction.binding === 'redirect'" class="form-row">
            <TextInput
              :id="inputs.redirectUrl.id"
              :label="inputs.redirectUrl.label"
              v-model="inputs.redirectUrl.binding"
              :error="inputs.redirectUrl.error"
              :rules="inputs.redirectUrl.rules"
              :realTimeValidation="true"
              @validate="onValidate('redirectUrl', $event)"
              placeholder="https://example.com"
            />
          </div>

          <div class="validation-settings">
            <h3>Validation</h3>

            <div class="form-row">
              <ToggleInput
                :id="inputs.validateOnSubmit.id"
                :label="inputs.validateOnSubmit.label"
                v-model="inputs.validateOnSubmit.binding"
                :error="inputs.validateOnSubmit.error"
                :rules="inputs.validateOnSubmit.rules"
                @validate="onValidate('validateOnSubmit', $event)"
              />
            </div>

            <div class="form-row">
              <ToggleInput
                :id="inputs.validateWhileTyping.id"
                :label="inputs.validateWhileTyping.label"
                v-model="inputs.validateWhileTyping.binding"
                :error="inputs.validateWhileTyping.error"
                :rules="inputs.validateWhileTyping.rules"
                @validate="onValidate('validateWhileTyping', $event)"
              />
            </div>

            <TextInput
              :id="inputs.errorMessage.id"
              :label="inputs.errorMessage.label"
              v-model="inputs.errorMessage.binding"
              :error="inputs.errorMessage.error"
              :rules="inputs.errorMessage.rules"
              :realTimeValidation="true"
              @validate="onValidate('errorMessage', $event)"
              placeholder="Please fix the errors before continuing"
            />
          </div>
        </div>

        <div v-if="activeTab === 'notifications'" class="form-section">
          <h2>Notifications</h2>

          <ToggleInput
            :id="inputs.notificationsEnabled.id"
            :label="inputs.notificationsEnabled.label"
            v-model="inputs.notificationsEnabled.binding"
            :error="inputs.notificationsEnabled.error"
            :rules="inputs.notificationsEnabled.rules"
            @validate="onValidate('notificationsEnabled', $event)"
          />
          <TextInput
            :id="inputs.notificationRecipients.id"
            :label="inputs.notificationRecipients.label"
            v-model="inputs.notificationRecipients.binding"
            :error="inputs.notificationRecipients.error"
            :rules="inputs.notificationRecipients.rules"
            @validate="onValidate('notificationRecipients', $event)"
          />
        </div>
        <div v-if="activeTab === 'language'" class="form-section">
          <h2>Language</h2>

          <div class="form-row">
            <TextInput
              :id="inputs.nextButtonText.id"
              :label="inputs.nextButtonText.label"
              v-model="inputs.nextButtonText.binding"
              :error="inputs.nextButtonText.error"
              :rules="inputs.nextButtonText.rules"
              @validate="onValidate('nextButtonText', $event)"
            />
            <TextInput
              :id="inputs.prevButtonText.id"
              :label="inputs.prevButtonText.label"
              v-model="inputs.prevButtonText.binding"
              :error="inputs.prevButtonText.error"
              :rules="inputs.prevButtonText.rules"
              @validate="onValidate('prevButtonText', $event)"
            />
            <TextInput
              :id="inputs.submitButtonText.id"
              :label="inputs.submitButtonText.label"
              v-model="inputs.submitButtonText.binding"
              :error="inputs.submitButtonText.error"
              :rules="inputs.submitButtonText.rules"
              @validate="onValidate('submitButtonText', $event)"
            />
          </div>
        </div>
        <div class="settings-footer">
          <Btn
            :text="saving ? 'Saving...' : 'Save Changes'"
            @click="saveSettings"
            :disabled="!hasChanges"
            :loading="saving"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, computed, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import { useValidation } from '@/composables/useValidation';
  import { debounce } from 'lodash';
  import TextInput from '@/components/inputs/TextInput.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    components: {
      TextInput,
      ToggleInput,
      Btn,
      SelectInput,
    },
    setup() {
      const route = useRoute();
      const formStore = useFormStore();
      const formId = route.params.id;
      const currentForm = computed(() => formStore.currentForm);
      const programmaticUpdate = ref(false);

      const { inputs, validateForm, onValidate, markTouched } = useValidation({
        displayFormTitle: {
          id: 'displayFormTitle',
          label: 'Display form title',
          binding: false,
          error: null,
          rules: [],
          touched: false,
        },
        displayPageTitle: {
          id: 'displayPageTitle',
          label: 'Display page title',
          binding: false,
          error: null,
          rules: [],
          touched: false,
        },
        displayPageTabs: {
          id: 'displayPageTabs',
          label: 'Display page tabs',
          binding: true,
          error: null,
          rules: [],
          touched: false,
        },
        displayProgress: {
          id: 'displayProgress',
          label: 'Display progress',
          binding: true,
          error: null,
          rules: [],
          touched: false,
        },
        progressPosition: {
          id: 'progressPosition',
          label: 'Progress position',
          binding: 'top',
          error: null,
          rules: ['required'],
          touched: false,
        },
        submitAction: {
          id: 'submitAction',
          label: 'Submit action',
          binding: 'message',
          error: null,
          rules: ['required'],
          touched: false,
        },
        redirectUrl: {
          id: 'redirectUrl',
          label: 'Redirect URL',
          binding: '',
          error: null,
          rules: ['url'],
          touched: false,
        },
        validateOnSubmit: {
          id: 'validateOnSubmit',
          label: 'Validate on submit',
          binding: true,
          error: null,
          rules: [],
          touched: false,
        },
        validateWhileTyping: {
          id: 'validateWhileTyping',
          label: 'Validate while typing',
          binding: false,
          error: null,
          rules: [],
          touched: false,
        },
        errorMessage: {
          id: 'errorMessage',
          label: 'Error message',
          binding: 'Please fix the errors before continuing',
          error: null,
          rules: ['required'],
          touched: false,
        },
        notificationsEnabled: {
          id: 'notificationsEnabled',
          label: 'Enable notifications',
          binding: true,
          error: null,
          rules: [],
          touched: false,
        },
        notificationRecipients: {
          id: 'notificationRecipients',
          label: 'Notification recipients (separate by comma)',
          binding: '',
          error: null,
          rules: [],
          touched: false,
        },
        nextButtonText: {
          id: 'nextButtonText',
          label: 'Next button text',
          binding: 'Next',
          error: null,
          rules: [],
          touched: false,
        },
        prevButtonText: {
          id: 'prevButtonText',
          label: 'Previous button text',
          binding: 'Previous',
          error: null,
          rules: [],
          touched: false,
        },
        submitButtonText: {
          id: 'submitButtonText',
          label: 'Submit button text',
          binding: 'Submit',
          error: null,
          rules: [],
          touched: false,
        },
      });

      const saving = ref(false);
      const hasChanges = ref(false);
      const activeTab = ref('appearance');
      const originalSettings = ref({});

      const initializeSettings = () => {
        programmaticUpdate.value = true;
        const currentSettings = currentForm.value?.settings || {};
        Object.keys(inputs.value).forEach((key) => {
          if (currentSettings[key] !== undefined) {
            // Convert array to comma-separated string for notificationRecipients
            if (key === 'notificationRecipients' && Array.isArray(currentSettings[key])) {
              inputs.value[key].binding = currentSettings[key].join(', ');
            } else {
              inputs.value[key].binding = currentSettings[key];
            }
          }
        });

        originalSettings.value = JSON.parse(
          JSON.stringify(
            Object.keys(inputs.value).reduce((acc, key) => {
              acc[key] = inputs.value[key].binding;
              return acc;
            }, {})
          )
        );
        programmaticUpdate.value = false;
      };

      // Watch for changes to the form and initialize settings
      watch(currentForm, () => {
        if (currentForm.value) {
          initializeSettings();
        }
      });

      const saveSettings = async () => {
        saving.value = true;
        try {
          // Format settings to simple key-value pairs
          const formattedSettings = Object.keys(inputs.value).reduce((acc, key) => {
            // Convert comma-separated string to array for notificationRecipients
            if (key === 'notificationRecipients') {
              acc[key] = inputs.value[key].binding
                .split(',')
                .map((email) => email.trim())
                .filter((email) => email !== '');
            } else {
              acc[key] = inputs.value[key].binding;
            }
            return acc;
          }, {});

          await formStore.updateFormSettings(formId, formattedSettings);
          console.log(formStore.currentForm);

          originalSettings.value = JSON.parse(JSON.stringify(inputs.value));
          hasChanges.value = false;
        } catch (error) {
          console.error('Failed to save settings:', error);
        } finally {
          saving.value = false;
        }
      };

      // Watch for changes in settings
      watch(
        inputs,
        () => {
          if (!programmaticUpdate.value) {
            const currentValues = Object.keys(inputs.value).reduce((acc, key) => {
              acc[key] = inputs.value[key].binding;
              return acc;
            }, {});

            hasChanges.value =
              JSON.stringify(currentValues) !== JSON.stringify(originalSettings.value);
          }
        },
        { deep: true }
      );

      onMounted(() => {
        initializeSettings();
      });

      return {
        inputs,
        activeTab,
        hasChanges,
        saving,
        onValidate,
        saveSettings,
      };
    },
  };
</script>

<style scoped lang="scss">
  .settings-page {
    display: flex;
    padding: 2rem;
  }
  .settings-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 100%;
    min-height: calc(100vh - 4rem);
    max-width: 800px;
    margin: 0 auto;
  }

  .settings-sidebar {
    width: 240px;
    border-right: 1px solid #eee;
    padding: 2rem 0;
  }

  .settings-tabs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
  }
  .tab {
    padding: 0.75rem 1rem;
    text-align: left;
    background: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
    color: #666;
    transition: all 0.2s ease;

    &:hover {
      background: #f5f5f5;
      color: #333;
    }

    &.active {
      background: #f0f7ff;
      color: #0066cc;
      font-weight: 500;
    }
  }

  .settings-content {
    flex: 1;
    padding: 2rem;
    position: relative;
  }

  .settings-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .form-row {
    padding: 1rem 0;
    border-bottom: 1px solid var(--slate-200);
  }

  .settings-footer {
    padding: 1rem;
    background: white;
    border-top: 1px solid #eee;
    text-align: right;
    margin-top: 2rem;
  }
</style>
