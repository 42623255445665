<template>
  <div class="form-card">
    <h2 class="form-card__title">
      {{ form.name }}
    </h2>
    <div class="form-card__subtitle">
      {{ formatDate(form.created_at) }}
    </div>
    <div class="form-card__actions">
      <Btn
        text="Edit flow"
        @click="router.push(`/flow/${form.id}`)"
        variant="secondary"
        :disabled="!isAdmin"
      />
      <BaseIcon
        name="trash"
        @click="deleteForm"
        size="12"
        class="form-card__delete"
        v-if="isAdmin"
      />
    </div>
  </div>
</template>

<script>
  import { useRoleCheck } from '@/composables/useRoleCheck';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import useDayJs from '@/utils/useDayJs';
  import { formatDate } from '@/utils/date';
  import Btn from '@/components/partials/Btn.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  export default {
    name: 'FormCard',
    components: {
      Btn,
      BaseIcon,
    },
    props: {
      form: {
        type: String,
      },
    },
    setup(props) {
      const router = useRouter();
      const formStore = useFormStore();
      const uiStore = useUIStore();
      const { isAdmin } = useRoleCheck();
      const deleteForm = () => {
        const modalId = uiStore.addModal(null, {
          title: 'Delete flow',
          description: 'Are you sure you want to delete this flow? This action cannot be undone.',
          status: 'danger',
          onSubmit: async () => {
            await formStore.deleteForm(props.form.id);
            uiStore.closeModal(modalId);
          },
        });
      };

      return {
        router,
        deleteForm,
        formatDate,
        isAdmin,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .form-card {
    position: relative;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    &__title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
    &__subtitle {
      color: var(--slate-500);
    }
    &__actions {
      display: flex;
      // justify-content: flex-end;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;
    }
    &__delete {
      color: var(--slate-500);
      cursor: pointer;
      @include hover {
        color: var(--red-500);
      }
    }
  }
</style>
