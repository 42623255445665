<template>
  <div class="sf-el single-choice-input">
    <div class="sf-single-choice">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      <div
        class="sf-single-choice__grid"
        :class="{ [`sf-single-choice__grid--display-${display}`]: display }"
      >
        <div
          v-for="(choice, index) in choices"
          :key="index"
          class="choice"
          @click="handleInput(choice.id)"
          :class="{ 'is-selected': modelValue === choice.id }"
        >
          <input
            type="radio"
            :name="name"
            :value="choice.id"
            v-model="selectedChoice"
            class="radio-button"
            :id="`${choice.id}-choice-${index}`"
          />
          <label :for="`${choice.id}-choice-${index}`">
            <img :src="choice.image" class="choice__img" alt="Choice Image" v-if="choice.image" />
            <div class="choice__label">
              <div class="choice__label-content">
                <span class="choice__title">
                  {{ choice.content }}
                </span>
                <span class="choice__description" v-if="choice.description">
                  {{ choice.description }}
                </span>
              </div>
              <div class="choice__radio">
                <BaseIcon name="check" size="16" />
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '../../BaseIcon.vue';
  import { watch, ref } from 'vue';
  export default {
    name: 'SingleChoiceElement',
    components: {
      BaseIcon,
    },
    props: {
      label: String,
      description: String,
      choices: Array,
      name: String,
      modelValue: String,
      display: [String, Number],
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const handleInput = (value) => {
        emit('update:modelValue', value);
      };
      // watch(selectedChoice, (newValue) => {
      //   emit('update:modelValue', newValue);
      // });
      return {
        handleInput,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@shared/assets/css/base/mixins.scss';
  @import './styles/choices.scss';
</style>
