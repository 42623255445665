<template>
  <div class="tabs">
    <div class="tab-headers">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        :class="['tab-header', { active: tab.name === activeTab }]"
        @click="selectTab(tab.name)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>

<script>
  import { ref, provide, watch } from 'vue';

  export default {
    name: 'TabsPanel',
    props: {
      modelValue: {
        type: String,
        required: true,
      },
    },
    setup(props, { emit }) {
      const activeTab = ref(props.modelValue);
      const tabs = ref([]);

      const selectTab = (tabName) => {
        activeTab.value = tabName;
        emit('update:modelValue', tabName);
      };

      const addTab = (tab) => {
        tabs.value.push(tab);
      };

      provide('addTab', addTab);
      provide('activeTab', activeTab);

      watch(
        () => props.modelValue,
        (newVal) => {
          activeTab.value = newVal;
        }
      );

      return {
        activeTab,
        tabs,
        selectTab,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .tabs {
    display: flex;
    flex-direction: column;
    @include fontSize('sm');
  }
  .tab-headers {
    display: flex;
    border-bottom: 1px solid var(--slate-200);
  }
  .tab-header {
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }
  .tab-header.active {
    border-bottom: 2px solid var(--brand-500);
  }
  .tab-content {
    padding: 1rem 0;
  }
</style>
