<template>
  <div class="send-proposal">
    <div class="row">
      <div class="col-lg-6">
        <div class="proposal-preview">
          <ProposalPreview :proposal="publicProposal" />
        </div>
      </div>
      <div class="col-lg-5 col-lg-offset-1">
        <div v-if="publicProposal?.status === 'accepted'">
          <div class="send-proposal__accepted">
            <p>Your proposal has been accepted!</p>
            <Btn text="View proposal" @click="handleViewProposal" variant="secondary" />
          </div>
          <div class="send-proposal__info">
            <ul>
              <li>
                <span>Sent at:</span>
                <span>{{ formatDate(publicProposal?.sent_at) }}</span>
              </li>
              <li>
                <span>Accepted at:</span>
                <span>{{ formatDate(publicProposal?.accepted_at) }}</span>
              </li>
              <li>
                <span>Accepted by:</span>
                <span>
                  {{ publicProposal?.signature_data?.signer_name }} ({{
                    publicProposal?.signature_data?.signer_email
                  }})
                </span>
              </li>
              <li>
                <span>IP Address:</span>
                <span>{{ publicProposal?.signature_data?.ip_address }}</span>
              </li>
            </ul>
            <div class="signature-preview">
              <img :src="publicProposal?.signature_data?.signature" alt="Signature" />
            </div>
          </div>
        </div>
        <div v-else-if="publicProposal?.sent_at && publicProposal?.status !== 'draft'">
          <div class="send-proposal__sent">
            <p>Proposal has been sent at {{ formatDate(publicProposal?.sent_at) }}.</p>
            <div class="send-proposal-link__copy">
              <input
                type="text"
                readonly
                :value="publicProposal?.sent_at ? formLink : ''"
                :placeholder="
                  !publicProposal?.sent_at ? 'Share your proposal to get a shareable link' : ''
                "
                ref="linkInput"
              />
              <Btn
                icon="copy"
                text="Copy"
                @click.prevent="copyLink"
                variant="secondary"
                :disabled="!publicProposal?.sent_at"
              />
            </div>
          </div>
          <Btn
            text="Resend proposal"
            @click="showResendForm = true"
            variant="secondary"
            icon="redo"
            v-if="!showResendForm"
            class="mt-2"
          />
        </div>
        <form
          v-if="publicProposal?.status === 'draft' || showResendForm"
          @submit.prevent="handleReviewAndSend"
        >
          <TextInput
            :label="inputs.recipients.label"
            v-model="inputs.recipients.binding"
            placeholder="Enter email addresses separated by commas"
            :error="inputs.recipients.error"
          />

          <TextInput
            :label="inputs.emailSubject.label"
            v-model="inputs.emailSubject.binding"
            placeholder="Enter email subject"
            :error="inputs.emailSubject.error"
          />

          <WysiwygEditor
            :label="inputs.emailContent.label"
            v-model="inputs.emailContent.binding"
            placeholder="Enter email content"
            :rows="5"
            :error="inputs.emailContent.error"
          />
          <div class="form-actions">
            <Btn
              :text="publicProposal?.sent_at ? 'Resend proposal' : 'Send proposal'"
              @click.prevent="handleReviewAndSend"
              :loading="isSending"
              variant="secondary"
              icon="paper-plane"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
  import NumberInput from '@/components/inputs/NumberInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import WysiwygEditor from '@/components/inputs/WysiwygEditor.vue';
  import { useValidation } from '@/composables/useValidation';
  import { crmService } from '@/services/crmService';
  import MessageElement from '@/components/partials/Message.vue';
  import { useToastStore } from '@/store/toastStore';
  import { formatDate } from '@/utils/date';
  import ProposalPreview from '@shared/components/proposal/ProposalPreview.vue';
  import { useConfetti } from '@/services/confettiService';
  import { useUserStore } from '@/store/userStore';
  export default {
    name: 'SendProposal',
    components: {
      TextInput,
      TextAreaInput,
      NumberInput,
      WysiwygEditor,
      SelectInput,
      Btn,
      MessageElement,
      ProposalPreview,
    },
    props: {
      proposalId: {
        type: String,
        required: false,
      },
      leadId: {
        type: String,
        required: true,
      },
      onSend: {
        type: Function,
        required: false,
      },
      proposal: {
        type: Object,
        required: false,
      },
    },

    setup(props) {
      const isLoading = ref(false);
      const toastStore = useToastStore();
      const isSending = ref(false);
      const publicProposal = ref(null);
      const confetti = useConfetti();
      const showResendForm = ref(false);
      const userStore = useUserStore();
      const linkInput = ref(null);
      // Validation setup
      const { inputs, validateForm, onValidate } = useValidation({
        recipients: {
          id: 'recipients',
          label: 'Recipients',
          binding: '',
          error: '',
          rules: ['required', 'email'],
        },
        emailSubject: {
          id: 'emailSubject',
          label: 'Email subject',
          binding: userStore.currentOrganization.crm_settings?.proposal_mail_subject || '',
          error: '',
          rules: ['required', 'maxLength:255'],
        },
        emailContent: {
          id: 'emailContent',
          label: 'Email content',
          binding: userStore.currentOrganization.crm_settings?.proposal_mail_body || '',
          error: '',
          rules: ['required', 'maxLength:2000'],
        },
      });

      const handleReviewAndSend = async () => {
        isSending.value = true;
        const allFieldsValid = await validateForm();
        if (!allFieldsValid) {
          isSending.value = false;
          return;
        }
        if (allFieldsValid) {
          const formData = {
            ...Object.keys(inputs.value).reduce((acc, key) => {
              if (key === 'recipients') {
                acc[key] = inputs.value[key].binding
                  .split(',')
                  .map((email) => email.trim())
                  .filter((email) => email !== '');
              } else {
                acc[key] = inputs.value[key].binding;
              }
              return acc;
            }, {}),
          };
          try {
            const sentEmail = await crmService.sendProposal(
              props.leadId,
              props.proposalId,
              formData
            );
            confetti.fire();
            toastStore.addToast({
              message: 'Proposal sent to your lead!',
              type: 'success',
              duration: 3000,
            });
            isSending.value = false;
            props.onSend(sentEmail);
          } catch (error) {
            console.error('Error saving proposal:', error);
            toastStore.addToast({
              message: 'There was an error saving the proposal',
              type: 'error',
              duration: 3000,
            });
          }
        }
      };

      const formLink = computed(() => {
        return `${import.meta.env.VITE_PROPOSALS_URL}/${props.proposalId}`;
      });

      const copyLink = () => {
        linkInput.value.select();
        document.execCommand('copy');
        toastStore.addToast({
          message: 'Link copied!',
        });
        setTimeout(() => {
          copied.value = false;
        }, 2000);
      };

      const handleViewProposal = () => {
        window.open(formLink.value, '_blank');
      };

      onMounted(async () => {
        const response = await crmService.getDraftProposal(props.leadId, props.proposalId);
        publicProposal.value = response;
        inputs.value.recipients.binding = response.leads.email;
        if (inputs.value.emailSubject.binding) {
          if (inputs.value.emailSubject.binding) {
            inputs.value.emailSubject.binding = inputs.value.emailSubject.binding
              .replace(/\[proposal_title\]/g, response.title || '')
              .replace(/\[lead_first_name\]/g, response.leads.first_name || '')
              .replace(/\[lead_last_name\]/g, response.leads.last_name || '')
              .replace(/\[organization_name\]/g, userStore.currentOrganization.name || '');
          }

          if (inputs.value.emailContent.binding) {
            inputs.value.emailContent.binding = inputs.value.emailContent.binding
              .replace(/\[proposal_title\]/g, response.title || '')
              .replace(/\[lead_first_name\]/g, response.leads.first_name || '')
              .replace(/\[lead_last_name\]/g, response.leads.last_name || '')
              .replace(/\[organization_name\]/g, userStore.currentOrganization.name || '');
          }
        }
      });
      return {
        isLoading,
        isSending,
        handleReviewAndSend,
        publicProposal,
        inputs,
        showResendForm,
        handleViewProposal,
        formatDate,
        formLink,
        linkInput,
        copyLink,
      };
    },
  };
</script>

<style scoped lang="scss">
  .send-proposal {
    .proposal-preview {
      border: 1px solid var(--slate-100);
      border-radius: 10px;
      padding: 20px;
    }
    .form-actions {
      margin-top: 20px;
    }
    &__accepted {
      padding: 1rem;
      background-color: var(--green-50);
      border: 1px solid var(--green-300);
      border-radius: 10px;
      margin-bottom: 1rem;
      display: block;
      p {
        margin-bottom: 1rem;
      }
    }
    &__sent {
      padding: 1rem;
      background-color: var(--amber-50);
      border: 1px solid var(--amber-500);
      border-radius: 10px;
      margin-bottom: 1rem;
      display: block;
      p {
        margin-bottom: 1rem;
      }
    }
    .send-proposal-link {
      &__copy {
        display: flex;
        gap: 0.5rem;

        input {
          flex: 1;
          padding: 0.5rem;
          border: 1px solid var(--slate-200);
          border-radius: 0.375rem;
          font-size: 0.875rem;
          background: #ffffff;
        }
      }
    }
    .signature-preview {
      width: 200px;
      max-width: 100%;
      padding: 0.25rem;
      border: 1px solid var(--slate-200);
      border-radius: 0.375rem;
      margin-top: 1rem;
      img {
        max-width: 100%;
        height: auto;
        width: 300px;
      }
    }
  }
</style>
