import confetti from 'canvas-confetti';

export const useConfetti = () => {
  const fire = (options = {}) => {
    const defaults = {
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      zIndex: 999999,
    };

    confetti({
      ...defaults,
      ...options,
    });
  };

  return {
    fire,
  };
};
