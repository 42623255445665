<template>
  <TextInput
    :id="`unit-price-${index}`"
    label="Price per unit"
    v-model="mapping.unitPrice"
    type="number"
    step="0.01"
    @update:model-value="$emit('update')"
  />
</template>

<script>
  import TextInput from '@/components/inputs/TextInput.vue';

  export default {
    name: 'PerUnitPricing',

    components: {
      TextInput,
    },

    props: {
      mapping: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },

    emits: ['update'],
  };
</script>
