import axios from 'axios';

class InsightsService {
  static async getInsights() {
    const response = await axios.get('/insights/dashboard');
    return response.data;
  }
}

export default InsightsService;
