import axios from 'axios';
export const getDesigns = async (organizationId) => {
  try {
    const response = await axios.get(`/organizations/${organizationId}/designs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching designs:', error);
    throw error;
  }
};

export const getDesign = async (id) => {
  try {
    const response = await axios.get(`/design/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching design:', error);
    throw error;
  }
};

export const createDesign = async (design) => {
  try {
    const response = await axios.post('/design', {
      ...design,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating design:', error);
    throw error;
  }
};

export const updateDesign = async (id, design) => {
  try {
    const response = await axios.patch(`/design/${id}`, {
      ...design,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating design:', error);
    throw error;
  }
};

export const deleteDesign = async (id) => {
  try {
    await axios.delete(`/design/${id}`);
  } catch (error) {
    console.error('Error deleting design:', error);
    throw error;
  }
};
