import { defineStore } from 'pinia';
import axios from 'axios';
import { supabase } from '@/services/supabase';
import { useFormStore } from '@/store/formStore';
import { useIntegrationStore } from '@/store/integrationStore';
import OrganizationService from '@/services/organizationService';
import { useUIStore } from '@/store/uiStore';
import { useRouter } from 'vue-router';
import WelcomeModal from '@/components/onboarding/WelcomeModal.vue';
import WelcomeSuccessModal from '@/components/onboarding/WelcomeSuccessModal.vue';

// Constants
const SESSION_TIMEOUT = 10000; // 10 seconds
const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1 second

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    userData: null,
    organizations: null,
    currentOrganization: null,
    emailVerificationPending: false,
    sessionStatus: 'idle', // 'idle' | 'loading' | 'authenticated' | 'unauthenticated'
    lastFetch: null,
    authStateChangeQueue: [],
    isProcessingAuthChange: false,
  }),

  getters: {
    isAuthenticated: (state) => !!state.user && state.sessionStatus === 'authenticated',
    currentOrganizationId: (state) => state.currentOrganization?.id,
    userRole: (state) => {
      if (!state.currentOrganization || !state.userData?.organizationMemberships) {
        return null;
      }
      const membership = state.userData.organizationMemberships.find(
        (m) => m.organizationId === state.currentOrganization.id
      );
      return membership?.role;
    },
  },

  actions: {
    async processAuthStateChangeQueue() {
      if (this.isProcessingAuthChange || this.authStateChangeQueue.length === 0) return;

      this.isProcessingAuthChange = true;
      try {
        const event = this.authStateChangeQueue.shift();
        await this.handleAuthStateChange(event.type, event.session);
      } finally {
        this.isProcessingAuthChange = false;
        if (this.authStateChangeQueue.length > 0) {
          await this.processAuthStateChangeQueue();
        }
      }
    },

    async verifySession(session) {
      try {
        const { data, error } = await supabase.auth.getUser(session?.access_token);
        return !error && data?.user;
      } catch {
        return false;
      }
    },

    async refreshSession() {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error || !session) {
        await this.clearUserState();
        throw new Error('Session refresh failed');
      }
      return session;
    },

    async retryOperation(operation, maxAttempts = MAX_RETRY_ATTEMPTS) {
      let lastError;
      for (let attempt = 1; attempt <= maxAttempts; attempt++) {
        try {
          return await operation();
        } catch (error) {
          lastError = error;
          if (attempt < maxAttempts) {
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY * attempt));
          }
        }
      }
      throw lastError;
    },

    async fetchUserData() {
      try {
        const authUser = this.user;
        if (!authUser) throw new Error('No authenticated user found');

        // Combine all data fetching into a single operation with timeout
        const fetchOperation = async () => {
          // Create timeout promise
          const timeout = new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Database query timeout')), SESSION_TIMEOUT)
          );

          // Create data fetch promises without timeout method
          const userDataPromise = supabase.from('users').select('*').eq('id', authUser.id).single();

          const orgDataPromise = supabase
            .from('user_organizations')
            .select(`*, organizations!user_organizations_organization_id_fkey(*)`)
            .eq('user_id', authUser.id);

          // Race the promises against timeout
          const [userData, orgData] = await Promise.all([
            Promise.race([userDataPromise, timeout]),
            Promise.race([orgDataPromise, timeout]),
          ]);

          if (userData.error) throw userData.error;
          if (orgData.error) throw orgData.error;

          // Process and update state atomically
          this.$patch((state) => {
            state.userData = userData.data;
            state.organizations =
              orgData.data?.map((uo) => ({
                ...uo.organizations,
                role: uo.role,
              })) || [];
            state.currentOrganization =
              state.organizations.length > 0
                ? state.organizations.find(
                    (org) => org.id === state.userData.current_organization_id
                  ) || state.organizations[0]
                : null;
            state.lastFetch = Date.now();
          });

          // Fetch related store data
          await Promise.all([
            useFormStore().fetchForms(),
            useIntegrationStore().fetchIntegrations(),
          ]);
        };

        await this.retryOperation(fetchOperation);
      } catch (error) {
        console.error('Error in fetchUserData:', error);
        throw error;
      }
    },

    async handleAuthStateChange(event, session) {
      switch (event) {
        case 'INITIAL_SESSION':
        case 'SIGNED_IN':
          if (session && (!this.user || this.user.id !== session.user.id)) {
            if (await this.verifySession(session)) {
              this.user = session.user;
              await this.fetchUserData();
              this.sessionStatus = 'authenticated';
            }
          }
          break;

        case 'SIGNED_OUT':
          await this.clearUserState();
          break;

        case 'TOKEN_REFRESHED':
          if (session && (await this.verifySession(session))) {
            this.sessionStatus = 'authenticated';
          }
          break;

        case 'USER_UPDATED':
          if (session && (await this.verifySession(session))) {
            this.user = session.user;
            await this.fetchUserData();
          }
          break;
      }
    },

    async init() {
      this.sessionStatus = 'loading';

      try {
        // Set up auth state change listener
        supabase.auth.onAuthStateChange((event, session) => {
          this.authStateChangeQueue.push({ type: event, session });
          this.processAuthStateChangeQueue();
        });

        // Initial session check
        const session = await this.refreshSession();
        if (session) {
          this.user = session.user;
          await this.fetchUserData();
          this.sessionStatus = 'authenticated';
        }

        // Handle email verification flow
        if (window.location.hash) {
          const success = await this.handleEmailVerification();
          if (success) return;
        }
      } catch (error) {
        this.sessionStatus = 'unauthenticated';
        await this.clearUserState();
        throw error;
      }
    },

    async login(email, password) {
      this.sessionStatus = 'loading';
      try {
        const {
          data: { user, session },
          error,
        } = await supabase.auth.signInWithPassword({
          email,
          password,
        });

        if (error?.message === 'Email not confirmed') {
          this.emailVerificationPending = true;
          localStorage.setItem('emailVerificationPending', 'true');
          const uiStore = useUIStore();
          const router = useRouter();
          uiStore.addModal(WelcomeModal, {
            title: 'Welcome to Smartflow!',
            description: 'Please check your email to verify your account.',
            onClose: async () => {
              router.push('/verify-email');
            },
          });
          return { verified: false, message: 'Please verify your email to continue' };
        }

        if (error) throw error;

        if (session && (await this.verifySession(session))) {
          this.user = user;
          await this.fetchUserData();
          this.sessionStatus = 'authenticated';
          return user;
        }

        throw new Error('Session verification failed');
      } catch (error) {
        this.sessionStatus = 'unauthenticated';
        console.error('Error logging in:', error);
        throw error;
      }
    },

    async logout() {
      try {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;

        this.clearUserState();
      } catch (error) {
        console.error('Error logging out:', error);
        throw error;
      }
    },

    async clearUserState() {
      this.$patch({
        user: null,
        userData: null,
        organizations: null,
        currentOrganization: null,
        sessionStatus: 'unauthenticated',
        lastFetch: null,
      });
    },

    async handleEmailVerification() {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      const type = hashParams.get('type');

      if (accessToken && type === 'signup') {
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: hashParams.get('refresh_token'),
        });

        if (error) throw error;

        this.user = data.user;
        await this.fetchUserData();
        const uiStore = useUIStore();
        const welcomeModalId = uiStore.addModal(WelcomeSuccessModal, {
          title: 'Welcome to Smartflow!',
          onClose: () => {
            uiStore.closeModal(welcomeModalId);
          },
        });
        return true;
      }
      return false;
    },

    async resendVerificationEmail() {
      try {
        const { error } = await supabase.auth.resend({
          type: 'signup',
          email: this.user.email,
        });

        if (error) throw error;
        return { success: true, message: 'Verification email sent' };
      } catch (error) {
        console.error('Error sending verification email:', error);
        throw error;
      }
    },

    async checkEmailVerification() {
      try {
        const {
          data: { user },
          error,
        } = await supabase.auth.getUser();
        if (error) throw error;

        if (user?.email_confirmed_at) {
          this.emailVerificationPending = false;
          localStorage.removeItem('emailVerificationPending');
        }
      } catch (error) {
        console.error('Error checking email verification:', error);
      }
    },

    async handleInviteSignup(signupData) {
      await supabase.auth.setSession(signupData.session);
      await this.fetchUserData();
      this.user = signupData.user;
      this.organization = signupData.organization;
    },

    async switchOrganization(organizationId) {
      try {
        const { data } = await axios.post('/users/switch-organization', {
          organizationId,
        });

        // Update local state
        this.currentOrganization = this.organizations.find((org) => org.id === organizationId);

        await this.fetchUserData();

        await supabase.auth.getSession();

        // Refresh the page to ensure all components are updated
        // window.location.reload();

        return this.currentOrganization;
      } catch (error) {
        console.error('Error switching organization:', error);
        throw error;
      }
    },
    async updateOrganization(organizationId, data) {
      try {
        const organizationService = new OrganizationService();

        const response = await organizationService.update(organizationId, data);
        this.currentOrganization = {
          ...this.currentOrganization,
          name: response.name,
          language: response.language,
          settings: response.settings,
          crm_settings: response.crm_settings,
          brand_settings: response.brand_settings,
          address: response.address,
        };
        return response;
      } catch (error) {
        console.error('Error updating organization:', error);
        throw error;
      }
    },
    async createOrganization(data) {
      const response = await axios.post('/organizations', data);
      window.location.reload();
      return response.data;
    },
  },
});
