import axios from 'axios';

const removeUndefinedValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefinedValues); // Recursively process each item in the array
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, value]) => value !== undefined) // Remove entries with undefined values
        .map(([key, value]) => [key, removeUndefinedValues(value)]) // Recursively process each value
    );
  }
  return obj; // Return the value if it's not an array or object
};

export const formService = {
  async getForms() {
    try {
      const response = await axios.get('/forms');
      return response.data;
    } catch (error) {
      console.error('Error fetching forms:', error);
      throw error;
    }
  },

  async getFormById(id) {
    try {
      const response = await axios.get(`/forms/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching forms:', error);
      throw error;
    }
  },

  async updateForm(id, form) {
    try {
      const sanitizedData = removeUndefinedValues(form);
      const response = await axios.patch(`/forms/${id}`, sanitizedData);
      return response.data;
    } catch (error) {
      console.error('Error updating form:', error);
      throw error;
    }
  },

  async publishForm(id) {
    try {
      const response = await axios.post(`/forms/${id}/publish`);
      return response.data;
    } catch (error) {
      console.error('Error updating form:', error);
      throw error;
    }
  },

  async unpublishForm(id) {
    try {
      const response = await axios.post(`/forms/${id}/unpublish`);
      return response.data;
    } catch (error) {
      console.error('Error updating form:', error);
      throw error;
    }
  },

  async deleteForm(id) {
    try {
      const response = await axios.delete(`/forms/${id}`);
      return response;
    } catch (error) {
      console.error('Error deleting form:', error);
      throw error;
    }
  },

  async exportSubmissions(id) {
    try {
      const response = await axios.get(`/forms/${id}/submissions/export`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error exporting submissions:', error);
      throw error;
    }
  },
};
