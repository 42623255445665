<template>
  <div class="invite-member-form">
    <form @submit.prevent="handleSubmit">
      <TextInput
        :id="inputs.email.id"
        :label="inputs.email.label"
        v-model="inputs.email.binding"
        :error="inputs.email.error"
        :rules="inputs.email.rules"
        @validate="onValidate('email', $event)"
      />
      <SelectInput
        :id="inputs.role.id"
        :label="inputs.role.label"
        v-model="inputs.role.binding"
        :error="inputs.role.error"
        :rules="inputs.role.rules"
        @validate="onValidate('role', $event)"
        :options="[
          { value: 'admin', label: 'Admin' },
          { value: 'member', label: 'Member' },
        ]"
      />
      <div class="invite-member-form__buttons">
        <Btn
          type="submit"
          text="Send Invitation"
          :loading="isLoading"
          variant="secondary"
          :disabled="isLoading"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import Btn from '@/components/partials/Btn.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import { useValidation } from '@/composables/useValidation';
  import OrganizationService from '@/services/organizationService';
  import { useUIStore } from '@/store/uiStore';

  export default {
    components: {
      Btn,
      TextInput,
      SelectInput,
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
      organization: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const organizationService = new OrganizationService();
      const isLoading = ref(false);
      const uiStore = useUIStore();
      const { inputs, validateForm, onValidate } = useValidation({
        email: {
          id: 'email',
          label: 'Email Address',
          binding: '',
          error: '',
          rules: ['required', 'email'],
        },
        role: {
          id: 'role',
          label: 'Role',
          binding: 'member',
          error: '',
          rules: ['required'],
        },
      });

      const handleSubmit = async () => {
        isLoading.value = true;
        const allFieldsValid = await validateForm();
        if (allFieldsValid) {
          const formData = Object.keys(inputs.value).reduce((acc, key) => {
            acc[key] = inputs.value[key].binding;
            return acc;
          }, {});

          try {
            formData.organization_id = props.organization.id;
            await organizationService.inviteMember(formData);
            props.onSubmit(formData);
          } catch (error) {
            uiStore.addError('Something went wrong', error.response.data.error);
          }
        } else {
          const errors = Object.keys(inputs.value).reduce((acc, key) => {
            if (inputs.value[key].error) {
              acc[key] = inputs.value[key].error;
            }
            return acc;
          }, {});
          props.onError(errors);
        }
        isLoading.value = false;
      };

      return {
        inputs,
        onValidate,
        handleSubmit,
        isLoading,
      };
    },
  };
</script>

<style scoped lang="scss">
  .invite-member-form {
    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }
</style>
