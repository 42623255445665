<template>
  <div class="proposal" v-if="proposal">
    <div class="proposal__company-info">
      <div class="proposal__logo">
        <img
          v-if="proposal.organizations?.brand_settings?.logo"
          :src="proposal.organizations.brand_settings.logo"
          alt="Logo"
        />
        <h2 v-else>{{ proposal.organizations?.name }}</h2>
      </div>
      <div class="proposal__company-details">
        <p v-if="proposal.organizations?.name">
          {{ proposal.organizations.name }}
        </p>
        <p v-if="proposal.organizations?.address?.street_address">
          {{ proposal.organizations.address.street_address }}
        </p>
        <p
          v-if="
            proposal.organizations?.address?.postal_code && proposal.organizations?.address?.city
          "
        >
          {{ proposal.organizations.address.postal_code }} {{ proposal.organizations.address.city }}
        </p>
        <p v-if="proposal.organizations?.address?.phone">
          {{ proposal.organizations.address.phone }}
        </p>
        <p v-if="proposal.organizations?.address?.registration_number">
          KvK: {{ proposal.organizations.address.registration_number }}
        </p>
      </div>
    </div>

    <div class="proposal__client-info">
      <p>{{ proposal.leads.company_name || '' }}</p>
      <p v-if="proposal.leads.first_name && proposal.leads.last_name">
        T.a.v. {{ proposal.leads.first_name }} {{ proposal.leads.last_name }}
      </p>
      <p>{{ proposal.leads.email }}</p>
      <p>{{ proposal.leads.phone }}</p>
    </div>

    <div class="proposal__reference">
      <h1>{{ proposal.title }}</h1>
      <div class="proposal__dates">
        <div>
          <span>Offertedatum:</span>
          <span>{{ formatDate(proposal.proposal_date) }}</span>
        </div>
        <div>
          <span>Vervaldatum:</span>
          <span>{{ formatDate(proposal.valid_until) }}</span>
        </div>
      </div>
    </div>

    <div class="proposal__items">
      <table class="proposal__table">
        <thead>
          <tr>
            <th>Omschrijving</th>
            <th class="proposal__table-cell--right">Aantal</th>
            <th class="proposal__table-cell--right">Bedrag</th>
            <th class="proposal__table-cell--right">BTW</th>
            <th class="proposal__table-cell--right">Totaal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in proposal.items" :key="item.id">
            <td>{{ item.description }}</td>
            <td class="proposal__table-cell--right">{{ item.quantity }}</td>
            <td class="proposal__table-cell--right">{{ formatCurrency(item.unit_price) }}</td>
            <td class="proposal__table-cell--right">{{ item.vat_rate }}%</td>
            <td class="proposal__table-cell--right">
              {{ formatCurrency(item.quantity * item.unit_price) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="proposal__table-cell--right"><strong>Subtotaal:</strong></td>
            <td class="proposal__table-cell--right">{{ formatCurrency(subtotal) }}</td>
          </tr>
          <tr>
            <td colspan="4" class="proposal__table-cell--right"><strong>BTW:</strong></td>
            <td class="proposal__table-cell--right">{{ formatCurrency(vatAmount) }}</td>
          </tr>
          <tr>
            <td colspan="4" class="proposal__table-cell--right"><strong>Totaal:</strong></td>
            <td class="proposal__table-cell--right">{{ formatCurrency(total) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div v-if="proposal.client_notes" class="proposal__notes">
      <h3 class="proposal__notes-title">Opmerkingen</h3>
      <p class="proposal__notes-content">{{ proposal.client_notes }}</p>
    </div>

    <div class="proposal__terms">
      <p>
        Door akkoord te gaan met deze offerte gaat u tevens akkoord met onze algemene voorwaarden.
      </p>
    </div>

    <div v-if="proposal.accepted_at" class="proposal__accepted">
      <p>
        Deze offerte is goedgekeurd op {{ formatDateTime(proposal.accepted_at) }} door
        {{ proposal.signature_data.signer_name }} ({{ proposal.signature_data.signer_email }})
      </p>
      <div class="proposal__accepted-signature">
        <img :src="proposal.signature_data.signature" alt="Signature" />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import dayjs from 'dayjs';

  export default {
    name: 'ProposalPreview',
    props: {
      proposal: {
        type: Object,
        required: true,
        validator: (prop) => {
          return prop.organizations && prop.leads && prop.items;
        },
      },
    },
    setup(props) {
      const subtotal = computed(() => {
        return props.proposal.items.reduce((sum, item) => {
          return sum + item.quantity * item.unit_price;
        }, 0);
      });

      const vatAmount = computed(() => {
        return props.proposal.items.reduce((sum, item) => {
          return sum + item.quantity * item.unit_price * (item.vat_rate / 100);
        }, 0);
      });

      const total = computed(() => subtotal.value + vatAmount.value);

      const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: props.proposal.currency || 'EUR',
        }).format(amount);
      };

      const formatDate = (date) => {
        return dayjs(date).format('DD-MM-YYYY');
      };

      const formatDateTime = (date) => {
        return dayjs(date).format('DD-MM-YYYY h:mm A');
      };

      return {
        subtotal,
        vatAmount,
        total,
        formatCurrency,
        formatDate,
        formatDateTime,
      };
    },
  };
</script>

<style scoped lang="scss">
  .proposal {
    background: white;
    padding: 2rem;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;

    &__company-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;
    }

    &__logo {
      h2 {
        font-size: 1.5rem;
        margin: 0;
      }
      max-width: 200px;
    }

    &__company-details {
      text-align: right;

      p {
        margin: 0.25rem 0;
        font-size: 0.9rem;
      }
    }

    &__client-info {
      margin-bottom: 4rem;

      p {
        margin: 0.25rem 0;
      }
    }

    &__reference {
      margin-bottom: 3rem;

      h1 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
      }
    }

    &__dates {
      display: flex;
      gap: 2rem;

      span:first-child {
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }

    &__items {
      margin: 2rem 0;
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 2rem;

      th,
      td {
        padding: 1rem;
        border-bottom: 1px solid #eee;
      }

      th {
        background: #f8f9fa;
        text-align: left;
        font-weight: 600;
      }

      tbody tr:hover {
        background-color: #f8f9fa;
      }
    }

    &__table-cell--right {
      text-align: right;
    }

    &__notes {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;
    }

    &__notes-title {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    &__notes-content {
      line-height: 1.5;
    }

    &__terms {
      margin-top: 3rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;
      font-size: 0.9rem;
      color: #666;
    }

    tfoot {
      tr:last-child {
        font-weight: bold;
        font-size: 1.1rem;
      }

      td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
    &__accepted {
      margin-top: 3rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;
    }

    &__accepted-signature {
      img {
        width: 200px;
        max-width: 100%;
      }
    }
  }
</style>
