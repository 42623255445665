<template>
  <div class="accept-invite-page">
    <div class="accept-invite-page__main">
      <div class="accept-invite-page__wrap">
        <h2 class="accept-invite-page__title">Accept invite</h2>
        <form
          @submit.prevent="handleAcceptInvite"
          class="accept-invite-page__form mt-8"
          v-if="!isVerifyInviteLoading"
        >
          <TextInput
            :label="userData.email.label"
            v-model="userData.email.binding"
            type="email"
            disabled="true"
          />
          <TextInput
            :label="userData.password.label"
            v-model="userData.password.binding"
            type="password"
          />
          <hr />
          <TextInput
            :label="userData.first_name.label"
            v-model="userData.first_name.binding"
            type="text"
          />
          <TextInput
            :label="userData.last_name.label"
            v-model="userData.last_name.binding"
            type="text"
          />
          <p class="organization-info">Joining: {{ inviteData.organizationName }}</p>
          <Btn
            type="submit"
            :loading="isLoading"
            variant="secondary"
            icon="user"
            class="width-100 mt-2"
            text="Accept Invitation"
          />
        </form>
        <LoadingSpinner class="accept-invite-page__loading" size="xl" v-else />
      </div>
    </div>
    <div class="accept-invite-page__aside">
      <div class="accept-invite-page__aside__content">
        <img src="@/assets/img/auth-visual@2x.png" alt="FormFlow" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter, useRoute } from 'vue-router';
  import { ref, onMounted } from 'vue';
  import { useUserStore } from '@/store/userStore';
  import { useConfetti } from '@/services/confettiService';
  import UserService from '@/services/userService';
  import axios from 'axios';
  import { supabase } from '@/services/supabase';
  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useUIStore } from '@/store/uiStore';
  import WelcomeModal from '@/components/onboarding/WelcomeModal.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';

  export default {
    components: {
      TextInput,
      Btn,
      WelcomeModal,
      LoadingSpinner,
    },
    setup() {
      const isLoading = ref(false);
      const isVerifyInviteLoading = ref(true);
      const confetti = useConfetti();
      const userData = ref({
        email: {
          label: 'Password',
          binding: '',
        },
        password: {
          label: 'Password',
          binding: '',
        },
        first_name: {
          label: 'First name',
          binding: '',
        },
        last_name: {
          label: 'Last name',
          binding: '',
        },
      });
      const router = useRouter();
      const route = useRoute();
      const userService = new UserService();
      const userStore = useUserStore();
      const uiStore = useUIStore();
      const inviteData = ref({
        token: '',
        email: '',
        organizationName: '',
      });

      onMounted(async () => {
        const token = route.query.token;
        if (!token) {
          router.push('/login');
          return;
        }

        try {
          const response = await userService.acceptInvite(token);
          isVerifyInviteLoading.value = false;
          if (response.isExistingUser) {
            // Handle existing user flow
            if (!userStore.isAuthenticated) {
              router.push(`/login?redirect=/accept-invite?token=${token}`);
              return;
            }
            await handleExistingUserInvite(token);
          } else {
            // Setup form for new user
            inviteData.value = {
              token,
              email: response.invite.email,
              organizationName: response.invite.organization.name,
            };
            userData.value.email.binding = response.invite.email;
          }
        } catch (error) {
          // handleError(error);
        }
      });

      const handleAcceptInvite = async () => {
        isLoading.value = true;
        try {
          const response = await userService.acceptNewInvite(inviteData.value.token, {
            email: inviteData.value.email,
            password: userData.value.password.binding,
            first_name: userData.value.first_name.binding,
            last_name: userData.value.last_name.binding,
          });
          const loginRequest = await userStore.login(
            inviteData.value.email,
            userData.value.password.binding
          );
          confetti.fire();
          router.push('/all-flows');
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };

      const handleExistingUserInvite = async (token) => {
        try {
          const response = await axios.post('/accept-invite/existing', { token });
          uiStore.addModal(null, {
            title: 'Success!',
            description: 'You have successfully joined the organization.',
          });
          router.push('/all-flows');
        } catch (error) {
          console.log(error);
        }
      };

      // const register = async () => {
      //   isLoading.value = true;
      //   try {
      //     const response = await axios.post('/users/register', {
      //       email: userData.value.email.binding,
      //       password: userData.value.password.binding,
      //       firstName: userData.value.first_name.binding,
      //       lastName: userData.value.last_name.binding,
      //       organizationName: userData.value.organization_name.binding,
      //     });

      //     if (response.status === 201) {
      //       // Log in the user
      //       const user = await userStore.login(
      //         userData.value.email.binding,
      //         userData.value.password.binding
      //       );

      //       router.push('/all-flows');

      //       // Show welcome modal
      //       const welcomeModalId = uiStore.addModal(WelcomeModal, {
      //         title: 'Welcome to Smartflow!',
      //         description: 'Please check your email to verify your account.',
      //         onSubmit: async () => {},
      //         onClose: async () => {
      //           uiStore.closeModal(welcomeModalId);
      //           router.push('/all-flows');
      //         },
      //       });
      //     }
      //   } catch (error) {
      //     let errorMessage = 'Registration failed. Please try again.';
      //     if (error.response?.data?.message) {
      //       errorMessage = error.response.data.message;
      //     } else if (error.message) {
      //       errorMessage = error.message;
      //     }
      //     uiStore.addModal(null, {
      //       title: 'Something went wrong',
      //       description: 'Cannot create account. Please try again. Does this email already exist?',
      //     });
      //   } finally {
      //     isLoading.value = false;
      //   }
      // };

      return {
        isLoading,
        userData,
        inviteData,
        isVerifyInviteLoading,
        handleAcceptInvite,
      };
    },
  };
</script>
<style scoped lang="scss">
  .accept-invite-page {
    display: flex;
    min-height: 100vh;
    @include lg-down {
      flex-direction: column;
    }
    &__main {
      width: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 100%;
      background-color: #ffffff;
      p a {
        text-decoration: underline;
      }
      @include lg-down {
        width: 100%;
        min-height: 100vh;
      }
    }
    &__form {
      max-width: 100%;
      width: 400px;
    }
    &__aside {
      width: 50%;
      min-height: 100%;
      background-color: #092220;
      background: linear-gradient(49.61deg, #092220 46.86%, #1b4743 101.41%);
      padding: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-width: 400px;
      }
      @include lg-down {
        width: 100%;
      }
    }
    &__loading {
      margin: 1rem auto;
    }
  }
</style>
