<template>
  <div class="insights-page">
    <div class="container">
      <PageHeader title="Insights" description="Insights about your forms"></PageHeader>

      <div class="dashboard-page__chart">
        <Line :data="chartData" :options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
  import PageHeader from '@/components/partials/PageHeader.vue';

  import { ref, computed, onMounted } from 'vue';
  import { Line } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import InsightsService from '@/services/insightsService';

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  export default {
    name: 'InsightsPage',
    components: { PageHeader, Line },
    setup() {
      const insights = ref([]);
      const loading = ref(true);

      const loadInsights = async () => {
        loading.value = true;
        try {
          const response = await InsightsService.getInsights();
          insights.value = response || [];
        } catch (error) {
          console.error('Failed to load insights:', error);
        } finally {
          loading.value = false;
        }
      };

      const chartData = computed(() => {
        // Safely access dailyCounts, defaulting to an empty object if undefined
        const dailyCounts = insights.value?.overview?.dailyCounts || {};

        // Get last 30 days
        const dates = Array.from({ length: 30 }, (_, i) => {
          const d = new Date();
          d.setDate(d.getDate() - i);
          return d.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
          });
        }).reverse();

        // Map the daily insights counts, defaulting to 0 if no data
        const counts = dates.map((date) => {
          const [month, day] = date.split('/');
          const year = new Date().getFullYear();
          const isoDate = `${year}-${month}-${day}`;
          return dailyCounts[isoDate] || 0;
        });

        return {
          labels: dates,
          datasets: [
            {
              label: 'Submissions over time',
              data: counts,
              borderColor: '#3b82f6',
              backgroundColor: 'rgba(59, 130, 246, 0.2)',
              fill: 'origin',
              tension: 0.4,
              borderWidth: 2,
            },
          ],
        };
      });

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
            font: {
              size: 16,
              weight: '500',
            },
            padding: 20,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 3,
            hoverRadius: 6,
          },
        },
      };

      onMounted(loadInsights);

      return {
        chartData,
        chartOptions,
        loading,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/_mixins.scss';
  .dashboard-page {
    &__chart {
      min-height: 25vh;
      margin-bottom: 2rem;
      @include card('sm');
    }
  }
</style>
