<template>
  <div class="range-pricing">
    <div v-for="(rule, ruleIndex) in mapping.rangeRules" :key="ruleIndex" class="range-rule mt-2">
      <div class="flex gap-4">
        <TextInput
          :id="`min-${index}-${ruleIndex}`"
          label="Min"
          v-model="rule.min"
          type="number"
          class="w-24"
          @update:model-value="$emit('update')"
        />
        <TextInput
          :id="`max-${index}-${ruleIndex}`"
          label="Max"
          v-model="rule.max"
          type="number"
          class="w-24"
          @update:model-value="$emit('update')"
        />
        <TextInput
          :id="`price-${index}-${ruleIndex}`"
          label="Price"
          v-model="rule.price"
          type="number"
          step="0.01"
          class="w-32"
          @update:model-value="$emit('update')"
        />
        <button @click="removeRule(ruleIndex)" class="btn btn--danger mt-auto mb-2">Remove</button>
      </div>
    </div>
    <button @click="addRule" class="btn btn--secondary mt-2">Add Range Rule</button>
  </div>
</template>

<script>
  import TextInput from '@/components/inputs/TextInput.vue';

  export default {
    name: 'RangePricing',

    components: {
      TextInput,
    },

    props: {
      mapping: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },

    emits: ['update'],

    setup(props, { emit }) {
      const addRule = () => {
        props.mapping.rangeRules.push({ min: 0, max: 0, price: 0 });
        emit('update');
      };

      const removeRule = (ruleIndex) => {
        props.mapping.rangeRules.splice(ruleIndex, 1);
        emit('update');
      };

      return {
        addRule,
        removeRule,
      };
    },
  };
</script>
