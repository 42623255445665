import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUIStore = defineStore('modal', () => {
  const modals = ref([]);
  const drawer = ref({
    isOpen: false,
    component: null,
    props: {},
  });

  const addModal = (component, props = {}, onClose = null) => {
    const id = Date.now();
    modals.value.push({ id, component, props, onClose, show: true });
    return id;
  };

  const removeModal = (id) => {
    modals.value = modals.value.filter((modal) => modal.id !== id);
  };

  const closeModal = (id) => {
    const modal = modals.value.find((modal) => modal.id === id);
    if (modal) {
      modal.show = false;
      setTimeout(() => {
        removeModal(id);
      }, 300); // Match this to the transition duration
    }
  };

  const addError = (title, description) => {
    const id = Date.now();
    modals.value.push({
      id,
      component: null,
      props: {
        type: 'error',
        title,
        description,
      },
      show: true,
    });
    return id;
  };

  const openDrawer = (component, props = {}) => {
    drawer.value = {
      isOpen: true,
      component,
      props,
    };
  };

  const closeDrawer = () => {
    drawer.value.isOpen = false;
    setTimeout(() => {
      drawer.value.component = null;
      drawer.value.props = {};
    }, 300); // Match transition duration
  };

  return {
    modals,
    addModal,
    removeModal,
    closeModal,
    addError,
    drawer,
    openDrawer,
    closeDrawer,
  };
});
