import { defineStore } from 'pinia';
import { useUserStore } from './userStore';
import automationService from '@/services/automationService';
import axios from 'axios';

// Define trigger types outside the store
const TRIGGER_TYPES = {
  form_submission: {
    name: 'Form Submission',
    config: {
      formId: { type: 'string', required: true },
      event: { type: 'string', required: true, options: ['submission', 'step_completed'] },
    },
  },
  status_change: {
    name: 'Status Change',
    config: {
      fromStatus: { type: 'string', required: true },
      toStatus: { type: 'string', required: true },
    },
  },
  scheduled: {
    name: 'Scheduled',
    config: {
      schedule: { type: 'string', required: true }, // cron expression
      timezone: { type: 'string', required: true },
    },
  },
};

export const useAutomationStore = defineStore('automation', {
  state: () => ({
    automations: [],
    loading: false,
    error: null,
    triggerTypes: TRIGGER_TYPES,
    currentAutomation: null,
  }),

  actions: {
    async fetchAutomations() {
      try {
        this.loading = true;

        const response = await axios.get(`/automations`);
        this.automations = response.data.data;
        return response.data;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async createAutomation() {
      try {
        this.loading = true;

        const response = await automationService.createAutomation();
        if (response.status === 201) {
          this.automations.push(response.data.data);
        }
        return response;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async fetchAutomation(id) {
      this.loading = true;
      try {
        const response = await axios.get(`/automations/${id}`);
        this.currentAutomation = response.data.data;
        return this.currentAutomation;
      } catch (error) {
        if (error.response?.status === 404) {
          this.error = 'Automation not found';
          this.currentAutomation = null;
        } else {
          this.error = error.message;
        }
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateAutomation(automation) {
      try {
        this.loading = true;
        const response = await axios.patch(`/automations/${automation.id}`, automation);

        const index = this.automations.findIndex((a) => a.id === automation.id);
        if (index !== -1) {
          this.automations[index] = response.data.data;
        }
        this.currentAutomation = response.data.data;
        return response;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteAutomation(automationId) {
      try {
        const response = await axios.delete(`/automations/${automationId}`);
        if (response.status === 200) {
          this.automations = this.automations.filter((a) => a.id !== automationId);
        }
        return response;
      } catch (error) {
        this.error = error.message;
        throw error;
      }
    },

    async toggleAutomation(automationId, isActive) {
      return this.updateAutomation(automationId, { isActive });
    },
  },
});
