<template>
  <div class="submission-details">
    <div class="submission-details__metadata">
      <div class="metadata-item">
        <label>Lead</label>
        <p>{{ submission.lead?.name }} - {{ submission.lead?.email }}</p>
      </div>
      <div class="metadata-item">
        <label>Last updated</label>
        <p>{{ formatDate(submission.metadata.lastUpdated) }}</p>
      </div>
    </div>

    <div class="submission-details__data">
      <h3>Form Data</h3>
      <div class="submission-details__fields">
        <div v-for="answer in getProcessedAnswers()" :key="answer.fieldName" class="field-item">
          <label>{{ answer.fieldName }}</label>
          <div v-if="Array.isArray(answer.value)">
            <p v-for="value in answer.value" :key="value">{{ value }}</p>
          </div>
          <div v-else>{{ answer.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import { ELEMENTS } from '@/constants/elements';
  import { formatDate } from '@/utils/date';

  export default defineComponent({
    name: 'SubmissionDetails',

    props: {
      submission: {
        type: Object,
        required: true,
      },
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const getProcessedAnswers = () => {
        return props.submission.data
          .filter((field) => {
            // Find the element definition to get the category
            const elementDef = ELEMENTS.find((e) => e.type === field.type);
            return elementDef && elementDef.category !== 'content';
          })
          .map((field) => {
            // Find the form element that matches this field
            const formElement = props.form.pages
              .flatMap((page) => page.elements)
              .find((element) => element.id === field.fieldId);

            let processedValue = field.value;

            // Handle multiple-choice values
            if (field.type === 'multiple-choice' && Array.isArray(field.value)) {
              processedValue = field.value.map((choiceId) => {
                const choice = formElement?.props.choices.find((c) => c.id === choiceId);
                return choice?.content || 'undefined';
              });
            }

            return {
              fieldName: field.fieldName,
              type: field.type,
              value: processedValue,
            };
          });
      };

      return {
        formatDate,
        getProcessedAnswers,
      };
    },
  });
</script>

<style scoped lang="scss">
  .submission-details {
    padding: 1rem;

    &__metadata {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
      margin-bottom: 2rem;

      .metadata-item {
        label {
          font-size: 0.875rem;
          color: var(--slate-600);
          margin-bottom: 0.25rem;
        }

        p {
          font-size: 1rem;
          color: var(--slate-900);
          margin: 0;
        }
      }
    }

    &__data {
      h3 {
        font-size: 1.125rem;
        margin-bottom: 1rem;
      }

      .data-preview {
        background: var(--slate-100);
        border-radius: 0.5rem;
        padding: 1rem;
        overflow: auto;
        max-height: 400px;

        pre {
          margin: 0;
          font-family: monospace;
          font-size: 0.875rem;
        }
      }
    }
    &__fields {
      .field-item {
        padding: 0.5rem 0;
        border-top: 1px solid var(--slate-200);

        label {
          font-size: 0.875rem;
          color: var(--slate-800);
          margin-bottom: 0.25rem;
          font-weight: 600;
        }
      }
    }
  }
</style>
