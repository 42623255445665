<template>
  <div class="drawer" :class="{ 'drawer--open': isVisible }">
    <div class="drawer__header">
      <div class="drawer__close">
        <Btn variant="plain" @click="closeDrawer">
          <BaseIcon name="arrow" size="16" rotate="180" />
        </Btn>
      </div>
      <div class="drawer__header-content">
        <div class="drawer__icon-title">
          <div class="drawer__icon">
            <BaseIcon :name="props.icon || 'zap'" size="16" />
          </div>
          <div class="drawer__titles">
            <div class="drawer__subtitle">{{ props.subtitle }}</div>
            <div class="drawer__title">{{ props.title }}</div>
          </div>
        </div>
        <button class="drawer__change" @click="closeDrawer">
          <span>Change</span>
        </button>
      </div>
      <div class="drawer__description" v-if="props.description">
        {{ props.description }}
      </div>
    </div>

    <div class="drawer__body">
      <slot></slot>
    </div>

    <div class="drawer__footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'Drawer',
    components: { BaseIcon, Btn },
    props: {
      show: Boolean,
      props: {
        type: Object,
        default: () => ({}),
      },
    },
    emits: ['close', 'refresh', 'delete'],
    setup(props, { emit }) {
      const isVisible = ref(props.show);

      watch(
        () => props.show,
        (newVal) => {
          isVisible.value = newVal;
        }
      );

      const closeDrawer = () => emit('close');
      const refreshBlock = () => emit('refresh');
      const deleteBlock = () => emit('delete');

      return {
        isVisible,
        closeDrawer,
        refreshBlock,
        deleteBlock,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .drawer {
    position: fixed;
    top: 0;
    right: -500px;
    bottom: 0;
    width: 500px;
    background: white;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: right 0.3s ease;
    z-index: 1000;

    &--open {
      right: 0;
    }

    &__header {
      padding: 0 24px 24px 24px;
      border-bottom: 1px solid var(--slate-200);
    }

    &__header-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 16px;
      padding: 1rem 0;
    }

    &__icon-title {
      display: flex;
      gap: 12px;
      align-items: center;
    }
    &__icon {
      color: var(--slate-600);
      background: var(--slate-100);
      padding: 8px;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__titles {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__subtitle {
      font-size: 14px;
      color: var(--slate-600);
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      color: var(--slate-900);
    }

    &__description {
      color: var(--slate-600);
      font-size: 14px;
    }

    &__change {
      padding: 6px 12px;
      border-radius: 6px;
      background: var(--slate-100);
      color: var(--slate-700);
      font-size: 14px;
      border: none;
      cursor: pointer;

      &:hover {
        background: var(--slate-200);
      }
    }

    &__close {
      border-bottom: 1px solid var(--slate-200);
      padding: 12px 24px;
      margin: 0 -24px;
    }

    &__body {
      flex: 1;
      overflow-y: auto;
      padding: 24px;
    }

    &__footer {
      padding: 16px 24px;
      border-top: 1px solid var(--slate-200);
    }

    &__footer-actions {
      display: flex;
      justify-content: space-between;
      gap: 12px;

      .btn {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        font-size: 14px;

        &--icon {
          background: var(--slate-100);
          color: var(--slate-700);

          &:hover {
            background: var(--slate-200);
          }
        }

        &--danger {
          color: var(--red-600);
          background: var(--red-50);

          &:hover {
            background: var(--red-100);
          }
        }
      }
    }
  }
</style>
