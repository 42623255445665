import dayjs from 'dayjs';

export const formatDate = (date) => {
  if (!date) return '';
  return dayjs(date).format('MMM D, YYYY h:mm A');
};

export const formatDateTime = (date) => {
  if (!date) return '';
  return dayjs(date).format('MMM D, YYYY h:mm A');
};

export const formatTimeAgo = (date) => {
  if (!date) return '';

  const timestamp = dayjs(date).valueOf();
  const now = dayjs();
  const diff = now.diff(timestamp, 'hour');

  if (diff === 0) {
    return 'just now';
  }

  if (diff < 24) {
    return `${diff}h`;
  }

  const days = Math.floor(diff / 24);
  if (days < 7) {
    return `${days}d`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks === 1) {
    return '1 week';
  }
  return `>${weeks}w`;
};
