<template>
  <transition-group name="modal-fade" tag="div">
    <Modal
      v-for="(modal, index) in modals"
      :key="modal.id"
      :props="modal.props"
      :onClose="modal.onClose"
      :show="modal.show"
      :size="modal.size"
      :hasComponent="modal.component ? true : false"
      @close="closeModal(index)"
      @after-leave="removeModal(index)"
    >
      <component v-if="modal.component" :is="modal.component" v-bind="modal.props" />
    </Modal>
  </transition-group>
</template>

<script>
  import { computed } from 'vue';
  import { useUIStore } from '@/store/uiStore';
  import Modal from './Modal.vue';

  export default {
    components: { Modal },
    setup() {
      const uiStore = useUIStore();
      const modals = computed(() => uiStore.modals);
      const closeModal = (index) => {
        modals.value[index].show = false;
        uiStore.closeModal(index);
      };

      const removeModal = (index) => {
        setTimeout(() => {
          uiStore.removeModal(index);
        }, 300); // Match this to the transition duration
      };

      return {
        modals,
        closeModal,
        removeModal,
      };
    },
  };
</script>

<style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition:
      opacity 0.5s,
      transform 0.5s;
  }
  .modal-fade-enter-from,
  .modal-fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
</style>
