import { defineStore } from 'pinia';
import { formService } from '@/services/formService';

import { useUserStore } from './userStore';
import { useDesignStore } from './designStore';

// import { watch } from 'vue';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { uid } from 'uid';

export const useFormStore = defineStore('form', {
  state: () => ({
    forms: [],
    currentForm: {
      id: null,
      name: '',
      pages: [],
      settings: {},
      designId: null,
      enabledIntegrations: [],
    },
    inputEditing: null,
    activePageIndex: null,
    activeElementIndex: null,
    formIntegrations: {},
    lastSaveTime: null,
    history: [],
    currentHistoryIndex: -1,
    maxHistorySteps: 50,
    isDirty: false,
    isSaving: false,
    isPublishing: false,
    originalFormData: null,
  }),
  getters: {
    pages(state) {
      return state.currentForm ? state.currentForm.pages : [];
    },
    activePage(state) {
      if (state.currentForm && state.activePageIndex !== null) {
        return state.currentForm.pages[state.activePageIndex];
      }
      return null;
    },
    activeElement(state) {
      if (
        state.currentForm &&
        state.activePageIndex !== null &&
        state.activeElementIndex !== null
      ) {
        return state.currentForm.pages[state.activePageIndex].elements[state.activeElementIndex];
      }
      return null;
    },
    // Get all integrations for a specific form
    getFormIntegrations: (state) => (formId) => {
      return state.formIntegrations[formId] || {};
    },
    isIntegrationEnabled: (state) => (integrationId) => {
      return state.currentForm.enabledIntegrations?.includes(integrationId) || false;
    },
    hasDraft: (state) => state.currentForm?.has_draft || false,
    publishedVersion: (state) => state.currentForm?.published_version || 0,
    currentVersion: (state) => state.currentForm?.version || 0,
    hasUnpublishedChanges: (state) => state.currentForm?.has_draft,
    // originalFormData: (state) => state.originalFormData,
  },

  actions: {
    async addPage() {
      try {
        const newPage = {
          id: uid(),
          title: `Page ${this.currentForm.pages.length + 1}`,
          elements: [],
        };
        if (this.currentForm) {
          this.currentForm.pages.push(newPage);
        }
        // await this.saveCurrentForm();
        return { status: 'success', message: 'Page added successfully', newPage };
      } catch (error) {
        console.error('Failed to add page:', error);
        return { status: 'error', message: 'Failed to add page', error };
      }
    },

    async removePage(pageIndex) {
      try {
        if (this.currentForm) {
          this.currentForm.pages.splice(pageIndex, 1);
          // Adjust activePageIndex if necessary
          if (pageIndex === this.activePageIndex) {
            this.activePageIndex = this.currentForm.pages.length > 0 ? 0 : null; // Set to the first page or null if no pages left
          } else if (pageIndex < this.activePageIndex) {
            this.activePageIndex--;
          }
          // await this.saveCurrentForm();
          return { status: 'success', message: 'Step removed successfully' };
        }
      } catch (error) {
        console.error('Failed to remove page:', error);
        return { status: 'error', message: 'Failed to remove page', error };
      }
    },

    setActivePage(pageIndex) {
      this.activePageIndex = pageIndex;
    },
    setActiveElementIndex(index) {
      this.activeElementIndex = index;
    },

    setActiveElement(pageIndex = 0, elementIndex = 0) {
      this.activePageIndex = pageIndex;
      this.activeElementIndex = elementIndex;
    },

    unsetElements() {
      this.activeElementIndex = null;
    },

    updateActiveElementSettings(settings) {
      this.setDirty();
      if (this.activeElement) {
        Object.assign(this.activeElement.props, settings);
        this.triggerUpdateForm(this.currentForm.id);
      }
    },

    editPageSettings(pageIndex) {
      this.inputEditing = {
        type: 'page',
        index: pageIndex,
        settings: { ...this.currentForm.pages[pageIndex] },
      };
    },

    // Update editElementSettings to work with blocks
    editElementSettings(pageIndex, elementIndex) {
      const page = this.currentForm.pages[pageIndex];
      this.inputEditing = {
        type: 'input',
        pageIndex,
        elementIndex,
        settings: { ...page.elements[elementIndex] },
      };
    },
    async addElement(el) {
      try {
        if (this.activePageIndex === null) {
          throw new Error('No active page selected');
        }
        this.currentForm.pages[this.activePageIndex].elements.push(el);
        this.setActiveElement(
          this.activePageIndex,
          this.currentForm.pages[this.activePageIndex].elements.length - 1
        );
        this.saveToHistory();
        // await this.saveCurrentForm();
        return { status: 'success', message: 'Element added successfully' };
      } catch (error) {
        console.error('Failed to add element:', error);
        return { status: 'error', message: 'Failed to add element', error };
      }
    },

    async removeElement(elementIndex) {
      try {
        this.currentForm.pages[this.activePageIndex].elements.splice(elementIndex, 1);
        // Adjust activeElementIndex if necessary
        if (elementIndex === this.activeElementIndex) {
          this.activeElementIndex =
            this.currentForm.pages[this.activePageIndex].elements.length > 0 ? 0 : null;
        } else if (elementIndex < this.activeElementIndex) {
          this.activeElementIndex--;
        }
        this.saveToHistory();
        // await this.saveCurrentForm();
        return { status: 'success', message: 'Element removed successfully' };
      } catch (error) {
        console.error('Failed to remove element:', error);
        return { status: 'error', message: 'Failed to remove element', error };
      }
    },

    async cloneElement({ elementIndex, pageIndex = this.activePageIndex }) {
      try {
        const sourceElement = this.currentForm.pages[pageIndex].elements[elementIndex];
        const clonedElement = {
          ...JSON.parse(JSON.stringify(sourceElement)),
          id: crypto.randomUUID(),
        };

        this.currentForm.pages[pageIndex].elements.splice(elementIndex + 1, 0, clonedElement);
        this.saveToHistory();
        // await this.saveCurrentForm();
        return { status: 'success', message: 'Element cloned successfully' };
      } catch (error) {
        console.error('Failed to clone element:', error);
        return { status: 'error', message: 'Failed to clone element', error };
      }
    },

    getAllElements() {
      return this.currentForm?.pages.flatMap((page) =>
        page.elements.map((element) => ({
          id: element.id,
          props: element.props,
          type: element.type,
          pageId: page.id,
        }))
      );
    },

    saveSettings(settings) {
      if (this.inputEditing.type === 'page') {
        const page = this.currentForm.pages[this.inputEditing.index];
        Object.assign(page, settings);
      } else if (this.inputEditing.type === 'input') {
        const element =
          this.currentForm.pages[this.inputEditing.pageIndex].elements[
            this.inputEditing.elementIndex
          ];
        Object.assign(element, settings);
      }
      this.inputEditing = null;
    },

    async updatePage(pageIndex, data) {
      if (this.currentForm && this.currentForm.pages[pageIndex]) {
        // First merge the data and the edited page settings
        Object.assign(this.currentForm.pages[pageIndex], data);
      }
      // Then save the form
      await this.saveCurrentForm();
    },

    async publishChanges(formId) {
      this.isPublishing = true;
      try {
        const response = await formService.publishForm(formId);
        this.currentForm = {
          ...response,
          has_draft: false,
        };
        this.isDirty = false;
        return response;
      } catch (error) {
        console.error('Failed to publish form:', error);
        throw error;
      } finally {
        this.isPublishing = false;
      }
    },

    async unpublishForm(formId) {
      this.isPublishing = true;
      try {
        const response = await formService.unpublishForm(formId);
        this.currentForm = {
          ...response,
          has_draft: true,
        };
        this.isDirty = false;
        return response;
      } catch (error) {
        console.error('Failed to unpublish form:', error);
        throw error;
      } finally {
        this.isPublishing = false;
      }
    },

    async saveCurrentForm() {
      this.isSaving = true;
      try {
        const form = {
          ...this.currentForm,
        };
        const updatedForm = await formService.updateForm(this.currentForm.id, form);
        this.currentForm = {
          ...updatedForm,
          has_draft: true,
          published_version: this.currentForm.published_version,
          published_at: this.currentForm.published_at,
        };

        // Update the original data after successful save
        this.originalFormData = JSON.parse(JSON.stringify(this.currentForm));
        this.lastSaveTime = new Date();
        this.isDirty = false;
      } finally {
        this.isSaving = false;
      }
    },

    saveToHistory() {
      // Remove any future states if we're in the middle of the history
      if (this.currentHistoryIndex < this.history.length - 1) {
        this.history = this.history.slice(0, this.currentHistoryIndex + 1);
      }

      // Add current state to history
      this.history.push(JSON.stringify(this.currentForm));

      // Remove oldest entries if exceeding max size
      if (this.history.length > this.maxHistorySteps) {
        this.history.shift();
      } else {
        this.currentHistoryIndex++;
      }
    },

    undo() {
      if (this.currentHistoryIndex > 0) {
        this.currentHistoryIndex--;
        this.currentForm = JSON.parse(this.history[this.currentHistoryIndex]);
        this.setDirty();
      }
    },

    redo() {
      if (this.currentHistoryIndex < this.history.length - 1) {
        this.currentHistoryIndex++;
        this.currentForm = JSON.parse(this.history[this.currentHistoryIndex]);
        this.setDirty();
      }
    },

    async loadForm(id) {
      const form = await formService.getFormById(id);

      this.$patch((state) => {
        state.currentForm = form;
        state.lastSaveTime = new Date(form.updated_at || form.created_at);
        // If form has a designId, load that design
        if (form.designId) {
          const designStore = useDesignStore();
          designStore.loadDesign(form.designId);
        }
      });
      // Store a deep copy of the form data
      this.originalFormData = JSON.parse(JSON.stringify(form));
      this.isDirty = false;
    },

    setDirty() {
      this.isDirty = true;
    },

    // Instead of a simple setDirty, compare with original data
    checkIfDirty(currentFormData) {
      if (!this.originalFormData) return false;

      // Compare relevant fields only (excluding metadata like updated_at)
      const relevantFields = ['name', 'pages', 'settings'];

      return relevantFields.some(
        (field) =>
          JSON.stringify(currentFormData[field]) !== JSON.stringify(this.originalFormData[field])
      );
    },

    async fetchForms() {
      try {
        const forms = await formService.getForms();
        this.forms = forms;
      } catch (error) {
        console.error('Failed to fetch forms:', error);
        throw error;
      }
    },

    async fetchFormById(id) {
      const form = await formService.getFormById(id);
      return form;
    },

    async createForm(data) {
      const userStore = useUserStore();
      const userId = userStore.user.id;

      const response = await axios.post('/forms', {
        name: data.flow_title,
        pages: [],
        settings: {},
        designId: null,
        uid: userId,
      });
      this.$patch(async (state) => {
        state.forms.push(response.data);
        state.forms = await formService.getForms();
      });
      return response;
    },

    async createFormWithAI(data) {
      const userStore = useUserStore();
      const organizationId = userStore.currentOrganization.id;
      const userId = userStore.user.id;

      const response = await axios.post('/ai/generate-form', {
        title: data.flow_title,
        description: data.description,
        organizationId,
        uid: userId,
      });
      this.$patch(async (state) => {
        state.forms.push(response.data);
        state.forms = await formService.getForms();
      });
      return response;
    },

    async deleteForm(id) {
      try {
        const deletedForm = await formService.deleteForm(id);
        if (deletedForm.status === 204) {
          this.forms = this.forms.filter((form) => form.id !== id);
        }
      } catch (error) {
        console.error('Error deleting form:', error);
      }
    },

    // Debounced update function
    debouncedUpdateForm: debounce(async function (id, form) {
      try {
        await formService.updateForm(id, form);
        this.lastSaveTime = new Date();
      } catch (error) {
        console.error('Error updating form:', error);
      }
    }, 30000), // 30 second debounce

    // Function to trigger the debounced update
    triggerUpdateForm(id) {
      const form = {
        name: this.currentForm.name,
        pages: this.pages,
        settings: this.currentForm.settings,
      };
      this.saveToHistory();
      this.debouncedUpdateForm(id, form);
    },

    updateElementProps(pageIndex, elementIndex, settings) {
      Object.assign(this.currentForm.pages[pageIndex].elements[elementIndex].props, settings);
      this.triggerUpdateForm(this.currentForm.id);
    },
    async setFormDesign(designId) {
      if (this.currentForm) {
        this.currentForm.design_id = designId;
        const designStore = useDesignStore();
        await designStore.loadDesign(designId);
        await this.saveCurrentForm();
      }
    },

    async updateFormSettings(formId, settings) {
      try {
        const response = await axios.patch(`/forms/${formId}/settings`, { settings });
        this.currentForm.settings = response.data.settings;
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to update form settings');
      }
    },

    async getFormSubmissions(formId, page = 1, limit = 50) {
      try {
        const response = await axios.get(`/submissions`, {
          params: {
            formId,
            page,
            limit,
          },
        });
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to get form submissions');
      }
    },

    async getFormSubmission(submissionId) {
      try {
        const response = await axios.get(`/submissions/${submissionId}`);
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to get form submissions');
      }
    },

    async updateSubmissionStatus(submissionId, newStatus) {
      try {
        const response = await axios.patch(`/submissions/${submissionId}/status`, {
          status: newStatus,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to update submission status');
      }
    },

    async toggleIntegration(integrationId) {
      try {
        if (!this.currentForm.enabledIntegrations) {
          this.currentForm.enabledIntegrations = [];
        }

        const isEnabled = this.isIntegrationEnabled(integrationId);

        // Toggle the integration
        if (isEnabled) {
          this.currentForm.enabledIntegrations = this.currentForm.enabledIntegrations.filter(
            (id) => id !== integrationId
          );
        } else {
          this.currentForm.enabledIntegrations.push(integrationId);
        }

        // Save the form
        // await this.saveCurrentForm();
      } catch (error) {
        console.error('Failed to toggle integration:', error);
        throw error;
      }
    },

    async fetchFormIntegrations(formId) {
      try {
        const response = await axios.get(`/forms/${formId}/integrations`);

        // Update local state
        if (!this.formIntegrations[formId]) {
          this.formIntegrations[formId] = {};
        }
        this.formIntegrations[formId] = response.data.reduce((acc, integration) => {
          acc[integration.integrationId] = {
            enabled: integration.enabled,
            settings: integration.settings || {},
          };
          return acc;
        }, {});

        return response.data;
      } catch (error) {
        console.error('Failed to fetch form integrations:', error);
        throw error;
      }
    },
  },
});

// // Watch for changes in the currentForm and trigger the debounced update
// const formStore = useFormStore();
// watch(() => formStore.currentForm, (newForm) => {
//   if (newForm.id) {
//     formStore.triggerUpdateForm(newForm.id);
//   }
// }, { deep: true });
