import axios from 'axios';

export const crmService = {
  async getLeads({ page = 1, limit = 20 } = {}) {
    try {
      const response = await axios.get('/crm/leads', {
        params: { page, limit },
      });
      return response.data; // Returns { leads: [], pagination: {} }
    } catch (error) {
      console.error('Error fetching leads:', error);
      throw error;
    }
  },

  async getLeadById(id) {
    try {
      const response = await axios.get(`/crm/leads/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching lead:', error);
      throw error;
    }
  },

  async updateLead(id, leadData) {
    try {
      const response = await axios.patch(`/crm/leads/${id}`, leadData);
      return response.data;
    } catch (error) {
      console.error('Error updating lead:', error);
      throw error;
    }
  },

  async deleteLead(id) {
    try {
      const response = await axios.delete(`/crm/leads/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting lead:', error);
      throw error;
    }
  },

  async addLeadActivity(leadId, activity) {
    try {
      const response = await axios.post(`/crm/leads/${leadId}/activities`, activity);
      return response.data;
    } catch (error) {
      console.error('Error adding lead activity:', error);
      throw error;
    }
  },

  async getProposals(leadId) {
    try {
      const response = await axios.get(`/crm/leads/${leadId}/proposals`);
      return response.data;
    } catch (error) {
      console.error('Error fetching proposals:', error);
      throw error;
    }
  },

  async getDraftProposal(leadId, proposalId) {
    try {
      const response = await axios.get(`/crm/leads/${leadId}/proposals/${proposalId}/draft`);
      return response.data;
    } catch (error) {
      console.error('Error fetching proposals:', error);
      throw error;
    }
  },
  async getProposal(leadId, proposalId) {
    try {
      const response = await axios.get(`/crm/leads/${leadId}/proposals/${proposalId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching proposals:', error);
      throw error;
    }
  },
  async updateProposal(leadId, proposalId, proposal) {
    try {
      const response = await axios.patch(`/crm/leads/${leadId}/proposals/${proposalId}`, proposal);
      return response.data;
    } catch (error) {
      console.error('Error saving proposal:', error);
      throw error;
    }
  },
  async createProposal(leadId, proposal) {
    try {
      const response = await axios.post(`/crm/leads/${leadId}/proposals`, proposal);
      return response.data;
    } catch (error) {
      console.error('Error creating proposal:', error);
      throw error;
    }
  },
  async sendProposal(leadId, proposalId, emailContent) {
    try {
      const response = await axios.post(`/crm/leads/${leadId}/proposals/${proposalId}/send`, {
        recipients: emailContent.recipients,
        subject: emailContent.emailSubject,
        body: emailContent.emailContent,
      });
      return response.data;
    } catch (error) {
      console.error('Error creating proposal:', error);
      throw error;
    }
  },
  async deleteProposal(leadId, proposalId) {
    try {
      const response = await axios.delete(`/crm/leads/${leadId}/proposals/${proposalId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting proposal:', error);
      throw error;
    }
  },
};
