<template>
  <div class="oauth-callback">
    <LoadingSpinner />
  </div>
</template>

<script>
  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useUserStore } from '@/store/userStore';
  import { supabase } from '@/services/supabase';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';

  export default {
    components: {
      LoadingSpinner,
    },
    setup() {
      const router = useRouter();
      const userStore = useUserStore();

      onMounted(async () => {
        try {
          // Get the session from Supabase
          const {
            data: { session },
            error,
          } = await supabase.auth.getSession();

          if (error) throw error;

          if (session) {
            await userStore.fetchUserData();
            router.push('/all-flows');
          } else {
            router.push('/login');
          }
        } catch (error) {
          console.error('Auth callback error:', error);
          router.push('/login');
        }
      });
    },
  };
</script>
