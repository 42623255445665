import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/userStore';

import LoginPage from '@/pages/authentication/LoginPage.vue';
import RegisterPage from '@/pages/authentication/RegisterPage.vue';
import ResetPasswordPage from '@/pages/authentication/ResetPasswordPage.vue';
import AcceptInvitePage from '@/pages/authentication/AcceptInvitePage.vue';

import FormEditorPage from '@/pages/flow/FormEditorPage.vue';
import FormViewerPage from '@/pages/public/FormViewerPage.vue';

import EditView from '@/pages/flow/EditView.vue';
import FormIntegrationsView from '@/pages/flow/FormIntegrationsView.vue';
import PerformanceView from '@/pages/flow/PerformanceView.vue';
import SubmissionView from '@/pages/flow/SubmissionView.vue';
import PublishView from '@/pages/flow/PublishView.vue';
import SettingsView from '@/pages/flow/SettingsView.vue';

import DefaultLayout from '@/layouts/DefaultLayout.vue';

import AllFormsPage from '@/pages/dashboard/AllFormsPage.vue';
import InsightsPage from '@/pages/dashboard/InsightsPage.vue';
import AutomationsView from '@/pages/dashboard/AutomationsPage.vue';
import AutomationEditor from '@/pages/dashboard/AutomationEditor.vue';
import CrmPage from '@/pages/dashboard/CrmPage.vue';
import { checkAuth } from '@/middleware/authMiddleware';

import SettingsLayout from '@/layouts/SettingsLayout.vue';
import SettingsIntegrationsView from '@/pages/settings/SettingsIntegrationsView.vue';
import SettingsMyAccountView from '@/pages/settings/SettingsMyAccountView.vue';
import SettingsOrganizationView from '@/pages/settings/SettingsOrganizationView.vue';
import SettingsBillingView from '@/pages/settings/SettingsBillingView.vue';
import CalendarCallback from '@/components/oauth/CalendarCallback.vue';
import GoogleAuthCallback from '@/components/oauth/GoogleAuthCallback.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/accept-invite',
    name: 'AcceptInvite',
    component: AcceptInvitePage,
    meta: { requiresAuth: false },
  },
  {
    path: '/flow/:id',
    component: FormEditorPage,
    meta: { requiresAuth: true, roles: ['admin'] },
    children: [
      {
        path: '',
        name: 'Edit',
        component: EditView,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: SettingsView,
      },
      {
        path: 'form-integrations',
        name: 'FormIntegrations',
        component: FormIntegrationsView,
      },
      {
        path: 'performance',
        name: 'Performance',
        component: PerformanceView,
      },
      {
        path: 'submissions',
        name: 'Submissions',
        component: SubmissionView,
      },
      {
        path: 'publish',
        name: 'Publish',
        component: PublishView,
      },
    ],
  },
  {
    path: '/funnel/:id',
    name: 'FormViewer',
    component: FormViewerPage,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/oauth/calendar-callback',
    name: 'CalendarCallback',
    component: CalendarCallback,
    meta: { requiresAuth: false },
  },
  {
    path: '/oauth/google-callback',
    name: 'GoogleCallback',
    component: GoogleAuthCallback,
    meta: { requiresAuth: false },
  },
  {
    path: '/settings',
    component: SettingsLayout,
    meta: { requiresAuth: true, roles: ['admin', 'member'] },
    children: [
      {
        path: '',
        redirect: '/settings/my-account',
      },
      {
        path: 'integrations',
        name: 'SettingsIntegrations',
        component: SettingsIntegrationsView,
        meta: { roles: ['admin'] },
      },
      {
        path: 'my-account',
        name: 'SettingsMyAccount',
        component: SettingsMyAccountView,
      },
      {
        path: 'organization',
        name: 'SettingsOrganization',
        component: SettingsOrganizationView,
        meta: { roles: ['admin'] },
      },
      {
        path: 'billing',
        name: 'SettingsBilling',
        component: SettingsBillingView,
        meta: { roles: ['admin'] },
      },
    ],
  },
  {
    path: '/',
    component: DefaultLayout,
    meta: { requiresAuth: true, roles: ['admin', 'member'] },
    beforeEnter: async (to) => {
      // Check for auth callback in URL
      if (window.location.hash) {
        const userStore = useUserStore();
        try {
          const verified = await userStore.init();
          if (verified) {
            return { path: '/all-flows' };
          }
        } catch (error) {
          console.error('Error handling verification:', error);
          return { path: '/login' };
        }
      }
      return true;
    },
    children: [
      {
        path: '',
        redirect: '/all-flows',
      },
      {
        path: 'all-flows',
        name: 'AllFlows',
        component: AllFormsPage,
      },
      {
        path: 'automations',
        name: 'Automations',
        component: AutomationsView,
        meta: { roles: ['admin'] },
      },
      {
        path: 'automations/:id',
        name: 'AutomationEditor',
        component: AutomationEditor,
        meta: { roles: ['admin'] },
      },
      {
        path: 'insights',
        name: 'Insights',
        component: InsightsPage,
      },
      {
        path: 'crm',
        name: 'CRM',
        component: CrmPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add error handling for the beforeEnter guard
const handleDefaultLayoutEnter = async (to) => {
  if (window.location.hash) {
    const userStore = useUserStore();
    try {
      const verified = await userStore.init();
      if (verified) {
        return { path: '/all-flows' };
      }
    } catch (error) {
      console.error('Error handling verification:', error);
      return { path: '/login' };
    }
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  try {
    await checkAuth(to, from, next);
  } catch (error) {
    console.error('Auth middleware error:', error);
    next('/login');
  }
});

// Update the beforeEach navigation guard with better error handling
// router.beforeEach(async (to, from, next) => {
//   try {
//     // Add a timeout to prevent hanging
//     const timeoutPromise = new Promise((_, reject) => {
//       setTimeout(() => reject(new Error('Auth check timeout')), 5000);
//     });

//     const authCheckPromise = checkAuth(to, from, next);
//     await Promise.race([authCheckPromise, timeoutPromise]);

//     // If we get here, auth check passed
//     next();
//   } catch (error) {
//     console.error('Auth middleware error:', error);
//     if (to.meta.requiresAuth === false) {
//       // Allow navigation to public routes even if auth check fails
//       next();
//     } else {
//       next('/login');
//     }
//   }
// });

// Add error handling
router.onError((error) => {
  console.error('Router error:', error);
});

export default router;
