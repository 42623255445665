<template>
  <div class="design-sidebar">
    <div v-if="!isEditing" class="designs-list">
      <div class="designs-header">
        <h4 class="section-title">Saved Designs</h4>
      </div>
      <div class="designs-grid">
        <Message
          v-if="savedDesigns.length === 0"
          message="No designs found within your organization. Create a new design to get started."
          variant="info"
        />
        <DesignPreset
          v-for="preset in savedDesigns"
          :key="preset.id"
          :preset="preset"
          :isActive="preset.id === currentDesignId"
          @set-active="applyDesign(preset)"
          @edit="editDesign(preset)"
          @delete="deleteDesign(preset.id)"
        />
      </div>
      <Btn
        text="Add new design"
        @click="startNewDesign"
        icon="plus"
        class="mt-4"
        variant="secondary"
      />
    </div>

    <div v-else class="design-editor">
      <div class="editor-header">
        <Btn text="Back" @click="cancelEdit" variant="outlined" />
        <Btn
          :text="saveText"
          @click="saveDesign"
          :disabled="!hasUnsavedChanges"
          :loading="isSaving"
        />
      </div>

      <div class="section">
        <h4 class="section-title">Name</h4>
        <TextInput id="design-name" label="Design name" v-model="design.name" />
      </div>
      <Divider />
      <div class="section">
        <h3 class="section-title">Colors</h3>
        <div class="color-pickers">
          <div class="color-section">
            <ColorPicker label="Background" v-model="design.colors.background.primary" />
          </div>
          <div class="color-section">
            <ColorPicker label="Primary" v-model="design.colors.brand.primary" />
          </div>
          <div class="color-section">
            <ColorPicker label="Text" v-model="design.colors.text.default" />
          </div>
          <div class="color-section">
            <ColorPicker label="Label" v-model="design.colors.text.label" />
          </div>
          <div class="color-section">
            <ColorPicker label="Answers text" v-model="design.colors.text.element" />
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section-title">Typography</h4>
        <FontPicker v-model="design.font" :fonts="availableFonts" />
        <FontInput
          label="Custom font"
          accept=".woff,.woff2"
          v-model="design.font_id"
          :upload-config="{
            type: 'font',
            organizationId: organization.id,
            context: 'organization',
          }"
        />
      </div>
      <Divider />
      <div class="section">
        <h3 class="section-title">Border Radius</h3>
        <RadioGroup
          v-model="design.border_radius"
          :options="borderRadiusOptions"
          @update:modelValue="(value) => (design.border_radius = value)"
        />
      </div>

      <div class="section">
        <h3 class="section-title">Custom CSS</h3>
        <div class="custom-css-editor">
          <Codemirror
            v-model="design.custom_css"
            placeholder="Add your custom CSS here..."
            :style="{ height: '400px' }"
            :indent-with-tab="true"
            :tab-size="2"
            :extensions="extensions"
            @ready="handleReady"
            @focus="handleEditorFocus"
            @blur="handleEditorBlur"
          />
        </div>
      </div>
      <Divider />

      <div class="section">
        <h3 class="section-title">Danger zone 😨</h3>
        <Btn
          text="Delete design"
          @click="deleteDesign"
          variant="danger"
          :loading="isDeleting"
          :disabled="isDeleting"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, ref, computed, onMounted, shallowRef, watch } from 'vue';
  import { useDesignStore } from '@/store/designStore';
  import { useFormStore } from '@/store/formStore';
  import { getDefaultDesign } from '@/utils/defaultDesign';
  import { useUserStore } from '@/store/userStore';

  import { Codemirror } from 'vue-codemirror';
  import { EditorView } from '@codemirror/view';
  import { css } from '@codemirror/lang-css';
  import { oneDark } from '@codemirror/theme-one-dark';

  import Btn from '@/components/partials/Btn.vue';
  import FontPicker from '@/components/inputs/FontPicker.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import FontInput from '@/components/inputs/FontInput.vue';
  import ColorPicker from '@/components/inputs/ColorPicker.vue';
  import RadioGroup from '@/components/inputs/RadioGroup.vue';
  import DesignPreset from './design/DesignPreset.vue';
  import Divider from '@/components/partials/Divider.vue';
  import Message from '@/components/partials/Message.vue';

  export default {
    name: 'SidebarDesign',
    components: {
      Codemirror,
      Btn,
      FontPicker,
      TextInput,
      FontInput,
      ColorPicker,
      RadioGroup,
      DesignPreset,
      Divider,
      RadioGroup,
      Message,
    },

    setup(props, { emit }) {
      const designStore = useDesignStore();
      const formStore = useFormStore();
      const userStore = useUserStore();

      const isEditing = ref(false);
      const isNew = ref(false);
      const isDeleting = ref(false);
      const isSaving = ref(false);
      const organization = userStore.currentOrganization;

      const savedDesigns = computed(() => designStore.designs);
      const hasUnsavedChanges = computed(() => designStore.hasUnsavedChanges);
      const currentDesignId = computed(() => formStore.currentForm?.design_id);

      const saveText = computed(() => {
        if (isNew.value) {
          return 'Create';
        }
        return hasUnsavedChanges.value ? 'Save' : 'Saved';
      });

      onMounted(async () => {
        try {
          await designStore.fetchDesigns();
        } catch (error) {
          console.error('Failed to fetch designs:', error);
        }
      });

      // Initialize design with default values
      const design = reactive(getDefaultDesign());

      watch(
        () => design,
        (newDesign) => {
          designStore.updateDesign(design);
        },
        { deep: true }
      );

      const borderRadiusOptions = [
        { label: 'None', value: 'none' },
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'X-Large', value: 'xlarge' },
        { label: 'Full', value: 'full' },
      ];

      const activeTabs = reactive({
        button: 'default',
        choice: 'hover',
      });

      const startNewDesign = () => {
        // Reset to default design and override specific properties
        Object.assign(design, {
          ...getDefaultDesign(),
          name: `Design ${designStore.designs.length + 1}`,
          category: 'custom',
        });
        isNew.value = true;
        isEditing.value = true;
      };

      const editDesign = (preset) => {
        Object.assign(design, preset);
        formStore.setFormDesign(preset.id);
        isEditing.value = true;
      };

      const saveDesign = async () => {
        isSaving.value = true;
        if (isNew.value) {
          await designStore.createDesign({
            ...design,
          });
        } else {
          await designStore.saveCurrentDesign();
        }
        isNew.value = false;
        isEditing.value = false;
        isSaving.value = false;
      };

      const cancelEdit = () => {
        isEditing.value = false;
        isNew.value = false;
      };

      const applyDesign = async (preset) => {
        // Update and save the form with the new design
        await formStore.setFormDesign(preset.id);
      };

      const deleteDesign = async () => {
        isDeleting.value = true;
        await designStore.deleteDesign(design.id);
        isEditing.value = false;
        isDeleting.value = false;
      };

      const view = shallowRef();
      const handleReady = (payload) => {
        view.value = payload.view;
      };

      const extensions = [
        css(),
        oneDark,
        EditorView.theme({
          '&': {
            height: '400px',
            backgroundColor: '#282c34',
          },
          '.cm-content': {
            color: '#abb2bf',
          },
          '.cm-gutters': {
            backgroundColor: '#282c34',
            color: '#676f7d',
            border: 'none',
          },
          '&.cm-focused .cm-cursor': {
            borderLeftColor: '#528bff',
          },
          '.cm-activeLine': {
            backgroundColor: '#2c313c',
          },
          '.cm-selectionMatch': {
            backgroundColor: '#3E4451',
          },
          '.cm-matchingBracket': {
            color: '#98c379 !important',
            backgroundColor: '#3E4451',
          },
        }),
      ];

      const handleEditorFocus = () => {
        emit('editor-focus', true);
      };

      const handleEditorBlur = () => {
        emit('editor-focus', false);
      };

      return {
        design,
        organization,
        borderRadiusOptions,
        savedDesigns,
        currentDesignId,
        isEditing,
        isNew,
        startNewDesign,
        editDesign,
        saveDesign,
        cancelEdit,
        applyDesign,
        deleteDesign,
        hasUnsavedChanges,
        saveText,
        isDeleting,
        currentForm: formStore.currentForm,
        activeTabs,
        extensions,
        handleReady,
        designStore,
        isSaving,
        handleEditorFocus,
        handleEditorBlur,
      };
    },
  };
</script>

<style scoped lang="scss">
  .design-sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;

    .design-editor {
      height: 100%;
      overflow-y: auto;
      flex: 1;
    }

    .designs-list {
      height: 100%;
      overflow-y: auto;
      flex: 1;
    }
    .section {
      margin-bottom: 2rem;

      &__title {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--slate-800);
        margin-bottom: 1rem;
      }
      .color-group {
        display: flex;
      }
    }

    .designs-header,
    .editor-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .designs-grid {
      display: grid;
      gap: 1rem;
    }

    .color-pickers {
      display: grid;
      gap: 0.25rem;
      .color-section {
        :deep(.sf-input) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s;

      &-primary {
        background-color: var(--color-primary);
        color: white;
        border: none;

        &:hover {
          opacity: 0.9;
        }
      }

      &-text {
        background: none;
        border: none;
        color: var(--color-text);

        &:hover {
          color: var(--color-primary);
        }
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    .custom-css-editor {
      border: 1px solid var(--slate-200);
      border-radius: 4px;
      overflow: hidden;

      :deep(.cm-editor) {
        height: 400px;
        font-family: 'Fira Code', monospace;
        // background-color: #282c34;
        // color: #abb2bf;
      }
      :deep(.cm-scroller) {
        // Add padding for better readability
        padding: 8px 0;
      }
    }
  }
</style>
