import axios from 'axios';
import { uid } from 'uid';

class AutomationService {
  static async getAvailableActions() {
    const response = await axios.get('/automations/available-actions');
    return response.data;
  }

  static async createAutomation() {
    const automation = {
      name: 'New Automation',
      trigger: {
        id: uid(),
        position: { x: 290, y: 50 },
        type: 'trigger',
        data: {
          type: 'form_submission',
          config: {
            formId: null,
          },
        },
      },
      steps: [],
      isActive: false,
    };
    const response = await axios.post('/automations', automation);
    return response;
  }

  static async getAutomations() {
    const response = await axios.get('/automations');
    return response.data;
  }
}

export default AutomationService;
