<template>
  <div class="register-page">
    <div class="register-page__main">
      <div class="register-page__wrap">
        <h2 class="register-page__title">Create your account</h2>
        <p class="mt-2">
          Already have an account?
          <router-link to="/login">Sign in!</router-link>
        </p>
        <form @submit.prevent="register" class="register-page__form mt-8">
          <!-- <div class="social-login">
            <Btn
              type="button"
              @click="signInWithGoogle"
              variant="outline"
              class="width-100 mb-4"
              text="Continue with Google"
            >
              <template #icon>
                <img src="@/assets/img/google-icon.svg" alt="Google" class="google-icon" />
              </template>
            </Btn>
            <div class="divider">
              <span>or</span>
            </div>
          </div> -->
          <TextInput :label="userData.email.label" v-model="userData.email.binding" type="email" />
          <TextInput
            :label="userData.password.label"
            v-model="userData.password.binding"
            type="password"
          />
          <hr />
          <TextInput
            :label="userData.first_name.label"
            v-model="userData.first_name.binding"
            type="text"
          />
          <TextInput
            :label="userData.last_name.label"
            v-model="userData.last_name.binding"
            type="text"
          />
          <TextInput
            :label="userData.organization_name.label"
            v-model="userData.organization_name.binding"
            type="text"
          />
          <Btn
            type="submit"
            :loading="isLoading"
            @click="register"
            variant="secondary"
            icon="user"
            class="width-100 mt-2"
            text="Create account"
          />
        </form>
      </div>
    </div>
    <div class="register-page__aside">
      <div class="register-page__aside__content">
        <img src="@/assets/img/auth-visual@2x.png" alt="FormFlow" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';
  import { useUserStore } from '@/store/userStore';
  import axios from 'axios';
  import { supabase } from '@/services/supabase';
  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useUIStore } from '@/store/uiStore';
  import WelcomeModal from '@/components/onboarding/WelcomeModal.vue';

  export default {
    components: {
      TextInput,
      Btn,
      WelcomeModal,
    },
    setup() {
      const isLoading = ref(false);
      const userData = ref({
        email: {
          label: 'Email address',
          binding: '',
        },
        password: {
          label: 'Password',
          binding: '',
        },
        first_name: {
          label: 'First name',
          binding: '',
        },
        last_name: {
          label: 'Last name',
          binding: '',
        },
        organization_name: {
          label: 'Workspace / organization name',
          binding: '',
        },
      });
      const router = useRouter();
      const userStore = useUserStore();
      const uiStore = useUIStore();

      const register = async () => {
        isLoading.value = true;
        try {
          const response = await axios.post('/users/register', {
            email: userData.value.email.binding,
            password: userData.value.password.binding,
            firstName: userData.value.first_name.binding,
            lastName: userData.value.last_name.binding,
            organizationName: userData.value.organization_name.binding,
          });

          if (response.status === 201) {
            // Log in the user
            const user = await userStore.login(
              userData.value.email.binding,
              userData.value.password.binding
            );

            router.push('/all-flows');

            // Show welcome modal
            const welcomeModalId = uiStore.addModal(WelcomeModal, {
              title: 'Welcome to Smartflow!',
              description: 'Please check your email to verify your account.',
              onSubmit: async () => {},
              onClose: async () => {
                uiStore.closeModal(welcomeModalId);
                router.push('/all-flows');
              },
            });
          }
        } catch (error) {
          let errorMessage = 'Registration failed. Please try again.';
          if (error.response?.data?.message) {
            errorMessage = error.response.data.message;
          } else if (error.message) {
            errorMessage = error.message;
          }
          uiStore.addModal(null, {
            title: 'Something went wrong',
            description: 'Cannot create account. Please try again. Does this email already exist?',
          });
        } finally {
          isLoading.value = false;
        }
      };

      return {
        isLoading,
        userData,
        register,
        TextInput,
        Btn,
      };
    },
  };
</script>
<style scoped lang="scss">
  .register-page {
    display: flex;
    min-height: 100vh;
    @include lg-down {
      flex-direction: column;
    }
    &__main {
      width: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 100%;
      background-color: #ffffff;
      p a {
        text-decoration: underline;
      }
      @include lg-down {
        width: 100%;
        min-height: 100vh;
      }
    }
    &__form {
      max-width: 100%;
      width: 400px;
    }
    &__aside {
      width: 50%;
      min-height: 100%;
      background-color: #092220;
      background: linear-gradient(49.61deg, #092220 46.86%, #1b4743 101.41%);
      padding: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-width: 400px;
      }
      @include lg-down {
        width: 100%;
      }
    }
  }
</style>
