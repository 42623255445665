import axios from 'axios';

class IntegrationService {
  static async connectGoogleCalendar() {
    try {
      const response = await axios.get('/integrations/google-calendar');
      return response.data;
    } catch (error) {
      console.error('Error fetching Google Calendar URL:', error);
      throw error;
    }
  }
  static async listCalendars(integration) {
    try {
      const response = await axios.get(`/integrations/google-calendar/calendars`);
      return response.data;
    } catch (error) {
      console.error('Error fetching calendars:', error);
      throw error;
    }
  }
}

export default IntegrationService;
