<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">{{ label }}</label>
    <div class="wysiwyg-editor" :class="{ 'wysiwyg-editor--has-error': localError }">
      <div class="editor-toolbar">
        <button
          v-for="item in toolbarItems"
          :key="item.action"
          @click="item.action"
          :class="{ active: item.isActive?.() }"
          class="toolbar-button"
        >
          <BaseIcon :name="item.icon" size="14" />
        </button>
      </div>
      <div ref="editorRef" class="editor-content" />
    </div>
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { defineComponent, onBeforeUnmount, onMounted, watch, ref } from 'vue';
  import { Editor } from '@tiptap/vue-3';
  import StarterKit from '@tiptap/starter-kit';
  import BaseIcon from '@shared/components/BaseIcon.vue';

  export default defineComponent({
    name: 'WysiwygEditor',
    components: { BaseIcon },

    props: {
      modelValue: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: '',
      },
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const editorRef = ref(null);
      let editor = null;
      const localError = ref(props.error);

      const toolbarItems = [
        {
          icon: 'bold',
          action: () => editor.chain().focus().toggleBold().run(),
          isActive: () => editor?.isActive('bold'),
        },
        {
          icon: 'italic',
          action: () => editor.chain().focus().toggleItalic().run(),
          isActive: () => editor?.isActive('italic'),
        },
        {
          icon: 'list-ul',
          action: () => editor.chain().focus().toggleBulletList().run(),
          isActive: () => editor?.isActive('bulletList'),
        },
      ];

      onMounted(() => {
        editor = new Editor({
          element: editorRef.value,
          extensions: [StarterKit],
          content: props.modelValue,
          onUpdate: ({ editor }) => {
            emit('update:modelValue', editor.getHTML());
          },
        });
      });

      watch(
        () => props.modelValue,
        (newValue) => {
          const isSame = newValue === editor.getHTML();
          if (!isSame) {
            editor.commands.setContent(newValue, false);
          }
        }
      );

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      onBeforeUnmount(() => {
        if (editor) {
          editor.destroy();
        }
      });

      return {
        toolbarItems,
        editorRef,
        localError,
      };
    },
  });
</script>

<style lang="scss" scoped>
  .wysiwyg-editor {
    border: 1px solid var(--slate-200);
    border-radius: 0.375rem;
    overflow: hidden;
    &--has-error {
      border-color: var(--red-500);
    }

    .editor-toolbar {
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem;
      border-bottom: 1px solid var(--slate-200);
      background: var(--slate-50);
    }

    .toolbar-button {
      padding: 0.25rem;
      border: none;
      background: transparent;
      border-radius: 0.25rem;
      cursor: pointer;
      color: var(--slate-600);
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--slate-100);
      }

      &.active {
        color: var(--brand-600);
        background: var(--brand-50);
      }
    }

    .editor-content {
      padding: 0.75rem;
      min-height: 100px;

      :deep(.ProseMirror) {
        outline: none;

        > * + * {
          margin-top: 0.75em;
        }

        ul {
          padding-left: 1rem;
        }

        p.is-empty::before {
          content: attr(data-placeholder);
          float: left;
          color: var(--slate-400);
          pointer-events: none;
          height: 0;
        }
      }
    }
  }
</style>
