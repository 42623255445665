import { defineStore } from 'pinia';
import { useUserStore } from './userStore';
import {
  getDesigns,
  updateDesign,
  createDesign,
  deleteDesign,
  getDesign,
} from '@/services/designService';
import { getDefaultDesign } from '@/utils/defaultDesign';

export const useDesignStore = defineStore('design', {
  state: () => ({
    designs: [],
    currentDesign: getDefaultDesign(),
    hasUnsavedChanges: false,
  }),

  actions: {
    clearCurrentDesign() {
      this.$patch((state) => {
        state.currentDesign = getDefaultDesign();
      });
    },
    updateDesign(design) {
      this.$patch((state) => {
        state.currentDesign = { ...design };
        state.hasUnsavedChanges = true;
      });
    },

    async fetchDesigns() {
      const userStore = useUserStore();
      const designs = await getDesigns(userStore.currentOrganization.id);
      this.$patch((state) => {
        state.designs = designs;
      });
    },
    async createDesign(data) {
      const userStore = useUserStore();
      const userId = userStore.user.id;

      const designData = {
        name: data.name,
        font: data.font,
        colors: data.colors,
        border_radius: data.border_radius,
        uid: userId,
        category: data.category,
      };

      const response = await createDesign(designData);
      this.$patch(async (state) => {
        state.designs = await getDesigns();
      });
      return response;
    },
    async loadDesign(id) {
      if (!id) {
        this.clearCurrentDesign();
        return;
      }

      const design = await getDesign(id);
      this.$patch((state) => {
        state.currentDesign = design;
      });
    },

    async saveCurrentDesign() {
      const design = {
        ...this.currentDesign,
      };
      const updatedDesign = await updateDesign(this.currentDesign.id, design);
      this.$patch((state) => {
        state.hasUnsavedChanges = false;
      });
      const idx = this.designs.findIndex((d) => d.id === this.currentDesign.id);
      if (idx > -1) {
        this.designs[idx] = this.currentDesign;
      }
    },

    async deleteDesign(id) {
      await deleteDesign(id);
      const idx = this.designs.findIndex((d) => d.id === id);
      if (idx > -1) {
        this.designs.splice(idx, 1);
      }
    },
  },
});
