<template>
  <div class="data-table">
    <div class="data-table__heading">
      <div class="data-table__heading-title">
        {{ title }}
        <span class="data-table__heading-count">{{ items.length }}</span>
      </div>
      <div class="data-table__heading-actions">
        <slot name="table-actions" />
      </div>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="items"
      :loading="loading"
      buttons-pagination
      @click-row="$emit('row-click', $event)"
      table-class-name="custom-table"
    >
      <!-- <template v-for="slot in $slots" #[slot.name]="props">
        <slot :name="slot.name" v-bind="props" />
      </template> -->
      <template v-for="(_, name) in $slots" :key="name" #[name]="slotData">
        <slot :name="name" v-bind="{ ...slotData, item: slotData.item || slotData }" />
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
  import EasyDataTable from 'vue3-easy-data-table';
  import 'vue3-easy-data-table/dist/style.css';

  export default {
    components: {
      EasyDataTable,
    },

    props: {
      title: {
        type: String,
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['row-click'],
  };
</script>

<style lang="scss" scoped>
  .data-table {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: var(--white);
    border: 1px solid var(--slate-200);

    &__heading {
      font-size: 20px;
      font-weight: 600;
      color: var(--slate-700);
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__heading-count {
      font-size: 20px;
      font-weight: 600;
      color: var(--slate-400);
      margin-left: 0.25rem;
    }

    :deep(.custom-table) {
      --easy-table-border: 0px solid transparent;
      --easy-table-row-border: 1px solid var(--slate-300);

      --easy-table-header-font-size: 14px;
      --easy-table-header-height: 70px;
      --easy-table-header-font-color: var(--slate-900);
      --easy-table-header-background-color: var(--slate-50);

      --easy-table-header-item-padding: 10px 15px;

      --easy-table-body-even-row-font-color: #fff;
      --easy-table-body-even-row-background-color: #4c5d7a;

      --easy-table-body-row-font-color: var(--slate-700);
      --easy-table-body-row-background-color: var(--white);
      --easy-table-body-row-height: 70px;
      --easy-table-body-row-font-size: 14px;

      --easy-table-body-row-hover-font-color: var(--slate-900);
      --easy-table-body-row-hover-background-color: var(--slate-100);

      --easy-table-body-item-padding: 10px 15px;

      --easy-table-footer-background-color: var(--slate-100);
      --easy-table-footer-font-color: var(--slate-900);
      --easy-table-footer-font-size: 14px;
      --easy-table-footer-padding: 0px 10px;
      --easy-table-footer-height: 60px;

      --easy-table-rows-per-page-selector-width: 70px;
      --easy-table-rows-per-page-selector-option-padding: 10px;
      --easy-table-rows-per-page-selector-z-index: 1;

      --easy-table-scrollbar-track-color: #2d3a4f;
      --easy-table-scrollbar-color: #2d3a4f;
      --easy-table-scrollbar-thumb-color: #4c5d7a;
      --easy-table-scrollbar-corner-color: #2d3a4f;

      --easy-table-loading-mask-background-color: var(--slate-900);
      border-radius: 0.5rem;
      overflow: hidden;
      .buttons-pagination {
        .item.button {
          &.active {
            background-color: var(--slate-900);
            border-color: var(--slate-900) !important;
          }
        }
      }
      tbody tr {
        cursor: pointer;
      }
    }
  }
</style>
