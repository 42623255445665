<template>
  <div class="my-account-settings">
    <div class="container">
      <PageHeader title="My account" description="Manage your account settings" />
      <div class="my-account">
        <div class="form-section">
          <div class="avatar-section">
            <img
              :src="inputs.avatar_url.binding || '/default-avatar.png'"
              alt="Profile avatar"
              class="current-avatar"
              referrerpolicy="no-referrer"
              crossorigin="anonymous"
            />
            <div class="avatar-controls">
              <FileInput
                :id="inputs.avatar_url.id"
                :label="inputs.avatar_url.label"
                accept="image/*"
                @file-selected="handleAvatarUpload"
              />
              <Btn
                v-if="inputs.avatar_url.binding"
                text="Remove Avatar"
                variant="text"
                @click="removeAvatar"
              />
            </div>
          </div>

          <TextInput
            :id="inputs.first_name.id"
            :label="inputs.first_name.label"
            v-model="inputs.first_name.binding"
            :error="inputs.first_name.error"
            :rules="inputs.first_name.rules"
            @validate="onValidate('first_name', $event)"
          />

          <TextInput
            :id="inputs.last_name.id"
            :label="inputs.last_name.label"
            v-model="inputs.last_name.binding"
            :error="inputs.last_name.error"
            :rules="inputs.last_name.rules"
            @validate="onValidate('last_name', $event)"
          />

          <TextInput
            :id="inputs.email.id"
            :label="inputs.email.label"
            v-model="inputs.email.binding"
            :error="inputs.email.error"
            :rules="inputs.email.rules"
            @validate="onValidate('email', $event)"
            disabled="true"
          />
          <div v-if="isGoogleUser" class="google-email-notice">
            Email cannot be changed for Google-authenticated accounts
          </div>
        </div>
        <div class="settings-footer">
          <Btn
            :text="saving ? 'Saving...' : 'Save Changes'"
            @click="saveSettings"
            :disabled="!hasChanges"
            :loading="saving"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted, watch } from 'vue';
  import { useValidation } from '@/composables/useValidation';
  import TextInput from '@/components/inputs/TextInput.vue';
  import FileInput from '@/components/inputs/FileInput.vue';
  // import { useSupabaseStorage } from '@/composables/useSupabaseStorage';

  import PageHeader from '@/components/partials/PageHeader.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useUIStore } from '@/store/uiStore';
  import { useUserStore } from '@/store/userStore';

  export default {
    name: 'SettingsMyAccountview',

    components: {
      PageHeader,
      Btn,
      TextInput,
      FileInput,
    },

    setup() {
      const userStore = useUserStore();
      const user = computed(() => userStore.user);
      const userData = computed(() => userStore.userData);
      const saving = ref(false);
      const hasChanges = ref(false);
      const programmaticUpdate = ref(false);
      const originalSettings = ref({});
      // const { uploadFile, deleteFile } = useSupabaseStorage();

      const isGoogleUser = computed(() => {
        return user.value?.app_metadata?.provider === 'google';
      });

      const { inputs, validateForm, onValidate } = useValidation({
        first_name: {
          id: 'first_name',
          label: 'First Name',
          binding: '',
          error: null,
          rules: ['required'],
          touched: false,
        },
        last_name: {
          id: 'last_name',
          label: 'Last Name',
          binding: '',
          error: null,
          rules: ['required'],
          touched: false,
        },
        email: {
          id: 'email',
          label: 'Email',
          binding: '',
          error: null,
          rules: ['required', 'email'],
          touched: false,
        },
        avatar_url: {
          id: 'avatar_url',
          label: 'Profile Picture',
          binding: '',
          error: null,
          rules: [],
          touched: false,
        },
      });

      const initializeSettings = () => {
        programmaticUpdate.value = true;
        if (user.value) {
          inputs.value.first_name.binding = user?.value?.user_metadata?.first_name || '';
          inputs.value.last_name.binding = user?.value?.user_metadata?.last_name || '';
          inputs.value.email.binding = user?.value?.user_metadata?.email || '';
          inputs.value.avatar_url.binding = user?.value?.user_metadata?.avatar_url || '';
        }
        originalSettings.value = JSON.parse(
          JSON.stringify(
            Object.keys(inputs.value).reduce((acc, key) => {
              acc[key] = inputs.value[key].binding;
              return acc;
            }, {})
          )
        );
        programmaticUpdate.value = false;
      };

      const handleAvatarUpload = async (file) => {
        try {
          const fileName = `avatars/${user.value.id}/${Date.now()}-${file.name}`;
          const { url } = await uploadFile(file, fileName, 'avatars');
          inputs.value.avatarUrl.binding = url;
        } catch (error) {
          console.error('Error uploading avatar:', error);
        }
      };

      const removeAvatar = async () => {
        try {
          if (inputs.value.avatarUrl.binding) {
            // Extract file path from URL
            const filePath = inputs.value.avatarUrl.binding.split('/').slice(-1)[0];
            await deleteFile(filePath, 'avatars');
          }
          inputs.value.avatarUrl.binding = '';
        } catch (error) {
          console.error('Error removing avatar:', error);
        }
      };

      const saveSettings = async () => {
        saving.value = true;
        try {
          const formattedSettings = {
            first_name: inputs.value.firstName.binding,
            last_name: inputs.value.lastName.binding,
            avatar_url: inputs.value.avatarUrl.binding,
          };

          // Only include email if it's changed and user is not Google-authenticated
          if (!isGoogleUser.value && inputs.value.email.binding !== user.value.email) {
            // Handle email change through Supabase Auth
            const { error } = await supabase.auth.updateUser({
              formattedSettings,
              email: inputs.value.email.binding,
            });

            if (error) throw error;
          }

          await userStore.updateUser(formattedSettings);
          originalSettings.value = JSON.parse(JSON.stringify(inputs.value));
          hasChanges.value = false;
        } catch (error) {
          console.error('Failed to save settings:', error);
        } finally {
          saving.value = false;
        }
      };

      onMounted(() => {
        initializeSettings();
      });

      return {
        user,
        inputs,
        hasChanges,
        saving,
        onValidate,
        saveSettings,
        handleAvatarUpload,
        removeAvatar,
        isGoogleUser,
      };
    },
  };
</script>

<style scoped lang="scss">
  .my-account-settings {
    .avatar-section {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-bottom: 2rem;

      .current-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }

      .avatar-controls {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
</style>
