<template>
  <div class="node-settings">
    <!-- Trigger Node Settings -->
    <template v-if="type === 'trigger'">
      <SelectInput
        :id="`trigger-type`"
        :label="'Trigger type'"
        v-model="localData.type"
        :options="triggerTypes"
        :disabled="hasConnections"
        @update:modelValue="handleTypeChange"
      />

      <template v-if="localData.type === 'form_submission'">
        <template v-if="forms.length">
          <SelectInput
            id="form-select"
            label="Select funnel"
            v-model="localData.config.formId"
            :options="formOptions"
            @update:modelValue="emitUpdate"
          />
        </template>
        <template v-else>
          <div class="alert alert--info">
            No funnels found. First add a funnel at the
            <router-link :to="{ path: '/all-flows' }">funnels page</router-link>
            .
          </div>
        </template>
      </template>

      <template v-if="localData.type === 'status_change'">
        <SelectInput
          id="from-status"
          label="From status"
          v-model="localData.config.fromStatus"
          :options="dealStatuses"
          @update:modelValue="emitUpdate"
        />
        <SelectInput
          id="to-status"
          label="To status"
          v-model="localData.config.toStatus"
          :options="dealStatuses.filter((status) => status.value !== 'new')"
        />
      </template>

      <template v-if="localData.type === 'scheduled_event'">
        <SelectInput
          id="frequency"
          label="Frequency"
          v-model="localData.config.frequency"
          :options="[
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
          ]"
        />

        <!-- Schedule settings based on frequency -->
        <ScheduleSettings
          v-model="localData.config.schedule_settings"
          :frequency="localData.config.frequency"
        />
      </template>
    </template>

    <!-- Action Node Settings -->
    <template v-else-if="type === 'action'">
      <!-- <SelectInput
        :id="`action-type`"
        :label="'Action type'"
        v-model="localData.type"
        :options="actionTypes"
        :disabled="hasConnections"
        @update:modelValue="handleTypeChange"
      /> -->
      <ConditionConfig
        v-if="localData.type === 'condition'"
        v-model="localData.config"
        :currentAutomation="currentAutomation"
      />

      <!-- <DelayConfig v-else-if="localData.type === 'delay'" v-model="localData.config" /> -->

      <TaskConfig
        v-else-if="localData.type === 'task'"
        :modelValue="localData.config"
        @update:config="handleConfigUpdate"
      />
    </template>
  </div>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import ConditionConfig from './configs/ConditionConfig.vue';
  //   import DelayConfig from './configs/DelayConfig.vue';
  import TaskConfig from './configs/TaskConfig.vue';
  //   import ScheduleSettings from './configs/ScheduleSettings.vue';
  import { useFormStore } from '@/store/formStore';
  import { TRIGGER_TYPES } from '@/constants/automations';
  import { ACTION_TYPES } from '@/constants/automations';
  import { DEAL_STATUSES } from '@/constants/statuses';

  export default {
    name: 'NodeSettings',

    components: {
      SelectInput,
      ConditionConfig,
      //   DelayConfig,
      TaskConfig,
      //   ScheduleSettings,
    },

    props: {
      type: {
        type: String,
        required: true,
        validator: (value) => ['trigger', 'action'].includes(value),
      },
      nodeData: {
        type: Object,
        required: true,
      },
      hasConnections: {
        type: Boolean,
        default: false,
      },
      currentAutomation: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: ['update'],

    setup(props, { emit }) {
      const formStore = useFormStore();
      const localData = ref({ ...props.nodeData });

      const forms = computed(() => formStore.forms);
      const formOptions = computed(() =>
        forms.value.map((form) => ({
          value: form.id,
          label: form.name,
        }))
      );

      const handleTypeChange = () => {
        localData.value.config = getDefaultConfig(localData.value.type);
        emitUpdate();
      };

      const getDefaultConfig = (type) => {
        switch (type) {
          case 'form_submission':
            return { formId: null };
          case 'status_change':
            return { fromStatus: null, toStatus: null };
          case 'scheduled_event':
            return {
              frequency: 'daily',
              schedule_settings: { time: '09:00' },
            };
          case 'condition':
            return { conditions: [] };
          case 'delay':
            return { duration: 1, unit: 'hours' };
          case 'task':
            return { title: '', description: '', dueDate: null };
          default:
            return {};
        }
      };

      const handleConfigUpdate = (newConfig) => {
        if (JSON.stringify(localData.value.config) === JSON.stringify(newConfig)) {
          return;
        }
        localData.value.config = newConfig;
        emitUpdate();
      };

      const emitUpdate = () => {
        emit('update', localData.value);
      };

      watch(
        () => props.nodeData,
        (newVal) => {
          if (JSON.stringify(localData.value) !== JSON.stringify(newVal)) {
            localData.value = { ...newVal };
          }
        },
        { deep: true }
      );

      return {
        localData,
        forms,
        formOptions,
        handleTypeChange,
        emitUpdate,
        triggerTypes: TRIGGER_TYPES,
        actionTypes: ACTION_TYPES,
        dealStatuses: DEAL_STATUSES,
        handleConfigUpdate,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .node-settings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .alert {
      margin-top: 1rem;
      padding: 0.75rem;
      border-radius: 6px;
      font-size: 0.875rem;

      &--info {
        background: var(--blue-50);
        border: 1px solid var(--blue-200);
        color: var(--blue-700);
      }

      a {
        color: var(--blue-600);
        text-decoration: underline;
      }
    }
  }
</style>
