<template>
  <div class="sf-el text-item">
    <div class="sf-text">
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'TextElement',
    props: {
      content: String,
    },
    setup(props, { emit }) {},
  };
</script>

<style scoped lang="scss">
  .sf-text {
  }
</style>
