<template>
  <div class="settings-layout">
    <Toolbar type="settings" />
    <div class="settings-container container">
      <div class="settings">
        <div class="settings-sidebar">
          <div class="settings-tabs">
            <router-link to="/all-flows" class="tab">
              <BaseIcon name="arrow" size="12" rotate="180" />
              Back to dashboard
            </router-link>
            <router-link to="/settings/my-account" class="tab">My account</router-link>
            <router-link to="/settings/organization" class="tab" v-if="isAdmin">
              Organization
            </router-link>
            <router-link to="/settings/integrations" class="tab" v-if="isAdmin">
              Integrations
            </router-link>
            <router-link to="/settings/billing" class="tab" v-if="isAdmin">Billing</router-link>
          </div>
        </div>
        <div class="settings-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoleCheck } from '@/composables/useRoleCheck';
  import Toolbar from '@/components/layout/Toolbar.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';

  export default {
    components: {
      Toolbar,
      BaseIcon,
    },
    setup() {
      const { isAdmin, isMember } = useRoleCheck();
      return { isAdmin, isMember };
    },
  };
</script>

<style scoped lang="scss">
  .settings-layout {
  }
  .settings-container {
    padding: 2rem 0;
  }
  .settings {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 100%;
    // min-height: calc(100vh - 8rem);
    margin: 0 auto;
    .settings-sidebar {
      width: 240px;
      border-right: 1px solid #eee;
      padding: 2rem 0;
    }
    .settings-tabs {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0 1rem;
      .tab {
        padding: 0.75rem 1rem;
        text-align: left;
        background: none;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 0.95rem;
        color: var(--slate-500);
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &:hover {
          background: #f5f5f5;
          color: #333;
        }

        &.active,
        &.router-link-exact-active {
          background: var(--brand-50);
          color: var(--brand-500);
          font-weight: 500;
        }
      }
    }
    .settings-content {
      flex: 1;
      padding: 2rem;
      position: relative;
    }
    .settings-footer {
      padding: 1rem;
      background: white;
      border-top: 1px solid #eee;
      text-align: right;
      margin-top: 2rem;
    }
  }
</style>
