<template>
  <div class="submission-table">
    <div class="submission-table__heading">
      <span>
        Submissions overview
        <span class="submission-table__heading-count">{{ formattedSubmissions.length }}</span>
      </span>
      <Btn
        variant="tertiary"
        size="sm"
        icon="export"
        :text="isExporting ? 'Exporting...' : 'Export to CSV'"
        @click="$emit('export')"
        :loading="isExporting"
      ></Btn>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="formattedSubmissions"
      :loading="loading"
      buttons-pagination
      @click-row="$emit('row-click', $event)"
      table-class-name="custom-table"
    >
      <template #item-lastUpdated="{ lastUpdated, status }">
        <div class="submission-value">
          <BaseIcon v-if="status === 'partial'" name="draw" size="16" />
          <div v-else class="submission-table__dot"></div>
          {{ lastUpdated }}
        </div>
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
  import { watch } from 'vue';
  import { formatDate } from '@/utils/date';
  import { ELEMENTS } from '@/constants/elements';
  import EasyDataTable from 'vue3-easy-data-table';
  import 'vue3-easy-data-table/dist/style.css';

  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  export default {
    components: {
      EasyDataTable,
      BaseIcon,
      Btn,
    },

    props: {
      submissions: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      form: {
        type: Object,
        required: true,
      },
      isExporting: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['row-click', 'export'],

    setup(props) {
      const headers = [
        { text: 'Last updated at', value: 'lastUpdated' },
        { text: 'Name', value: 'leadName' },
        { text: 'Email', value: 'leadEmail' },
      ];

      function getFirstTwoNonContentFields(form) {
        // Flatten all elements from all pages
        const allElements = form.pages.flatMap((page) => page.elements);
        // Find first two non-content fields
        return allElements
          .filter((element) => {
            // Use the ELEMENTS constant to check if it's not in content category
            const elementDefinition = ELEMENTS.find((e) => e.type === element.type);
            return elementDefinition && elementDefinition.category !== 'content';
          })
          .slice(0, 2);
      }

      function getAnswersForFields(submission, formFields, form) {
        return formFields
          .map((field) => {
            const answer = submission.data.find((a) => a.fieldId === field.id);
            if (!answer?.value) return null;

            // For multiple choice, map the IDs to actual choice content
            let displayValue = answer.value;
            if (field.type === 'multiple-choice' && Array.isArray(answer.value)) {
              displayValue = answer.value
                .map((choiceId) => field.props.choices.find((c) => c.id === choiceId)?.content)
                .filter(Boolean)
                .join(', ');
            }

            return {
              fieldId: field.id,
              fieldName: field.props.label,
              value: displayValue,
            };
          })
          .filter(Boolean);
      }

      // Get the first two non-content fields from the form
      const firstTwoFormFields = getFirstTwoNonContentFields(props.form);

      // Add headers for these fields
      firstTwoFormFields.forEach((field) => {
        headers.push({
          text: field.props.label,
          value: `field_${field.id}`,
        });
      });

      const formattedSubmissions = props.submissions.map((submission) => {
        const formattedDate = formatDate(submission.metadata.lastUpdated);

        // Get answers for our two fields
        const fieldAnswers = getAnswersForFields(submission, firstTwoFormFields, props.form);

        // Create the submission object with dynamic fields
        const submissionObj = {
          ...submission,
          lastUpdated: formattedDate,
          leadName: submission.lead?.name || '',
          leadEmail: submission.lead?.email || '',
        };

        // Add the field values to the submission object
        fieldAnswers.forEach((field) => {
          submissionObj[`field_${field.fieldId}`] = field.value;
        });

        return submissionObj;
      });

      return {
        headers,
        formattedSubmissions,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .submission-table {
    box-shadow:
      0 0 rgba(0, 0, 0, 0),
      0 0 rgba(0, 0, 0, 0),
      0 1px 2px 0 rgba(0, 0, 0, 0.05);

    border-radius: 0.5rem;
    overflow: hidden;
    background-color: var(--white);
    border: 1px solid var(--slate-200);
    &__heading {
      font-size: 20px;
      font-weight: 600;
      color: var(--slate-700);
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__heading-count {
      font-size: 20px;
      font-weight: 600;
      color: var(--slate-400);
      margin-left: 0.25rem;
    }
    :deep(.custom-table) {
      --easy-table-border: 0px solid transparent;
      --easy-table-row-border: 1px solid var(--slate-300);

      --easy-table-header-font-size: 14px;
      --easy-table-header-height: 70px;
      --easy-table-header-font-color: var(--slate-900);
      --easy-table-header-background-color: var(--slate-50);

      --easy-table-header-item-padding: 10px 15px;

      --easy-table-body-even-row-font-color: #fff;
      --easy-table-body-even-row-background-color: #4c5d7a;

      --easy-table-body-row-font-color: var(--slate-700);
      --easy-table-body-row-background-color: var(--white);
      --easy-table-body-row-height: 70px;
      --easy-table-body-row-font-size: 14px;

      --easy-table-body-row-hover-font-color: var(--slate-900);
      --easy-table-body-row-hover-background-color: var(--slate-100);

      --easy-table-body-item-padding: 10px 15px;

      --easy-table-footer-background-color: var(--slate-100);
      --easy-table-footer-font-color: var(--slate-900);
      --easy-table-footer-font-size: 14px;
      --easy-table-footer-padding: 0px 10px;
      --easy-table-footer-height: 60px;

      --easy-table-rows-per-page-selector-width: 70px;
      --easy-table-rows-per-page-selector-option-padding: 10px;
      --easy-table-rows-per-page-selector-z-index: 1;

      --easy-table-scrollbar-track-color: #2d3a4f;
      --easy-table-scrollbar-color: #2d3a4f;
      --easy-table-scrollbar-thumb-color: #4c5d7a;
      --easy-table-scrollbar-corner-color: #2d3a4f;

      --easy-table-loading-mask-background-color: var(--slate-900);
      border-radius: 0.5rem;
      overflow: hidden;
      .buttons-pagination {
        .item.button {
          &.active {
            background-color: var(--slate-900);
            border-color: var(--slate-900) !important;
          }
        }
      }
      tbody tr {
        cursor: pointer;
      }
    }

    // Keep your existing dot and icon styles
    &__dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--green-500);
      margin: 0 2px;
    }

    .icon {
      color: var(--slate-500);
    }

    .submission-value {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
</style>
