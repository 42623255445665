<template>
  <div class="automations-editor">
    <div class="container">
      <PageHeader title="Automation editor" description="Edit your automation">
        <template #actions>
          <Btn
            :disabled="!hasUnsavedChanges"
            @click="handleSaveAutomation"
            text="Save automation"
            :loading="isSaving"
          />
        </template>
      </PageHeader>
      <div class="automations-editor__heading">
        <div class="automations-editor__heading-title">
          <InplaceEdit
            :label="`<h3>${currentAutomation.name}</h3>`"
            :value="currentAutomation.name"
            @update="updateField('name', $event)"
          ></InplaceEdit>
        </div>
      </div>
    </div>
    <div class="automations-editor__view">
      <AutomationBuilder
        v-if="currentAutomation && !isLoading"
        :automation="currentAutomation"
        @update="handleAutomationUpdate"
        @save="handleSaveAutomation"
      />
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { debounce } from 'lodash-es';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useAutomationStore } from '@/store/automationStore';
  import AutomationBuilder from '@/components/automations/AutomationBuilder.vue';
  import InplaceEdit from '@/components/partials/InplaceEdit.vue';
  export default {
    name: 'AutomationsView',

    components: {
      PageHeader,
      Btn,
      AutomationBuilder,
      InplaceEdit,
    },

    setup() {
      const automationStore = useAutomationStore();
      const route = useRoute();
      const isSaving = ref(false);
      const automationId = computed(() => route.params.id);
      const isLoading = ref(true);
      // Store both the original and current state
      const originalAutomation = ref(null);
      const currentAutomation = ref({
        id: null,
        organization_id: null,
        name: '',
        trigger_type: null,
        trigger_details: {},
        created_by: null,
        updated_by: null,
        created_at: null,
        updated_at: null,
        description: null,
        status: 'active',
        steps: [],
        form_id: null,
      });

      const hasUnsavedChanges = computed(() => {
        if (!originalAutomation.value) return false;

        const compareOriginal = JSON.parse(JSON.stringify(originalAutomation.value));
        const compareCurrent = JSON.parse(JSON.stringify(currentAutomation.value));

        // Remove fields that shouldn't affect "unsaved" state
        delete compareOriginal.updated_at;
        delete compareCurrent.updated_at;

        return JSON.stringify(compareCurrent) !== JSON.stringify(compareOriginal);
      });

      const handleAutomationUpdate = debounce((newAutomation) => {
        // Deep clone to prevent reference issues
        const clonedAutomation = JSON.parse(JSON.stringify(newAutomation));

        // Preserve existing steps data
        clonedAutomation.steps = clonedAutomation.steps.map((step) => {
          const existingStep = currentAutomation.value?.steps?.find((es) => es.id === step.id);

          return {
            ...step,
            action_details: step.action_details || existingStep?.action_details || {},
            configuration: step.configuration || existingStep?.configuration || {},
          };
        });

        // Prevent unnecessary updates
        if (JSON.stringify(currentAutomation.value) === JSON.stringify(clonedAutomation)) {
          return;
        }

        currentAutomation.value = clonedAutomation;
      }, 300);

      const handleSaveAutomation = async () => {
        if (!hasUnsavedChanges.value) return;

        try {
          isSaving.value = true;
          await automationStore.updateAutomation(currentAutomation.value);
          originalAutomation.value = JSON.parse(JSON.stringify(currentAutomation.value));
        } catch (error) {
          console.error('Failed to save automation:', error);
          // You might want to show an error notification here
        } finally {
          isSaving.value = false;
        }
      };

      onMounted(async () => {
        if (automationId.value) {
          try {
            const response = await automationStore.fetchAutomation(automationId.value);
            if (response) {
              // Deep clone to prevent reference issues
              const clonedResponse = JSON.parse(JSON.stringify(response));
              currentAutomation.value = clonedResponse;
              originalAutomation.value = clonedResponse;
              isLoading.value = false;
            }
          } catch (error) {
            console.error('Failed to fetch automation:', error);
            // You might want to show an error notification here
          }
        }
      });

      // watch(
      //   () => currentAutomation.value,
      //   (newVal) => {
      //     if (newVal) {
      //       handleAutomationUpdate(newVal);
      //     }
      //   },
      //   { deep: true }
      // );

      return {
        currentAutomation,
        handleSaveAutomation,
        isSaving,
        hasUnsavedChanges,
        handleAutomationUpdate,
        isLoading,
      };
    },
  };
</script>

<style scoped lang="scss">
  .automations-editor {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__heading {
      margin: 1rem 0;
    }
    &__heading-title {
      display: inline-flex;
    }
    &__view {
      flex-grow: 1;
      overflow: hidden;
    }
  }
</style>
