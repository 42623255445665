<template>
  <div class="preview-funnel">
    <div class="preview-section">
      <h3>Preview Your Form</h3>
      <div class="preview-controls">
        <select v-model="selectedPlacement" class="placement-select">
          <option value="inline">Inline Embed</option>
          <option value="modal">Modal Popup</option>
          <option value="sidebar">Sidebar Slide-in</option>
        </select>
      </div>

      <div class="preview-area">
        <div v-if="selectedPlacement === 'inline'" id="preview-container"></div>
        <Btn
          v-else-if="selectedPlacement === 'modal'"
          text="Open Modal Form"
          id="preview-modal-button"
          variant="secondary"
        />
        <Btn
          v-else-if="selectedPlacement === 'sidebar'"
          text="Toggle Sidebar Form"
          id="preview-sidebar-button"
          variant="secondary"
        />
      </div>

      <div class="embed-code">
        <div class="embed-header">
          <h4>Embed Code</h4>

          <Btn
            @click="copyEmbedCode"
            text="Copy Code"
            :class="{ copied: isCopied }"
            variant="secondary"
          />
        </div>
        <pre>{{ embedCode }}</pre>
      </div>

      <!-- Analytics Preview -->
      <!-- <div class="analytics-preview" v-if="analytics.length">
        <h4>Recent Form Views</h4>
        <ul>
          <li v-for="view in analytics" :key="view.id">
            {{ view.timestamp }} - {{ view.referrer }}
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
  import Btn from '@/components/partials/Btn.vue';

  // Import the widget directly in development
  let FunnelWidget;
  let widgetInstance = null;

  export default {
    name: 'PublishFunnelForm',

    components: {
      Btn,
    },

    props: {
      formId: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      // State
      const selectedPlacement = ref('inline');
      const isCopied = ref(false);

      const widgetUrl = computed(() => {
        return import.meta.env.VITE_NODE_ENV === 'development'
          ? 'src/widget/widget-entry.js' // Local path for development
          : 'https://pub-5932d75611fd44a6affc545150053db2.r2.dev/widget/funnel-widget.latest.js'; // CDN path for production
      });

      // const orgApiKey = organization.value.apiKey;
      const orgApiKey = 'test';

      const embedCode = computed(() => {
        // if (!organization.value) return '';

        const widgetScript = `<script src="https://pub-5932d75611fd44a6affc545150053db2.r2.dev/widget/funnel-widget.latest.js"><\/script>`;

        switch (selectedPlacement.value) {
          case 'inline':
            return `${widgetScript}
  <div id="funnel-container"></div>
  <script>
      FunnelWidget.init({
      target: "#funnel-container",
      formId: "${props.formId}",
      mode: "inline",
      options: {
          tracking: true
      }
      });
  <\/script>`;

          case 'modal':
            return `${widgetScript}
  <button id="openFunnel">Open Form</button>
  <script>
      FunnelWidget.init({
      formId: "${props.formId}",
      mode: "modal",
      options: {
          triggerElement: "#openFunnel",
          tracking: true
      }
      });
  <\/script>`;

          case 'sidebar':
            return `${widgetScript}
<script>
FunnelWidget.init({
formId: "${props.formId}",
mode: "sidebar",
options: {
    triggerElement: "#openFunnel",
    tracking: true
}
});
<\/script>`;
        }
      });

      // Methods
      const initPreview = async () => {
        if (!FunnelWidget) {
          console.warn('FunnelWidget not yet loaded');
          return;
        }

        if (widgetInstance) {
          widgetInstance.destroy();
        }

        // Wait for next tick to ensure DOM is updated
        await nextTick();

        const config = {
          formId: props.formId,
          mode: selectedPlacement.value,
          orgApiKey: orgApiKey,
          options: {
            tracking: true,
          },
        };

        // Configure based on placement type
        switch (selectedPlacement.value) {
          case 'inline':
            config.target = '#preview-container';
            break;
          case 'modal':
            config.options.triggerElement = '#preview-modal-button';
            break;
          case 'sidebar':
            config.options.triggerElement = '#preview-sidebar-button';
            break;
        }

        widgetInstance = FunnelWidget.init(config);
      };

      const copyEmbedCode = () => {
        navigator.clipboard.writeText(embedCode.value);
        isCopied.value = true;
        setTimeout(() => {
          isCopied.value = false;
        }, 2000);
      };

      if (import.meta.env.VITE_NODE_ENV === 'development') {
        import('@widget/widget-entry.js')
          .then((module) => {
            FunnelWidget = module.default;
            initPreview();
          })
          .catch((error) => {
            console.error('Error loading FunnelWidget:', error);
          });
      }

      // Lifecycle hooks
      onMounted(() => {
        if (FunnelWidget) {
          initPreview();
        }
      });

      onUnmounted(() => {
        if (widgetInstance) {
          widgetInstance.destroy();
        }
      });

      // Watchers
      watch(selectedPlacement, initPreview);

      return {
        selectedPlacement,
        embedCode,
        isCopied,
        copyEmbedCode,
      };
    },
  };
</script>

<style scoped lang="scss">
  .preview-funnel {
    .preview-section {
      border: 1px solid #dee2e6;
      border-radius: 8px;
      padding: 1.5rem;

      .preview-controls {
        margin-bottom: 1.5rem;

        .placement-select {
          padding: 0.5rem;
          border-radius: 4px;
          border: 1px solid #ced4da;
        }
      }

      .preview-area {
        min-height: 80vh;
        border: 1px dashed #ced4da;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
        border-radius: 4px;
        // display: flex;
        width: 100%;

        #preview-container {
          height: 80vh;
          width: 100%;
        }

        .preview-button {
          padding: 0.5rem 1rem;
          background: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background: #0056b3;
          }
        }
      }

      .embed-code {
        background: #f8f9fa;
        padding: 1rem;
        border-radius: 4px;

        .embed-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }

        .copy-button {
          padding: 0.5rem 1rem;
          background: #28a745;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;

          &.copied {
            background: #218838;
          }

          &:hover {
            background: #218838;
          }
        }

        pre {
          background: #fff;
          padding: 1rem;
          border-radius: 4px;
          overflow-x: auto;
          code {
            white-space: pre-wrap;
          }
        }
      }
    }

    .analytics-preview {
      margin-top: 1.5rem;
      padding: 1rem;
      background: #f8f9fa;
      border-radius: 4px;

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 0.5rem 0;
          border-bottom: 1px solid #dee2e6;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
</style>
