<template>
  <div class="crm-view">
    <div class="container container--fluid">
      <PageHeader
        title="CRM"
        description="View and manage all your leads in a table or pipeline view"
      />
      <div class="crm-view__controls" v-if="leads && leads.length > 0">
        <Tabs v-model="viewMode">
          <!-- <Tab name="table" title="Table View">
            <TableView :leads="leads" :loading="loading" @row-click="openLeadModal" :form="form" />
          </Tab> -->
          <Tab name="kanban" title="Pipeline View">
            <KanbanView
              :leads="groupedLeads"
              :statuses="kanbanStatuses"
              @card-click="openLeadModal"
              @status-change="handleStatusChange"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import { useCrmStore } from '@/store/crmStore';
  import { useUIStore } from '@/store/uiStore';
  import { useToastStore } from '@/store/toastStore';

  // import SubmissionDetails from '@/components/crm/submissionDetails.vue';
  import LeadDetails from '@/components/crm/LeadDetails.vue';
  import Tabs from '@/components/partials/Tabs/Tabs.vue';
  import Tab from '@/components/partials/Tabs/Tab.vue';
  import TableView from '@/components/crm/TableView.vue';
  import KanbanView from '@/components/crm/KanbanView.vue';
  import PageHeader from '@/components/partials/PageHeader.vue';

  export default {
    components: {
      Tabs,
      Tab,
      TableView,
      KanbanView,
      PageHeader,
    },
    setup() {
      const crmStore = useCrmStore();
      const uiStore = useUIStore();
      const toastStore = useToastStore();

      const viewMode = ref('kanban');
      const loading = computed(() => crmStore.loading);

      const leads = computed(() => crmStore.leads);
      const pagination = computed(() => crmStore.pagination);

      const kanbanStatuses = [
        { value: 'new', label: 'New' },
        { value: 'contacted', label: 'Contacted' },
        { value: 'introduction', label: 'Introduction' },
        { value: 'qualified', label: 'Qualified' },
        { value: 'converted', label: 'Converted' },
        { value: 'lost', label: 'Lost' },
      ];

      // Update groupedLeads computed to use the store's leads
      const groupedLeads = computed(() => {
        const grouped = {};
        kanbanStatuses.forEach((status) => {
          grouped[status.value] =
            status.value === 'new'
              ? leads.value.filter((lead) => !lead.status || lead.status === 'new')
              : leads.value.filter((lead) => lead.status === status.value);
        });
        return grouped;
      });

      const handleStatusChange = async (lead, newStatus, originalStatus) => {
        const leadToUpdate = leads.value.find((l) => l.id === lead.id);
        if (leadToUpdate) {
          leadToUpdate.status = newStatus;
        }

        try {
          await crmStore.updateLead(lead.id, { status: newStatus });
          toastStore.addToast({
            message: 'Updated lead status',
            type: 'success',
            duration: 3000,
          });
        } catch (error) {
          console.error('Failed to update status:', error);
          if (leadToUpdate) {
            leadToUpdate.status = originalStatus;
          }
        }
      };

      const openLeadModal = (lead) => {
        uiStore.addModal(LeadDetails, {
          title: 'Lead Details',
          leadId: lead.id,
          size: 'xxl',
        });
      };

      const changePage = async (page) => {
        await crmStore.fetchLeads({
          page,
          limit: pagination.value.limit,
        });
      };

      onMounted(() =>
        crmStore.fetchLeads({
          page: 1,
          limit: 20,
        })
      );

      return {
        viewMode,
        leads,
        loading,
        kanbanStatuses,
        groupedLeads,
        handleStatusChange,
        openLeadModal,
        pagination,
        changePage,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/_mixins.scss';
  .submission-view {
    &__controls {
      margin-bottom: 20px;
    }
    &__chart {
      min-height: 25vh;
      margin-bottom: 2rem;
      @include card('sm');
    }
  }
</style>
