<template>
  <div class="automation-card" @click="$emit('open-modal', automation)">
    <div class="automation-card__content">
      <div class="automation-card__icon">⚡</div>
      <div class="automation-card__info">
        <h3 class="automation-card__title">{{ automation.name }}</h3>
        <div class="automation-card__details">
          <div class="trigger">
            <span>{{ getTriggerLabel }}</span>
          </div>
          <div class="steps">
            {{ getStepsLabel }}
          </div>
        </div>
      </div>
      <div
        class="automation-card__status"
        :class="{ 'automation-card__status--is-active': automation.isActive }"
      >
        {{ automation.isActive ? 'Active' : 'Disabled' }}
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';

  export default {
    name: 'AutomationCard',

    props: {
      automation: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const getTriggerLabel = computed(() => {
        const triggerType = props.automation.trigger_type;
        switch (triggerType) {
          case 'form_submission':
            return 'Form Submission';
          case 'status_change':
            return 'Status Change';
          case 'scheduled':
            return 'Scheduled';
          default:
            return 'Unknown Trigger';
        }
      });

      const getStepsLabel = computed(() => {
        const count = props.automation.steps.length;
        return `${count} step${count !== 1 ? 's' : ''}`;
      });

      return {
        getTriggerLabel,
        getStepsLabel,
      };
    },
  };
</script>

<style scoped lang="scss">
  .automation-card {
    background: white;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__icon {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid var(--slate-200);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 32px;
        height: 32px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__status {
      font-size: 14px;
      padding: 6px 10px;
      border-radius: 2rem;
      color: var(--slate-700);
      background-color: var(--slate-200);
      &--is-active {
        color: var(--green-500);
        background-color: var(--green-200);
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }

    &__info {
      flex: 1;
    }

    &__title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    &__description {
      font-size: 14px;
      color: var(--color-text-secondary);
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__status {
      font-size: 14px;
      color: var(--color-text-secondary);
    }
  }
</style>
