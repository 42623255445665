import { useUserStore } from '@/store/userStore';
import { useUIStore } from '@/store/uiStore';
import { supabase } from '@/services/supabase';

/**
 * Authentication middleware for Vue Router
 * @param {import('vue-router').RouteLocationNormalized} to - Target route
 * @param {import('vue-router').RouteLocationNormalized} from - Current route
 * @param {import('vue-router').NavigationGuardNext} next - Navigation guard function
 */
export async function checkAuth(to, from, next) {
  const userStore = useUserStore();
  const uiStore = useUIStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Get the most permissive parent roles (if any)
  const parentRoles = to.matched[0]?.meta?.roles || [];

  // Get the most specific child roles (if any)
  const childRoles = to.matched.slice(-1)[0]?.meta?.roles;

  // If child specifies roles, use those (more restrictive)
  // Otherwise, fall back to parent roles
  const requiredRoles = childRoles || parentRoles;

  // Allow access to non-auth routes
  if (!requiresAuth) {
    next();
    return;
  }

  // Initialize user store if needed
  try {
    if (!userStore.user) {
      await userStore.init();
    }
  } catch (error) {
    console.error('❌ Error in user store initialization:', error);
    next('/login');
    return;
  }

  // Check if user is authenticated
  // try {
  //   console.log('🔄 About to check session');
  //   console.log('🔍 Supabase client status:', !!supabase);

  //   // Add timeout to the promise
  //   const timeoutPromise = new Promise((_, reject) => {
  //     setTimeout(() => reject(new Error('Session check timed out')), 5000);
  //   });

  //   // Then try the session check
  //   const sessionResponse = await Promise.race([supabase.auth.getSession(), timeoutPromise]);
  //   console.log('📍 Raw session response:', sessionResponse);

  //   const {
  //     data: { session },
  //   } = sessionResponse;

  //   console.log('📍 Session status:', !!session);
  //   if (!session) {
  //     console.log('⛔ No active session found, redirecting to login...');
  //     next('/login');
  //     return;
  //   }
  //   console.log('✅ Valid session found');
  // } catch (error) {
  //   console.error('❌ Error checking session:', error);
  //   console.error('Error details:', {
  //     name: error.name,
  //     message: error.message,
  //     stack: error.stack,
  //   });
  //   next('/login');
  //   return;
  // }
  // console.log('✅ Valid session found');

  // Check role permissions
  if (requiredRoles.length > 0) {
    if (!userStore.currentOrganization) {
      try {
        await userStore.fetchUserData();
      } catch (error) {
        console.error('❌ Failed to initialize organization data:', error);
        next('/login');
        return;
      }
    }
    const userRole = userStore.currentOrganization?.role || 'member';
    if (!requiredRoles.includes(userRole)) {
      console.log('⛔ User role not authorized for this route');
      next('/unauthorized');
      return;
    }
  }

  // Check email verification
  // if (!currentUser.emailVerified) {
  //   // Allow users less than 1 day old to proceed without verification
  //   const accountAge = Date.now() - currentUser.metadata.creationTime;
  //   const oneDayInMs = 24 * 60 * 60 * 1000;
  //   if (accountAge > oneDayInMs) {
  //     const modalId = uiStore.addModal(null, {
  //       title: 'Email Verification Required',
  //       description: 'Please verify your email address to continue using the application.',
  //       submitText: 'Resend verification email',
  //       onSubmit: async () => {
  //         try {
  //           uiStore.closeModal(modalId);
  //           const sentModalId = uiStore.addModal(null, {
  //             title: 'Verification Email Sent',
  //             description: 'Please check your inbox for the verification link.',
  //           });
  //           await sendEmailVerification(currentUser);
  //         } catch (error) {
  //           uiStore.addModal(null, {
  //             title: 'Error',
  //             description: error.message,
  //           });
  //         }
  //       },
  //       onClose: async () => {
  //         await auth.signOut();
  //         await userStore.logout();
  //         next('/login');
  //       },
  //     });
  //   }
  //   next();
  //   return;
  // }

  // Ensure user data is loaded
  if (!userStore.userData) {
    try {
      await userStore.fetchUserData(session.user.id);
    } catch (error) {
      console.error('❌ Failed to fetch user data:', error);
      next('/login');
      return;
    }
  }

  next();
}
