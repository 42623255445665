<template>
  <div class="lead-proposals">
    <LoadingSpinner v-if="loading" />

    <EmptyState
      v-else-if="proposals.length === 0"
      title="No proposals yet"
      description="Get started by creating your first proposal."
      icon="duotone-folders"
    >
      <Btn text="Create new proposal" icon="plus" variant="secondary" @click="addProposal" />
    </EmptyState>
    <div v-else>
      <DataTable
        title="Proposals"
        :headers="headers"
        :items="formattedProposals"
        :loading="loading"
        @row-click="handleRowClick"
      >
        <template #item-status="{ status }">
          <div class="proposal-status" :class="{ ['proposal-status--' + status]: status }">
            {{ status }}
          </div>
        </template>
        <template #item-actions="{ item }">
          <div class="actions" @click.stop>
            <SplitBtn
              v-if="item.status === 'draft'"
              variant="tertiary"
              :menu-items="[
                { label: 'Edit', value: 'edit' },
                { label: 'Duplicate', value: 'duplicate', disabled: true },
                { label: 'Send', value: 'send' },
                { label: 'Delete', value: 'delete', disabled: true },
              ]"
              @click="handleAction('edit', item)"
              @menu-item-click="(value) => handleAction(value, item)"
            >
              Edit
            </SplitBtn>
            <SplitBtn
              v-else
              variant="tertiary"
              :menu-items="[
                { label: 'View', value: 'view' },
                { label: 'Resend', value: 'resend' },
                { label: 'Duplicate', value: 'duplicate', disabled: true },
              ]"
              @click="handleAction('view', item)"
              @menu-item-click="(value) => handleAction(value, item)"
            >
              View
            </SplitBtn>
          </div>
        </template>
        <template #table-actions>
          <Btn icon="plus" @click="addProposal" variant="secondary">Add proposal</Btn>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref, computed, watch } from 'vue';
  import { crmService } from '@/services/crmService';
  import { useToastStore } from '@/store/toastStore';
  import { useUIStore } from '@/store/uiStore';

  import { formatDate } from '@/utils/date';

  import CreateEditProposal from '@/components/crm/CreateEditProposal.vue';
  import SendProposal from '@/components/crm/SendProposal.vue';

  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import EmptyState from '@/components/partials/EmptyState.vue';
  import Btn from '@/components/partials/Btn.vue';
  import SplitBtn from '@/components/partials/SplitBtn.vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import DataTable from '@/components/tables/DataTable.vue';
  export default defineComponent({
    name: 'LeadProposals',

    components: {
      LoadingSpinner,
      EmptyState,
      Btn,
      PageHeader,
      DataTable,
      SplitBtn,
    },

    props: {
      leadId: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      const loading = ref(true);
      const toastStore = useToastStore();
      const proposals = ref([]);
      const pagination = ref({});

      const headers = [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'status' },
        { text: 'Total amount', value: 'amount' },
        { text: 'Created at', value: 'createdAt' },

        { text: 'Valid until', value: 'validUntil' },
        { text: 'Actions', value: 'actions' },
      ];

      const formattedProposals = computed(() => {
        return proposals.value.map((proposal) => ({
          id: proposal.id,
          createdAt: formatDate(proposal.created_at),
          title: proposal.title,
          status: proposal.status,
          amount: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: proposal.currency,
          }).format(proposal.total_amount),
          validUntil: formatDate(proposal.valid_until),
        }));
      });

      const handleRowClick = (row) => {
        if (row.status === 'draft') {
          editProposal(row);
        } else {
          viewProposal(row);
        }
      };

      const handleAction = (action, item) => {
        if (action === 'edit') {
          editProposal(item);
        } else if (action === 'duplicate') {
          duplicateProposal(item);
        } else if (action === 'delete') {
          deleteProposal(item);
        } else if (action === 'send' || action === 'view' || action === 'resend') {
          viewProposal(item);
        }
      };

      const uiStore = useUIStore();

      const addProposal = () => {
        const modalId = uiStore.addModal(CreateEditProposal, {
          title: 'Create new proposal',
          description: 'Create a new proposal for this lead.',
          size: 'lg',
          hasComponent: true,
          leadId: props.leadId,
          onSubmit: async (data) => {
            uiStore.closeModal(modalId);
          },
          onAdd: async (data) => {
            proposals.value.push(data);
          },
          onEdit: async (data) => {
            const index = proposals.value.findIndex((p) => p.id === data.id);
            if (index !== -1) {
              proposals.value[index] = data;
            }
          },
          onClose: async (data) => {
            const index = proposals.value.findIndex((p) => p.id === data.id);
            if (index !== -1) {
              proposals.value[index] = data;
            }
            uiStore.closeModal(modalId);
          },
        });
      };

      const editProposal = (proposal) => {
        const modalId = uiStore.addModal(CreateEditProposal, {
          title: 'Edit proposal',
          description: 'Edit this proposal.',
          size: 'lg',
          hasComponent: true,
          leadId: props.leadId,
          proposalId: proposal.id,
          onSubmit: async (data) => {
            uiStore.closeModal(modalId);
          },
          onEdit: async (data) => {
            const index = proposals.value.findIndex((p) => p.id === data.id);
            if (index !== -1) {
              proposals.value[index] = data;
            }
          },
          onClose: async (data) => {
            const index = proposals.value.findIndex((p) => p.id === data.id);
            if (index !== -1) {
              proposals.value[index] = data;
            }
            uiStore.closeModal(modalId);
          },
        });
      };

      const viewProposal = (proposal) => {
        const modalId = uiStore.addModal(SendProposal, {
          title: 'View proposal',
          description: 'View this proposal.',
          size: 'xxl',
          proposalId: proposal.id,
          onSend: (sentEmail) => {
            uiStore.closeModal(modalId);
          },
        });
      };

      const deleteProposal = async (proposal) => {
        const modalId = uiStore.addModal(null, {
          title: 'Delete proposal',
          description:
            'Are you sure you want to delete this proposal? This action cannot be undone.',
          status: 'danger',
          onSubmit: async () => {
            await crmService.deleteProposal(props.leadId, proposal.id);
            proposals.value = proposals.value.filter((p) => p.id !== proposal.id);
            uiStore.closeModal(modalId);
            toastStore.addToast({
              message: 'Proposal deleted',
              type: 'success',
              duration: 3000,
            });
          },
        });
      };

      onMounted(async () => {
        try {
          const response = await crmService.getProposals(props.leadId);
          proposals.value = response.proposals;
          pagination.value = response.pagination;
          loading.value = false;
        } catch (error) {
          console.error('Failed to fetch proposals:', error);
        }
      });

      return {
        formatDate,
        loading,
        proposals,
        pagination,
        addProposal,
        headers,
        formattedProposals,
        handleRowClick,
        handleAction,
      };
    },
  });
</script>

<style scoped lang="scss">
  .lead-proposals {
    .proposal-status {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 0.5rem;
      background-color: var(--slate-200);
      color: var(--slate-900);
      border: 1px solid var(--slate-300);
      &--sent {
        background-color: var(--amber-100);
        color: var(--amber-600);
        border: 1px solid var(--amber-500);
      }
      &--viewed {
        background-color: var(--brand-100);
        color: var(--brand-600);
        border: 1px solid var(--brand-300);
      }
      &--accepted {
        background-color: var(--emerald-100);
        color: var(--emerald-600);
        border: 1px solid var(--emerald-300);
      }
    }
  }
</style>
