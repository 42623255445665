<template>
  <div class="task-config">
    <!-- Task Type Selection -->
    <SelectInput
      :id="'task-type-select'"
      label="Select task type"
      :options="availableTaskTypes"
      v-model="localConfig.taskType"
      @update:model-value="onTaskTypeChange"
    />

    <!-- Integration Task Configuration -->
    <div v-if="localConfig.taskType === 'fire_integration'" class="mt-4">
      <template v-if="availableIntegrations.length">
        <SelectInput
          :id="'integration-select'"
          label="Select Integration"
          :options="availableIntegrations.map((i) => ({ label: i.name, value: i.id }))"
          v-model="localConfig.integrationId"
          option-label="name"
          option-value="id"
          :hint="selectedIntegration?.type ? `Type: ${selectedIntegration.type}` : ''"
          @update:model-value="onIntegrationChange"
        />

        <!-- Add your integration-specific configurations here -->
        <div v-if="selectedIntegration" class="mt-4">
          <!-- You'll add specific integration configs here -->
        </div>
      </template>
      <template v-else>
        <div class="alert alert--info">
          No integrations found. First add your integrations at the
          <router-link :to="{ path: '/integrations' }">integrations page</router-link>
          .
        </div>
      </template>
    </div>

    <div v-else-if="localConfig.taskType === 'add_activity'" class="mt-4">
      <div class="alert alert--info">Activity tracking coming soon</div>
    </div>

    <div v-else-if="localConfig.taskType === 'celebrate'" class="mt-4">
      <TextInput
        id="celebration-message"
        label="Celebration Message"
        v-model="localConfig.celebrationMessage"
        placeholder="Time to celebrate!"
      />
    </div>

    <div v-else-if="localConfig.taskType === 'proposal'" class="mt-4">
      <ProposalAutomationConfig v-model="localConfig.proposalConfig" />
      <!-- <ProposalRulesConfig v-model="localConfig.proposalConfig" /> -->
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useIntegrationStore } from '@/store/integrationStore';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { INTEGRATION_TYPES } from '@/constants/integrationTypes';
  import ProposalRulesConfig from './ProposalRulesConfig.vue';
  import ProposalAutomationConfig from './ProposalAutomationConfig.vue';

  export default {
    name: 'TaskConfig',

    components: {
      SelectInput,
      TextInput,
      ProposalRulesConfig,
      ProposalAutomationConfig,
    },

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
    },

    setup(props, { emit }) {
      const integrationStore = useIntegrationStore();

      // Available task types
      const availableTaskTypes = [
        { label: 'Fire Integration', value: 'fire_integration' },
        { label: 'Add Activity to Lead (coming soon)', value: 'add_activity', disabled: true },
        { label: 'Celebrate', value: 'celebrate' },
        { label: 'Create proposal', value: 'proposal' },
      ];

      // Initialize local config
      const localConfig = ref({
        taskType: props.modelValue.taskType || 'fire_integration',
        integrationId: props.modelValue.integrationId || null,
        celebrationMessage: props.modelValue.celebrationMessage || '',
        proposalConfig: props.modelValue.proposalConfig || {
          title: '',
          days_valid_amount: 30,
          client_notes: '',
          internal_notes: '',
          items: [],
        },
      });

      const availableIntegrations = ref([]);

      // Get selected integration details
      const selectedIntegration = computed(() => {
        return availableIntegrations.value.find(
          (integration) => integration.id === localConfig.value.integrationId
        );
      });

      // Event handlers
      const onTaskTypeChange = () => {
        // Reset task-specific configurations
        localConfig.value.integrationId = null;
        localConfig.value.celebrationMessage = '';
        emitUpdate();
      };

      const onIntegrationChange = () => {
        // Reset integration-specific configurations
        emitUpdate();
      };

      const emitUpdate = () => {
        emit('update:config', { ...localConfig.value });
      };

      // Watch for changes
      watch(
        localConfig.value,
        (newVal) => {
          emit('update:config', { ...newVal });
        },
        { deep: true }
      );

      // Fetch available integrations on mount
      onMounted(async () => {
        try {
          const integrations = integrationStore.integrations;
          // Filter for active integrations that can be used in automations
          availableIntegrations.value = integrations.filter(
            (integration) =>
              integration.isActive && INTEGRATION_TYPES[integration.type]?.canUseAutomation
          );
        } catch (error) {
          console.error('Failed to fetch integrations:', error);
        }
      });

      return {
        localConfig,
        availableTaskTypes,
        availableIntegrations,
        selectedIntegration,
        onTaskTypeChange,
        onIntegrationChange,
      };
    },
  };
</script>

<style scoped lang="scss">
  .task-config {
    .alert {
      font-style: italic;
      font-size: 0.875rem;
      a {
        text-decoration: underline;
      }
    }
  }
</style>
