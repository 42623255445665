import axios from 'axios';
import { supabase } from '@/services/supabase';

// Set the default baseURL
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

//Set up interceptor
axios.interceptors.request.use(
  async (config) => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session?.access_token) {
      config.headers.Authorization = `Bearer ${session.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
