<template>
  <div class="elements-sidebar">
    <div class="elements-sidebar__inner">
      <h3>Add form element</h3>
      <div class="elements-category" v-for="category in categorizedElements" :key="category.slug">
        <h4>{{ category.label }}</h4>
        <ul class="elements-grid">
          <li
            v-for="element in category.elements"
            :key="element.slug"
            class="elements-choice"
            :class="{
              'elements-choice--coming-soon': element.comingSoon,
              ['elements-choice--category-' + category.slug]: category.slug,
            }"
            @click="element.comingSoon ? null : addElement(element)"
            v-tippy="{ content: element.comingSoon ? 'Coming soon' : '' }"
          >
            <div class="elements-choice__icon">
              <BaseIcon :name="`elements_${element.slug}`" size="16" />
            </div>
            <div class="elements-choice__label">{{ element.label }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { uid } from 'uid';
  import { ELEMENTS } from '@/constants/elements';

  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    props: {},
    components: {
      BaseIcon,
      Btn,
    },
    emits: ['save-settings', 'edit-element-settings'],
    setup(props) {
      const formStore = useFormStore();
      const availableElements = ref(ELEMENTS);

      const categories = [
        { label: 'Text', slug: 'text' },
        { label: 'Numbers', slug: 'numbers' },
        { label: 'Choices', slug: 'choices' },
        { label: 'Options', slug: 'options' },
        { label: 'Date & Time', slug: 'date' },
        { label: 'Special', slug: 'special' },
        { label: 'Contact Data', slug: 'contact' },
        { label: 'Content', slug: 'content' },
      ];

      const categorizedElements = computed(() => {
        return categories.map((category) => ({
          ...category,
          elements: availableElements.value.filter(
            (elements) => elements.category === category.slug
          ),
        }));
      });

      const addElement = (element) => {
        const newElement = {
          ...element,
          id: uid(),
          props: {
            label: 'New element',
            ...(element.category === 'choices'
              ? {
                  choices: [
                    {
                      id: uid(),
                      content: 'Choice 1',
                    },
                  ],
                }
              : {}),
          },
        };
        formStore.addElement(newElement);
      };

      return {
        availableElements,
        categorizedElements,
        categories,
        addElement,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .elements-sidebar {
    .elements-category {
      margin: 2rem 0;
      h4 {
        margin-bottom: 1rem;
      }
      .elements-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 4px;
        list-style: none;
        margin: 0;
        list-style: none;
        .elements-choice {
          display: flex;
          flex-direction: row;
          padding: 0.5rem;
          border: 1px solid var(--slate-100);
          background-color: var(--slate-50);
          border-radius: 0.5rem;
          text-align: center;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;
          @include fontSize('sm');
          @include hover {
            border-color: var(--slate-300);
          }
          &__icon {
            width: 100%;
            aspect-ratio: 1/1;
            max-width: 2rem;
            max-height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            color: var(--brand-600);
            border: 1px solid var(--slate-200);
          }
          &__label {
            word-break: break-word;
            @include fontSize('sm');
          }
          &--coming-soon {
            // pointer-events: none;
            cursor: default;
            border-color: var(--slate-100);
            color: var(--slate-500);
            .elements-choice__icon {
              opacity: 0.45;
            }
            &:hover {
              border-color: var(--slate-100);
              cursor: default;
            }
          }
          &--category- {
            &text {
              .elements-choice__icon {
                color: map-get($text-controls-color, icon-color);
                background-color: map-get($text-controls-color, background-color);
                border-color: map-get($text-controls-color, border-color);
              }
            }
            &numbers {
              .elements-choice__icon {
                color: map-get($numbers-color, icon-color);
                background-color: map-get($numbers-color, background-color);
                border-color: map-get($numbers-color, border-color);
              }
            }
            &choices {
              .elements-choice__icon {
                color: map-get($choices-color, icon-color);
                background-color: map-get($choices-color, background-color);
                border-color: map-get($choices-color, border-color);
              }
            }
            &options {
              .elements-choice__icon {
                color: map-get($options-color, icon-color);
                background-color: map-get($options-color, background-color);
                border-color: map-get($options-color, border-color);
              }
            }
            &date {
              .elements-choice__icon {
                color: map-get($date-color, icon-color);
                background-color: map-get($date-color, background-color);
                border-color: map-get($date-color, border-color);
              }
            }
            &special {
              .elements-choice__icon {
                color: map-get($special-color, icon-color);
                background-color: map-get($special-color, background-color);
                border-color: map-get($special-color, border-color);
              }
            }
            &contact {
              .elements-choice__icon {
                color: map-get($contact-data-color, icon-color);
                background-color: map-get($contact-data-color, background-color);
                border-color: map-get($contact-data-color, border-color);
              }
            }
            &content {
              .elements-choice__icon {
                color: map-get($content-color, icon-color);
                background-color: map-get($content-color, background-color);
                border-color: map-get($content-color, border-color);
              }
            }
          }
        }
      }
    }
  }
</style>
