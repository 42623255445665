export const ACTION_TYPES = [
  { value: 'send_email', label: 'Send Email' },
  { value: 'create_task', label: 'Create Task' },
  { value: 'update_contact', label: 'Update Contact' },
  { value: 'create_deal', label: 'Create Deal' },
  { value: 'update_deal', label: 'Update Deal' },
  { value: 'add_tag', label: 'Add Tag' },
  { value: 'remove_tag', label: 'Remove Tag' },
  { value: 'webhook', label: 'Webhook' },
  // Add more action types as needed
];

export const TRIGGER_TYPES = [
  { value: 'form_submission', label: 'Form Submission' },
  { value: 'lead_status_change', label: 'Lead Status Change' },
  { value: 'scheduled', label: 'Scheduled' },
];
