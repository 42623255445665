<template>
  <div class="form-editor">
    <EmptyState
      v-if="!form.pages?.length"
      title="No pages yet"
      description="Get started by adding your first page in the sidebar on the left."
      icon="duotone-page"
    >
      <Btn text="Add page" icon="plus" variant="secondary" @click="$emit('add-page')" />
    </EmptyState>
    <div v-if="activePage" class="form-editor__wrap" :style="designStyles">
      <div class="funnel">
        <FormPage
          :page="activePage"
          :activeElementIndex="activeElementIndex"
          @set-active-element="setActiveElement"
          @remove-element="removeElement"
          @add-new-element="addNewElement"
        />
        <div class="funnel__buttons" @click.stop="openButtonSettings">
          <Btn v-if="!isFirstPage" variant="plain">Back</Btn>
          <Btn variant="primary">Next</Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, onBeforeUnmount } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { useDesignStore } from '@/store/designStore';
  import FormPage from '@/components/editor/FormPage.vue';
  import EmptyState from '@/components/partials/EmptyState.vue';
  import Btn from '@shared/components/Btn.vue';
  import { useDesignStyles } from '@shared/composables/useDesignStyles';

  export default {
    components: {
      FormPage,
      EmptyState,
      Btn,
    },
    props: {
      activePage: {
        type: Object,
      },
      activeElementIndex: {
        type: Number,
      },
    },
    emits: ['set-active-element', 'remove-element', 'add-page'],
    setup(props, { emit }) {
      const formStore = useFormStore();
      const designStore = useDesignStore();

      const form = computed(() => formStore.currentForm);
      console.log(form.value.pages);
      const designStyles = computed(() => {
        if (!form.value.design_id) return useDesignStyles(null).designStyles;
        const currentDesign = designStore.designs.find((d) => d.id === form.value.design_id);
        return useDesignStyles(currentDesign).designStyles;
      });

      const isFirstPage = computed(() => {
        return form.value.pages.findIndex((page) => page.id === props.activePage.id) === 0;
      });

      const setActiveElement = (elementIndex) => {
        emit('set-active-element', elementIndex);
      };
      const removeElement = (elementIndex) => {
        emit('remove-element', elementIndex);
      };
      const addNewElement = () => {
        // Set element index to null so that the sidebar will become visible
        emit('set-active-element', null);
      };
      // // Warn user before leaving if there are unsaved changes
      onBeforeUnmount(() => {
        if (formStore.isDirty) {
          return confirm('You have unsaved changes. Are you sure you want to leave?');
        }
      });

      return {
        setActiveElement,
        removeElement,
        addNewElement,
        form,
        designStyles,
        isFirstPage,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@shared/assets/css/form/_designStyles.scss';
  .form-editor {
    flex: 1;
    padding: 1rem;
    display: flex;
    justify-content: center;
    height: 100%;
    overflow-y: auto;
    &__wrap {
      width: 100%;
      min-height: 100%;
      border-radius: 1rem;
      background-color: var(--cs-background-primary);
      box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px -1px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      overflow-y: auto;
      border: 1px solid var(--slate-200);
    }
  }

  .form-editor__page {
    margin-bottom: 1rem;
  }
  .funnel {
    padding: 1.5rem 0.5rem;
    max-width: 720px;
    margin: 0 auto;
    &__buttons {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      &:has(> :nth-child(2)) {
        justify-content: space-between;
      }
      :deep(.btn) {
        background-color: var(--cs-button-default-background);
      }
    }
  }
</style>
