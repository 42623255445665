<template>
  <div class="integrations-view">
    <div class="container">
      <PageHeader
        title="Integrations"
        description="Connect your favorite tools and eventually integrate them in your funnels"
      >
        <template #actions>
          <Btn variant="primary" @click="handleAddIntegration">Add Integration</Btn>
        </template>
      </PageHeader>
      <!-- <div class="integrations-view__search">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search integrations..."
        class="search-input"
      />
    </div> -->
      <EmptyState
        v-if="filteredIntegrations.length === 0"
        title="No integrations found"
        description="Add an integration to get started"
        icon="duotone-cubes"
      />
      <div class="integrations-view__list">
        <IntegrationCard
          v-for="integration in filteredIntegrations"
          :key="integration.id"
          :integration="integration"
          @open-modal="openSettingsModal(integration)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import IntegrationCard from '@/components/integrations/IntegrationCard.vue';
  import IntegrationSetupModal from '@/components/integrations/IntegrationSetupModal.vue';
  import SelectIntegrationModal from '@/components/integrations/SelectIntegrationModal.vue';
  import Btn from '@/components/partials/Btn.vue';
  import EmptyState from '@/components/partials/EmptyState.vue';
  import { useIntegrationStore } from '@/store/integrationStore';
  import { useUIStore } from '@/store/uiStore';

  export default {
    name: 'SettingsIntegrationsView',

    components: {
      PageHeader,
      IntegrationCard,
      IntegrationSetupModal,
      Btn,
      EmptyState,
    },

    setup() {
      const searchQuery = ref('');
      const integrationStore = useIntegrationStore();
      const integrations = computed(() => integrationStore.integrations);
      const filteredIntegrations = computed(() => {
        // First filter by search query
        let filtered = integrations.value;
        if (searchQuery.value.trim()) {
          const query = searchQuery.value.toLowerCase();
          filtered = filtered.filter((integration) =>
            integration.name.toLowerCase().includes(query)
          );
        }

        // Sort active integrations first, then by name
        return [...filtered].sort((a, b) => {
          // Put active integrations first
          if (a.is_active && !b.is_active) return -1;
          if (!a.is_active && b.is_active) return 1;
          // Then sort by name
          return a.name.localeCompare(b.name);
        });
      });

      const integrationOptions = Object.entries(integrationStore.integrationTypes)
        // .filter(([_, type]) => type.requiresAutomation) // Only show automatable integrations in this list
        .map(([id, type]) => ({
          id,
          name: type.name,
          type: id,
        }));

      // Fetch integrations
      const fetchIntegrations = async () => {
        try {
          const data = await integrationStore.fetchIntegrations();
          integrations.value = data;
        } catch (error) {
          console.error('Failed to fetch integrations:', error);
        }
      };
      const uiStore = useUIStore();
      // Handle adding new integration
      const handleAddIntegration = () => {
        const selectModalId = uiStore.addModal(SelectIntegrationModal, {
          title: 'Add integration',
          description: 'Select which integration you would like to add',
          options: integrationOptions,
          onSubmit: async (selectedType) => {
            uiStore.closeModal(selectModalId);

            const setupModalId = uiStore.addModal(IntegrationSetupModal, {
              title: `Setup ${selectedType.name}`,
              description: `Configure your ${selectedType.name} integration settings`,
              integration: {
                type: selectedType.type,
                name: selectedType.name,
                is_active: true,
                settings: {}, // Empty settings, modal will handle initialization
              },
              isEdit: false,
              onSubmit: async (formData) => {
                try {
                  // Split out formConnections from formData
                  const { formConnections, ...integrationData } = formData;

                  // Create integration with main data
                  const response = await integrationStore.createIntegration(
                    integrationData,
                    formConnections
                  );
                  console.log(response);

                  if (response.status === 201) {
                    uiStore.closeModal(setupModalId);
                  }
                } catch (error) {
                  console.error('Failed to create integration:', error);
                }
              },
            });
          },
        });
      };

      // Handle opening settings modal for existing integration
      const openSettingsModal = (integration) => {
        const setupModalId = uiStore.addModal(IntegrationSetupModal, {
          title: `Edit ${integration.name}`,
          description: `Configure your ${integration.name} integration settings`,
          integration: integration,
          isEdit: true,
          onSubmit: async (formData) => {
            try {
              const response = await integrationStore.updateIntegration(integration.id, formData);
              if (response.status === 200) {
                uiStore.closeModal(setupModalId);
              }
            } catch (error) {
              console.error('Failed to update integration:', error);
            }
          },
          onDeleteIntegration: async (integrationId) => {
            try {
              await integrationStore.deleteIntegration(integrationId);
              uiStore.closeModal(setupModalId);
            } catch (error) {
              console.error('Failed to delete integration:', error);
            }
          },
        });
      };

      // Load integrations on mount
      onMounted(() => {
        fetchIntegrations();
      });

      return {
        searchQuery,
        integrations,
        filteredIntegrations,
        handleAddIntegration,
        openSettingsModal,
      };
    },
  };
</script>

<style scoped lang="scss">
  .integrations-view {
    &__search {
      margin-bottom: 24px;

      .search-input {
        width: 100%;
        max-width: 400px;
        padding: 8px 16px;
        border: 1px solid var(--slate-300);
        border-radius: 6px;
        font-size: 14px;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      margin-top: 24px;
    }
  }
</style>
