<template>
  <transition name="toast-fade" @after-leave="afterLeave">
    <div v-if="isVisible" class="toast" :class="[`toast--${type}`]">
      <div class="toast__content">
        <BaseIcon :name="iconName" size="16" />
        <span>{{ message }}</span>
      </div>
      <BaseIcon @click="closeToast" name="xmark" size="16" class="toast__close" />
    </div>
  </transition>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';

  export default {
    name: 'Toast',
    components: { BaseIcon },
    props: {
      message: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'info',
      },
      show: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['after-leave', 'close'],
    setup(props, { emit }) {
      const isVisible = ref(props.show);

      const iconName = computed(() => {
        switch (props.type) {
          case 'success':
            return 'check-circle';
          case 'error':
            return 'exclamation-circle';
          case 'warning':
            return 'exclamation-triangle';
          default:
            return 'information-circle';
        }
      });

      watch(
        () => props.show,
        (newVal) => {
          isVisible.value = newVal;
        }
      );

      const closeToast = () => {
        emit('close');
      };

      const afterLeave = () => {
        emit('after-leave');
      };

      return {
        isVisible,
        closeToast,
        afterLeave,
        iconName,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0.25rem;
    background: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    margin-bottom: 0.5rem;

    &__content {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
    }

    &__close {
      cursor: pointer;
      color: inherit;
      &:hover {
        color: var(--slate-900);
      }
      margin-left: 0.5rem;
    }

    // Toast types
    &--success {
      background: var(--green-50);
      border: 1px solid var(--green-400);
      color: var(--green-600);
    }

    &--error {
      background: var(--red-50);
      border: 1px solid var(--red-500);
    }

    &--warning {
      background: var(--yellow-50);
      border: 1px solid var(--yellow-500);
    }

    &--info {
      background: var(--brand-50);
      border: 1px solid var(--brand-500);
      color: var(--brand-600);
    }
  }

  .toast-fade-enter-active,
  .toast-fade-leave-active {
    transition: all 0.3s ease;
  }

  .toast-fade-enter-from,
  .toast-fade-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
</style>
