<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">
      {{ label }}
      <span v-if="info" class="form-group__info" v-tippy="{ content: info }">
        <BaseIcon name="info" size="14" />
      </span>
    </label>
    <input
      :type="type"
      :id="id"
      :value="modelValue"
      @input="handleInput"
      ref="inputRef"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';

  export default {
    components: {
      BaseIcon,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      info: {
        type: String,
        default: null,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const handleInput = (event) => {
        emit('update:modelValue', event.target.value);
      };
      const handleBlur = () => {
        touched.value = true;
        // emit('blur');
      };
      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        handleInput,
        handleBlur,
        localError,
      };
    },
  };
</script>

<style scoped></style>
