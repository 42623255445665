export function validateInput(value, rules) {
  const errors = [];
  if (rules) {
    rules.forEach((rule) => {
      const [ruleName, ruleValue] = rule.split(':');
      switch (ruleName) {
        case 'required':
          if (
            !value ||
            (typeof value === 'string' &&
              // Check for empty string
              (!value.trim() ||
                // Check for empty HTML tags like <p></p>
                value.replace(/<[^>]*>/g, '').trim().length === 0)) ||
            (value instanceof HTMLElement && !value.innerHTML.trim())
          )
            errors.push('This field is required.');
          break;
        case 'maxLength':
          if (value.length > parseInt(ruleValue))
            errors.push(`Maximum length is ${ruleValue} characters.`);
          break;
        case 'minLength':
          if (value.length < parseInt(ruleValue))
            errors.push(`Minimum length is ${ruleValue} characters.`);
          break;
        case 'minItems':
          if (value.length < parseInt(ruleValue))
            errors.push(`Minimum number of items is ${ruleValue}.`);
          break;
        case 'email':
          // eslint-disable-next-line no-case-declarations
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(value)) errors.push('Invalid email address.');
          break;
        case 'min':
          if (Number(value) < parseInt(ruleValue))
            errors.push(`Value must be at least ${ruleValue}.`);
          break;
        case 'max':
          if (Number(value) > parseInt(ruleValue))
            errors.push(`Value must be no more than ${ruleValue}.`);
          break;
        case 'requiredCheckbox':
          if (!Array.isArray(value) || value.length === 0) {
            errors.push('At least one option must be selected.');
          }
          break;
        // Add more cases for different rules as needed.
        default:
          break;
      }
    });
  }
  return errors.length > 0 ? errors.join(' ') : '';
}

export function validateNestedInput(value, rules) {
  // Handle array validation
  if (Array.isArray(value) && rules._each) {
    const errors = [];

    // Validate array itself if _self rules exist
    if (rules._self) {
      const arrayError = validateInput(value, rules._self);
      if (arrayError) return arrayError;
    }

    // Validate each item
    value.forEach((item, index) => {
      const itemErrors = {};

      // Check each field in the item
      Object.entries(rules._each).forEach(([field, fieldRules]) => {
        const error = validateInput(item[field], fieldRules);
        if (error) itemErrors[field] = error;
      });

      if (Object.keys(itemErrors).length > 0) {
        errors[index] = itemErrors;
      }
    });

    return errors.length > 0 ? errors : null;
  }

  // Handle regular validation
  return validateInput(value, rules);
}
