<template>
  <div class="split-btn" :class="{ 'split-btn--has-items': menuItems.length > 0 }">
    <button
      class="btn split-btn__main"
      :class="{
        'btn--loading': loading,
        ['btn--variant-' + variant]: variant,
        'btn--icon-only': isIconOnly,
      }"
      @click="handleClick"
      :disabled="isMainBtnDisabled || loading"
    >
      <LoadingSpinner v-if="loading" color="light" />
      <div class="btn__content">
        <slot>{{ text }}</slot>
        <BaseIcon v-if="icon" :name="icon" size="12" />
      </div>
    </button>
    <button
      class="btn split-btn__dropdown"
      :class="['btn--variant-' + variant]"
      @click="toggleDropdown"
      :disabled="isDisabled || loading"
      v-if="menuItems.length > 0"
    >
      <BaseIcon name="chevron" size="12" rotate="180" />
    </button>

    <div v-if="isOpen" class="split-btn__menu">
      <slot name="menu">
        <div v-for="(item, index) in menuItems" :key="index" :class="{ 'split-btn__menu-item--disabled': item.disabled }"
             class="split-btn__menu-item"
             @click="handleMenuItemClick(item.value)">
          {{ item.label }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
  import { computed, ref, onMounted } from 'vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  export default {
    name: 'ButtonEl',
    props: {
      text: {
        type: String,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      mainBtnDisabled: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
      },
      variant: {
        type: String,
        default: 'primary',
      },
      menuItems: {
        type: Array,
        default: () => [],
      }
    },
    components: {
      LoadingSpinner,
      BaseIcon,
    },
    emits: ['click', 'menu-item-click'],
    setup(props, { emit, slots }) {
      const isOpen = ref(false);
      const isDisabled = computed(() => (props.loading || props.disabled ? true : false));
      const isMainBtnDisabled = computed(() => (props.mainBtnDisabled ? true : false));
      const hasSlot = computed(() => !!slots.default);
      const isIconOnly = computed(() => !props.text && !hasSlot && !!props.icon);
      const handleClick = (event) => {
        emit('click', event);
      };
      const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
      };

      const handleMenuItemClick = (item) => {
        emit('menu-item-click', item);
        isOpen.value = false;
      };

      // Close dropdown when clicking outside
      onMounted(() => {
        document.addEventListener('click', (event) => {
          const splitBtn = event.target.closest('.split-btn');
          if (!splitBtn) {
            isOpen.value = false;
          }
        });
      });
      return {
        isDisabled,
        isMainBtnDisabled,
        handleClick,
        isIconOnly,
        isOpen,
        toggleDropdown,
        handleMenuItemClick,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  
  .btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 6px;
    padding-bottom: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 500;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: var(--brand-500);
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    @include hover {
    }
    &__content {
      transition: padding 150ms ease-out;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .icon {
        margin-left: 0.5rem;
      }
    }
    &--loading {
      .btn__content {
        padding-left: 0.5rem;
      }
    }
    &--icon-only {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      .btn__content {
        .icon {
          margin: 0;
        }
      }
    }
    &:disabled {
      opacity: 0.8;
      pointer-events: none;
    }
    &--variant- {
      &primary {
        @include hover {
          background-color: var(--brand-600);
        }
      }
      &danger {
        background-color: var(--white);
        border-color: var(--slate-200);
        color: var(--red-500);
        @include hover {
          border-color: var(--red-500);
        }
      }
      &plain {
        background-color: transparent;
        color: var(--slate-500);
        box-shadow: none;
        @include hover {
          color: var(--slate-800);
        }
        padding-left: 0;
        padding-right: 0;
      }
      &secondary {
        background-color: var(--slate-800);
        color: var(--slate-100);
        box-shadow: none;
        @include hover {
          background-color: var(--slate-700);
        }
      }
      &tertiary {
        background-color: var(--slate-100);
        color: var(--slate-600);
        @include hover {
          color: var(--slate-800);
          background-color: var(--slate-200);
        }
      }
      &outlined {
        background-color: var(--white);
        color: var(--slate-600);
        border-color: var(--slate-300);
        box-shadow: none;
        @include hover {
          color: var(--slate-700);
          border-color: var(--slate-700);
        }
      }
      &success {
        background-color: var(--green-500);
        color: var(--slate-100);
      }
    }
  }

  .split-btn {
    position: relative;
    display: inline-flex;
    
    &__dropdown {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 6px 8px;
    }

    &__menu {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: 4px;
      min-width: 160px;
      background-color: var(--white);
      border: 1px solid var(--slate-200);
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 50;
    }

    &__menu-item {
      padding: 8px 16px;
      cursor: pointer;
      color: var(--slate-600);

      &:hover {
        background-color: var(--slate-50);
        color: var(--slate-800);
      }
      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &--has-items {
      .btn {
        &.split-btn__main {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
</style>
