<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">{{ label }}</label>
    <div class="file-upload">
      {{ modelValue }}
      <div v-if="previewUrl && isImage" class="file-upload__preview">
        <img :src="previewUrl" alt="Preview" class="image-preview" />
        <Btn @click="changeFile" icon="edit" variant="plain">Change</Btn>
        <Btn @click="removeFile" icon="trash" variant="plain">Remove</Btn>
      </div>

      <div v-else-if="previewUrl && !isImage" class="file-upload__file-info">
        <span>{{ fileName }}</span>
        <Btn @click="removeFile" icon="trash" variant="plain">Remove</Btn>
      </div>

      <div v-else class="file-upload__upload">
        <Btn
          @click="triggerFileInput"
          icon="upload"
          variant="outlined"
          text="Add custom font"
          :loading="isUploading"
        />
      </div>
      <input
        ref="fileInput"
        type="file"
        :id="id"
        @change="onFileChange"
        :accept="accept"
        style="display: none"
      />
    </div>
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import { validateInput } from '@/utils/validation';
  import FileService from '@/services/fileService';

  import axios from 'axios';
  import { useUserStore } from '@/store/userStore';
  import Btn from '@/components/partials/Btn.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  export default {
    components: {
      Btn,
      LoadingSpinner,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: String,
        default: 'font/*',
      },
      // previewUrl: {
      //   type: String,
      //   default: '',
      // },
      uploadConfig: {
        type: Object,
        default: () => ({
          type: 'default',
          context: 'default',
        }),
      },
    },
    emits: ['update:modelValue', 'validate', 'file-selected', 'upload-error', 'upload-success'],
    setup(props, { emit }) {
      const userStore = useUserStore();
      const localError = ref(props.error);
      const touched = ref(false);
      const fileName = ref('');
      const fileInput = ref(null);
      const previewUrl = ref(props.modelValue);
      // const previewUrl = ref(props.previewUrl);
      const isUploading = ref(false);
      watch(
        () => props.modelValue,
        (newValue) => {
          previewUrl.value = newValue;
        },
        { immediate: true }
      );

      // Determine if the uploaded file is an image
      const isImage = computed(() => {
        if (!previewUrl.value) return false;
        // If it's a blob URL, use the stored file type information
        if (previewUrl.value.startsWith('blob:')) {
          return fileName.value.match(/\.(jpeg|jpg|gif|png)$/i) !== null;
        }
        // For regular URLs, check the extension
        return previewUrl.value.match(/\.(jpeg|jpg|gif|png)$/i) !== null;
      });

      const onFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        touched.value = true;

        try {
          // Create preview URL for images
          if (file.type.startsWith('image/')) {
            previewUrl.value = URL.createObjectURL(file);
          }
          fileName.value = file.name;

          // Handle file upload
          isUploading.value = true;
          const response = await FileService.uploadFile(file, props.uploadConfig);
          console.log(response);
          if (response?.id) {
            emit('update:modelValue', response.id);
            emit('upload-success', response);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          emit('upload-error', error);
          removeFile();
        } finally {
          isUploading.value = false;
        }
      };

      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      const changeFile = () => {
        fileInput.value.click();
      };

      const removeFile = () => {
        if (previewUrl.value?.startsWith('blob:')) {
          URL.revokeObjectURL(previewUrl.value);
        }
        previewUrl.value = '';
        fileName.value = '';
        emit('update:modelValue', '');
        emit('file-selected', null);
      };

      const triggerFileInput = () => {
        fileInput.value.click();
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        onFileChange,
        onBlur,
        validate,
        localError,
        previewUrl,
        fileName,
        fileInput,
        isImage,
        changeFile,
        removeFile,
        triggerFileInput,
        isUploading,
      };
    },
  };
</script>

<style scoped lang="scss">
  .file-upload {
    &__preview {
      display: flex;
      align-items: center;
    }

    &__file-info {
      display: flex;
      align-items: center;
    }

    &__upload {
      display: flex;
      align-items: center;
    }

    .image-preview {
      max-width: 100px;
      max-height: 100px;
      object-fit: cover;
      margin-right: 10px;
    }
  }
</style>
