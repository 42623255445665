import axios from 'axios';

class FileService {
  async uploadFile(file, options = {}) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      // Add options to formData
      Object.entries(options).forEach(([key, value]) => {
        formData.append(key, String(value));
      });

      const response = await axios.post('/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // Add timeout and additional debugging
        timeout: 30000,
        onUploadProgress: (progressEvent) => {},
      });

      return response.data;
    } catch (error) {
      console.error('FileService: Upload failed', {
        message: error.message,
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        config: error.config,
      });
      throw error;
    }
  }
}

export default new FileService();
