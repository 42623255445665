<template>
  <span class="label" :class="variantClass">
    <slot />
  </span>
</template>

<script>
  export default {
    name: 'Label',
    props: {
      variant: {
        type: String,
        default: 'default',
        validator: (value) => {
          return ['default', 'success', 'warning', 'danger', 'info'].includes(value);
        },
      },
    },
    computed: {
      variantClass() {
        return `label--${this.variant}`;
      },
    },
  };
</script>

<style scoped lang="scss">
  .label {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    :deep(.icon) {
      margin-right: 0.25rem;
    }

    &--default {
      background-color: var(--slate-100);
      color: var(--slate-600);
      border: 1px solid var(--slate-200);
    }

    &--primary {
      background-color: var(--primary-50);
      color: var(--primary-700);
      border: 1px solid var(--slate-200);
    }

    &--success {
      background-color: var(--green-50);
      color: var(--green-700);
    }

    &--warning {
      background-color: var(--amber-50);
      color: var(--amber-700);
    }

    &--danger {
      background-color: var(--red-50);
      color: var(--red-700);
    }

    &--info {
      background-color: var(--blue-50);
      color: var(--blue-700);
    }
  }
</style>
