<template>
  <div class="elements-sidebar-wrap" @error="handleError"></div>
  <div class="elements-sidebar" :class="{ 'is-loading': isLoading }">
    <div class="elements-sidebar__inner">
      <template v-if="activeElement">
        <div class="elements-sidebar__heading">
          <div
            class="elements-sidebar__heading-icon"
            :class="`elements-sidebar__heading-icon--category-${activeElement.category}`"
          >
            <BaseIcon :name="`elements_${activeElement.slug}`" size="16" />
          </div>
          <div class="elements-sidebar__heading-label">
            {{ activeElement.label }}
          </div>
        </div>
        <div v-if="error" class="elements-sidebar__error" role="alert">
          {{ error }}
          <button class="close-btn" @click="clearError">&times;</button>
        </div>
        <div class="elements-sidebar__content">
          <component
            :is="settingsComponent"
            :element="activeElement"
            @update-settings="updateElementSettings"
            @save-error="handleSaveError"
            :key="activeElement.id"
          />
        </div>
        <div class="elements-sidebar__footer">
          <Btn @click="closeElementSettings" variant="outlined" :disabled="isLoading">Close</Btn>
        </div>
      </template>
      <div v-if="isLoading" class="elements-sidebar__loading">
        <LoadingSpinner />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch, onBeforeUnmount, defineAsyncComponent } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { uid } from 'uid';

  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  // Dynamic imports for settings components
  const settingsComponents = {
    formula: defineAsyncComponent(() => import('@/components/form/settings/FormulaSettings.vue')),
    email: defineAsyncComponent(() => import('@/components/form/settings/EmailSettings.vue')),
    'single-choice': defineAsyncComponent(
      () => import('@/components/form/settings/SingleChoiceSettings.vue')
    ),
    'multiple-choice': defineAsyncComponent(
      () => import('@/components/form/settings/MultiChoiceSettings.vue')
    ),
    'picture-choice': defineAsyncComponent(
      () => import('@/components/form/settings/PictureChoiceSettings.vue')
    ),
    name: defineAsyncComponent(() => import('@/components/form/settings/NameSettings.vue')),
    phone: defineAsyncComponent(() => import('@/components/form/settings/PhoneSettings.vue')),
    'short-answer': defineAsyncComponent(
      () => import('@/components/form/settings/ShortAnswerSettings.vue')
    ),
    number: defineAsyncComponent(() => import('@/components/form/settings/NumberSettings.vue')),
    heading: defineAsyncComponent(() => import('@/components/form/settings/HeadingSettings.vue')),
    text: defineAsyncComponent(() => import('@/components/form/settings/TextSettings.vue')),
    scheduling: defineAsyncComponent(
      () => import('@/components/form/settings/SchedulingSettings.vue')
    ),
    'long-answer': defineAsyncComponent(
      () => import('@/components/form/settings/LongAnswerSettings.vue')
    ),
    slider: defineAsyncComponent(() => import('@/components/form/settings/SliderSettings.vue')),
  };

  export default {
    name: 'SidebarElement',

    components: {
      BaseIcon,
      Btn,
      LoadingSpinner,
    },
    props: {
      activeElement: {
        type: Object,
        required: true,
      },
    },

    // emits: ['save-settings', 'edit-element-settings'],
    setup(props) {
      const formStore = useFormStore();
      const error = ref(null);
      const isLoading = ref(false);
      const isSaving = ref(false);

      // Get the active element
      const activeElement = computed(() => formStore.activeElement);

      // Dynamically resolve the settings component
      const settingsComponent = computed(() => {
        if (!activeElement.value?.type) return null;
        return settingsComponents[activeElement.value.type];
      });
      // Handle settings updates
      const updateElementSettings = async (newSettings) => {
        try {
          await formStore.updateActiveElementSettings({
            ...newSettings,
            id: activeElement.value.id,
          });
        } catch (err) {
          handleError(err);
        }
      };

      // Error handling
      const handleError = (err) => {
        error.value = err.message || 'An error occurred';
        console.error(err);
      };

      const handleSaveError = (err) => {
        handleError(err);
      };

      const clearError = () => {
        error.value = null;
      };

      // Close sidebar
      const closeElementSettings = () => {
        formStore.unsetElements();
      };

      // Cleanup on unmount
      onBeforeUnmount(() => {
        clearError();
      });

      // Watch for prop changes
      watch(
        () => props.activeElement,
        (newElement) => {
          if (newElement) {
            clearError();
          }
        }
      );

      return {
        activeElement,
        settingsComponent,
        error,
        isLoading,
        isSaving,
        updateElementSettings,
        closeElementSettings,
        handleError,
        handleSaveError,
        clearError,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .elements-sidebar {
    width: 350px;
    // height: 100%;
    overflow-y: auto;

    &.is-loading {
      pointer-events: none;
      opacity: 0.7;
    }

    &__inner {
      padding: 1rem 1rem 5rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__heading {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      @include card('sm');
    }
    &__heading-icon {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      &--category- {
        &text {
          color: map-get($text-controls-color, icon-color);
          background-color: map-get($text-controls-color, background-color);
          border-color: map-get($text-controls-color, border-color);
        }
        &numbers {
          color: map-get($numbers-color, icon-color);
          background-color: map-get($numbers-color, background-color);
          border-color: map-get($numbers-color, border-color);
        }
        &choices {
          color: map-get($choices-color, icon-color);
          background-color: map-get($choices-color, background-color);
          border-color: map-get($choices-color, border-color);
        }
        &options {
          color: map-get($options-color, icon-color);
          background-color: map-get($options-color, background-color);
          border-color: map-get($options-color, border-color);
        }
        &date {
          color: map-get($date-color, icon-color);
          background-color: map-get($date-color, background-color);
          border-color: map-get($date-color, border-color);
        }
        &special {
          color: map-get($special-color, icon-color);
          background-color: map-get($special-color, background-color);
          border-color: map-get($special-color, border-color);
        }
        &contact {
          color: map-get($contact-data-color, icon-color);
          background-color: map-get($contact-data-color, background-color);
          border-color: map-get($contact-data-color, border-color);
        }
        &content {
          color: map-get($content-color, icon-color);
          background-color: map-get($content-color, background-color);
          border-color: map-get($content-color, border-color);
        }
      }
    }
  }
</style>
