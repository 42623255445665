<template>
  <div class="lead-kanban">
    <div class="lead-kanban__columns">
      <div v-for="status in statuses" :key="status.value" class="lead-kanban__column">
        <h3 class="lead-kanban__title">{{ status.label }}</h3>
        <draggable
          :list="leads[status.value]"
          group="leads"
          item-key="id"
          @change="(evt) => handleDragChange(evt, status.value)"
          class="lead-kanban__list"
        >
          <template #item="{ element }">
            <div class="lead-kanban__card-wrapper">
              <div class="lead-kanban__card" @click="$emit('card-click', element)">
                <div class="lead-kanban__card-header">
                  <div class="lead-kanban__card-name">
                    <AvatarElement
                      v-if="element.name"
                      :title="element.name ? element.name.substring(0, 2).toUpperCase() : ''"
                      size="sm"
                      variantColor="gray"
                    />
                    {{ element.name || element.email || 'Unnamed lead' }}
                  </div>
                </div>
                <div class="lead-kanban__card-content">
                  <div class="lead-kanban__lead-info">
                    <Label v-tippy="formatDate(element.created_at)">
                      <BaseIcon name="clock" size="14" />
                      {{ formatTimeAgo(element.created_at) }}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate, formatTimeAgo } from '@/utils/date';
  import draggable from 'vuedraggable';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import AvatarElement from '@/components/partials/Avatar.vue';
  import Label from '@/components/partials/Label.vue';

  export default {
    components: {
      draggable,
      BaseIcon,
      AvatarElement,
      Label,
    },

    props: {
      leads: {
        type: Object,
        required: true,
      },
      statuses: {
        type: Array,
        required: true,
      },
    },

    emits: ['card-click', 'status-change'],

    setup(props, { emit }) {
      const handleDragChange = (evt, newStatus) => {
        if (!evt.added && !evt.moved) return;

        const lead = evt.added?.element || evt.moved?.element;
        if (lead) {
          evt.preventDefault?.();
          const originalStatus = lead.status;
          emit('status-change', lead, newStatus, originalStatus);
        }
      };

      return {
        handleDragChange,
        formatDate,
        formatTimeAgo,
      };
    },
  };
</script>

<style scoped lang="scss">
  .lead-kanban {
    overflow-x: scroll;
    // overflow-y: visible;
    &__columns {
      display: flex;
      gap: 12px;
      min-height: 500px;
      width: 100%;
      min-width: min-content;
      padding-bottom: 1rem; /* For scrollbar space */
      &::-webkit-scrollbar {
        height: 8px;
        background: var(--slate-100);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--slate-300);
        border-radius: 4px;
      }
    }

    &__column {
      flex: 0 0 300px;
      min-width: 300px;
      border-radius: 8px;
      padding: 1rem;
      border: 1px solid var(--slate-200);
      background-color: var(--slate-50);
    }

    &__title {
      margin-bottom: 16px;
    }
    &__list {
      min-height: 100px;
      padding-bottom: 120px;
    }
    &__card-wrapper {
      &.sortable-ghost {
        .lead-kanban__card {
          opacity: 0.8;
        }
      }
      &.sortable-drag {
        .lead-kanban__card {
          transform: rotate(-3deg);
          opacity: 0.8;
        }
      }
    }

    &__card {
      background: white;
      padding: 1rem;
      margin-bottom: 0.75rem;
      border-radius: 0.5rem;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      &.sortable-ghost {
        transform: rotate(3deg);
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    &__card-id {
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--slate-300);
    }
    &__card-name {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__card-header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--slate-200);
    }

    &__card-content {
      padding-top: 0.5rem;
    }

    &__status-icon {
      width: 16px;
      height: 16px;
      color: var(--slate-500);
    }

    &__status-dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: var(--green-500);
      margin-right: 2px;
    }

    &__card-id {
      // Remove the padding-bottom since it's now handled by the header
      margin: 0;
    }
  }
</style>
