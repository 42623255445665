<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError && touched,
      'form-group--has-success': !localError && touched,
    }"
  >
    <div class="sf-input">
      <label v-if="label" class="form-group__label">{{ label }}</label>
      <div class="color-picker" v-tippy="label">
        <VueColorPicker
          :id="id"
          :pureColor="modelValue"
          format="hex"
          shape="square"
          lang="en"
          :disableAlpha="true"
          @update:pureColor="updateColor"
          @blur="onBlur"
        />
      </div>
      <span v-if="localError && touched" class="form-group__error">{{ localError }}</span>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { validateInput } from '@/utils/validation';

  import { ColorPicker as VueColorPicker } from 'vue3-colorpicker';
  import 'vue3-colorpicker/style.css';

  export default {
    name: 'ColorPicker',
    components: {
      VueColorPicker,
    },
    props: {
      id: {
        type: String,
        default: 'color-picker',
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      realTimeValidation: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue', 'validate'],
    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);

      const updateColor = (color) => {
        touched.value = true;
        emit('update:modelValue', color);
        emit('change');
        if (props.realTimeValidation) {
          validate();
        }
      };

      const onBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(props.modelValue, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        },
        { immediate: true }
      );

      return {
        updateColor,
        onBlur,
        validate,
        localError,
        touched,
      };
    },
  };
</script>

<style scoped>
  .sf-input {
    position: relative;
  }
  .color-picker :deep(.vc-color-wrap) {
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    border: 1px solid var(--slate-300);
  }
  .color-picker {
    display: inline-block;
  }
</style>
