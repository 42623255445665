<template>
  <div class="trigger-node" :class="{ 'trigger-node--active': active }" :data-node-id="id">
    <div class="trigger-node__header">
      <div class="trigger-node__label">
        <BaseIcon name="zap" size="12" />
        <span>Trigger</span>
      </div>
      <h3>{{ triggerTypes.find((t) => t.value === nodeData.type)?.label || nodeData.type }}</h3>
    </div>

    <div class="trigger-node__content">
      <template v-if="nodeData.type === 'form_submission'">
        <template v-if="forms.length">
          <div>
            Trigger at a form submission for form
            <i>{{ forms.find((f) => f.id === nodeData.config.formId)?.name }}</i>
          </div>
        </template>
        <template v-else>
          <div class="alert alert--info">
            No funnels found. First add a funnel at the
            <router-link :to="{ path: '/all-flows' }">funnels page</router-link>
            .
          </div>
        </template>
      </template>

      <template v-if="nodeData.type === 'status_change'">
        <SelectInput
          id="from-status"
          label="From status"
          v-model="nodeData.config.fromStatus"
          :options="dealStatuses"
        />
        <SelectInput
          id="to-status"
          label="To status"
          v-model="nodeData.config.toStatus"
          :options="dealStatuses.filter((status) => status.value !== 'new')"
        />
      </template>
      <template v-if="nodeData.type === 'scheduled_event'">
        <SelectInput
          id="frequency"
          label="Frequency"
          v-model="nodeData.config.frequency"
          :options="[
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
          ]"
        />

        <template v-if="nodeData.config.frequency === 'daily'">
          <SelectInput
            id="time"
            label="Time of Day"
            v-model="nodeData.config.schedule_settings.time"
            :options="timeOptions"
          />
        </template>

        <template v-if="nodeData.config.frequency === 'weekly'">
          <SelectInput
            id="days"
            label="Days of Week"
            v-model="nodeData.config.schedule_settings.day"
            :options="weekDayOptions"
            multiple
          />
          <SelectInput
            id="time"
            label="Time of Day"
            type="time"
            v-model="nodeData.config.schedule_settings.time"
            :options="timeOptions"
          />
        </template>

        <template v-if="nodeData.config.frequency === 'monthly'">
          <SelectInput
            id="day"
            label="Day of Month"
            v-model="nodeData.config.schedule_settings.day"
            :options="monthDayOptions"
          />
          <SelectInput
            id="time"
            label="Time of Day"
            type="time"
            v-model="nodeData.config.schedule_settings.time"
            :options="timeOptions"
          />
        </template>
      </template>
    </div>
    <div class="trigger-node__footer" v-if="canAddNewNode">
      <button class="add-node-btn" @click="addNewNode">
        <BaseIcon name="plus" size="12" />
      </button>
    </div>
  </div>
</template>

<script>
  import { ref, watch, computed } from 'vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import ActionPickerModal from '../modals/ActionPickerModal.vue';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import { TRIGGER_TYPES } from '../constants/triggers';
  import { DEAL_STATUSES } from '@/constants/statuses';
  import { useVueFlow } from '@vue-flow/core';
  import { useValidation } from '@/composables/useValidation';

  export default {
    name: 'TriggerNode',

    props: {
      id: {
        type: String,
        required: true,
      },
      data: {
        type: Object,
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update', 'add-node'],
    components: { BaseIcon, SelectInput, ActionPickerModal },
    setup(props, { emit }) {
      const nodeData = ref({
        type: props.data.type,
        config: props.data.config || getDefaultConfig(props.data.type),
      });

      const getDefaultConfig = (type) => {
        switch (type) {
          case 'form_submission':
            return { formId: null };
          case 'lead_status_change':
            return { from_status: null, to_status: null };
          case 'scheduled':
            return {
              frequency: 'daily',
              schedule_settings: { time: '09:00' },
            };
          default:
            return {};
        }
      };

      const handleTypeChange = () => {
        nodeData.value.config = getDefaultConfig(nodeData.value.type);
      };
      const { getOutgoers } = useVueFlow();
      const formStore = useFormStore();
      const forms = computed(() => formStore.forms);
      const uiStore = useUIStore();

      // Add computed property to check for connections
      const hasConnections = computed(() => {
        return getOutgoers({ id: props.id }).length > 0;
      });

      const addNewNode = () => {
        const modalId = uiStore.addModal(ActionPickerModal, {
          title: 'Add Step',
          description: 'Add a new step to the automation',
          width: 'lg',
          onSubmit: (action) => {
            emit('add-node', { afterId: props.id, action });
            uiStore.closeModal(modalId);
          },
        });
      };

      const canAddNewNode = computed(() => {
        return (
          !hasConnections.value &&
          !(nodeData.value.type === 'form_submission' && forms.value.length === 0)
        );
      });

      // Computed properties for options
      const timeOptions = computed(() => {
        return Array.from({ length: 48 }, (_, i) => {
          const hour = Math.floor(i / 2)
            .toString()
            .padStart(2, '0');
          const minute = i % 2 ? '30' : '00';
          const time = `${hour}:${minute}`;
          return { value: time, label: time };
        });
      });

      const weekDayOptions = computed(() => [
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
        { value: 0, label: 'Sunday' },
      ]);

      const monthDayOptions = computed(() =>
        Array.from({ length: 31 }, (_, i) => ({
          value: i + 1,
          label: `${i + 1}${['st', 'nd', 'rd'][i] || 'th'}`,
        }))
      );

      const formOptions = computed(() =>
        forms.value.map((form) => ({
          value: form.id,
          label: form.name,
        }))
      );

      watch(
        () => props.data,
        (newData) => {
          nodeData.value = {
            type: newData.type,
            config: newData.config || getDefaultConfig(newData.type),
          };
        },
        { deep: true }
      );

      watch(
        nodeData,
        (newVal) => {
          // emit('update', { id: props.id, data: newVal });
        },
        { deep: true }
      );

      return {
        nodeData,
        forms,
        handleTypeChange,
        triggerTypes: TRIGGER_TYPES,
        dealStatuses: DEAL_STATUSES,
        forms,
        addNewNode,
        hasConnections,
        canAddNewNode,
        weekDayOptions,
        monthDayOptions,
        timeOptions,
        formOptions,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .trigger-node {
    background: #ffffff;
    border: 1px solid var(--slate-200);
    border-radius: 12px;
    padding: 1rem;
    width: 300px;
    color: var(--slate-900);
    outline: 0px solid transparent;
    transition: all 0.2s ease;

    &--active {
      border-color: var(--brand-500);
      outline: 4px solid var(--brand-200);
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
      @include fontSize('md');
      h3 {
        font-size: inherit;
      }
    }
    &__label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: var(--slate-50);
      border: 1px solid var(--slate-200);
      border-radius: 6px;
      padding: 0.25rem 0.5rem;
      @include fontSize('sm');
      .icon {
        color: var(--green-500);
      }

      .label {
        color: #666;
      }
    }
    &__badge {
      background: #333;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      font-size: 0.875rem;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__content {
    }

    &__footer {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;
      .add-node-btn {
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        justify-content: center;
        @include hover {
          background: var(--slate-50);
          border-color: var(--brand-500);
        }
      }
    }
    .alert {
      margin-top: 1rem;
      font-size: 0.875rem;
      font-style: italic;
      a {
        text-decoration: underline;
      }
    }
  }
</style>
