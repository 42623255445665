<template>
  <div class="form-list">
    <div class="form-list__toolbar">
      <PageHeader title="Your funnels" description="View and manage all your stored funnels">
        <template #actions>
          <Btn text="New flow" @click="openNewFunnelModal" icon="plus" v-if="isAdmin" />
        </template>
      </PageHeader>
    </div>
    <EmptyState
      v-if="!forms.length"
      title="No funnels yet"
      description="Get started by creating your first funnel."
      icon="duotone-folders"
    >
      <Btn
        text="Create new flow"
        icon="plus"
        variant="secondary"
        @click="openNewFunnelModal"
        :disabled="!isAdmin"
      />
    </EmptyState>
    <ul v-else>
      <li v-for="form in forms" :key="form.id">
        <FormCard :form="form" />
      </li>
    </ul>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import EmptyState from '@/components/partials/EmptyState.vue';
  import FormCard from '@/components/partials/Card/FormCard.vue';
  import Btn from '@/components/partials/Btn.vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import { useRoleCheck } from '@/composables/useRoleCheck';
  import CreateFunnelForm from '@/components/dashboard/CreateFunnelForm.vue';

  export default {
    components: {
      FormCard,
      Btn,
      PageHeader,
      EmptyState,
    },
    setup() {
      const formStore = useFormStore();
      const forms = ref(formStore.forms);
      const fetchForms = async () => {
        await formStore.fetchForms();
      };

      const { isAdmin } = useRoleCheck();

      watch(
        () => formStore.forms,
        (newForms) => {
          forms.value = newForms;
        }
      );

      const uiStore = useUIStore();
      const openNewFunnelModal = () => {
        const modalId = uiStore.addModal(CreateFunnelForm, {
          title: 'Create a new flow',
          description: 'Let us know how you would like to create your new flow 👏',
          onSubmit: async (data) => {
            uiStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      };

      onMounted(fetchForms);

      return {
        openNewFunnelModal,
        forms,
        isAdmin,
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-list {
    ul {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 1.5rem;
      list-style: none;
      margin: 0;
      margin-left: 0;
      margin-right: 0;
      li {
        grid-column: span 4 / span 4;
      }
    }
  }
</style>
