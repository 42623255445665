<template>
  <div class="fnnl-page">
    <div class="fnnl-page__wrap">
      <div class="fnnl-page__content">
        <h3 class="fnnl-page__title">{{ page.title }}</h3>
        <draggable
          v-model="page.elements"
          @end="onDragEnd"
          class="draggable-list"
          item-key="id"
          handle=".sf-element__drag-handle"
        >
          <template #item="{ element, index }">
            <div
              :key="element.id"
              @click="setActiveElement(index)"
              class="sf-element"
              :class="{ 'sf-element--active': isElementActive(index) }"
            >
              <div class="sf-element__heading">
                <div class="sf-element__dropdown">
                  <div
                    class="sf-element__icon"
                    :class="`sf-element__icon--category-${element.category}`"
                  >
                    <BaseIcon :name="`elements_${element.slug}`" size="10" />
                  </div>
                  <div class="sf-element__label">
                    {{ element.label }}
                  </div>
                  <!-- <BaseIcon
                      name="chevron"
                      @click.stop="editElement(index)"
                      size="14"
                      class="sf-element__chevron"
                    /> -->
                </div>
                <div class="sf-element__actions">
                  <div
                    class="sf-element__action"
                    :class="{
                      'sf-element__action--active': element.props.conditions?.groups?.length > 0,
                    }"
                  >
                    <BaseIcon name="split-up" size="14" />
                  </div>
                  <div class="sf-element__action">
                    <BaseIcon name="clone" @click.stop="cloneElement(index)" size="14" />
                  </div>
                  <div class="sf-element__action sf-element__action--delete">
                    <BaseIcon name="trash" @click.stop="removeElement(index)" size="14" />
                  </div>
                </div>
              </div>
              <!-- <FormElement :key="element.id" :element="element" :formData="null" :dummy="true" /> -->
              <component
                :is="getElementComponent(element.type)"
                v-bind="element.props"
                v-model="element.props.answer"
                @update:modelValue="
                  (value) => handleInput(element, value, blockIndex, elementIndex)
                "
              ></component>
              <div class="sf-element__drag-handle">
                <BaseIcon name="grip-dots" size="12" />
              </div>
            </div>
          </template>
        </draggable>
        <div v-if="!activeElement" class="fnnl-page__element-placeholder">
          <div class="sf-element sf-element--active">
            <p>Select an element from the sidebar to fill</p>
          </div>
        </div>
        <div class="fnnl-page__add" v-else>
          <div class="fnnl-page__add-btn" @click="addNewElement()">
            <BaseIcon name="plus" size="16" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useFormStore } from '@/store/formStore';
  import { computed } from 'vue';
  import { useUIStore } from '@/store/uiStore';
  import draggable from 'vuedraggable';

  import BlockConditionsModalForm from '@/components/editor/settings/BlockConditionsModalForm.vue';

  import FormElement from '@shared/components/form/FormElement.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import EmailElement from '@shared/components/form/elements/EmailElement.vue';
  import SingleChoiceElement from '@shared/components/form/elements/SingleChoiceElement.vue';
  import MultiChoiceElement from '@shared/components/form/elements/MultiChoiceElement.vue';
  import PictureChoiceElement from '@shared/components/form/elements/PictureChoiceElement.vue';
  import ShortAnswerElement from '@shared/components/form/elements/ShortAnswerElement.vue';
  import LongAnswerElement from '@shared/components/form/elements/LongAnswerElement.vue';
  import NameElement from '@shared/components/form/elements/NameElement.vue';
  import NumberElement from '@shared/components/form/elements/NumberElement.vue';
  import PhoneElement from '@shared/components/form/elements/PhoneElement.vue';
  import HeadingElement from '@shared/components/form/elements/HeadingElement.vue';
  import TextElement from '@shared/components/form/elements/TextElement.vue';
  import SchedulingElement from '@shared/components/form/elements/SchedulingElement.vue';
  import SliderElement from '@shared/components/form/elements/SliderElement.vue';
  import FormulaElement from '@shared/components/form/elements/FormulaElement.vue';
  import Message from '@/components/partials/Message.vue';

  export default {
    name: 'FormPage',

    components: {
      FormElement,
      draggable,
      BaseIcon,
      Btn,
      EmailElement,
      SingleChoiceElement,
      MultiChoiceElement,
      ShortAnswerElement,
      NameElement,
      NumberElement,
      PhoneElement,
      BlockConditionsModalForm,
      Message,
      HeadingElement,
      TextElement,
      SchedulingElement,
      SliderElement,
    },

    props: {
      page: {
        type: Object,
        required: true,
      },
      activeElementIndex: {
        type: Number,
        default: null,
      },
      formSettings: {
        type: Object,
        default: null,
      },
    },

    emits: [
      'remove-element',
      'set-active-element',
      'add-new-element',
      'edit-element-settings',
      'element-input',
    ],

    setup(props, { emit }) {
      const formStore = useFormStore();
      const uiStore = useUIStore();

      const activeElement = computed(() => formStore.activeElement);
      const getElementComponent = (type) => {
        const components = {
          email: EmailElement,
          'single-choice': SingleChoiceElement,
          'multiple-choice': MultiChoiceElement,
          'picture-choice': PictureChoiceElement,
          name: NameElement,
          number: NumberElement,
          phone: PhoneElement,
          'short-answer': ShortAnswerElement,
          'long-answer': LongAnswerElement,
          heading: HeadingElement,
          text: TextElement,
          scheduling: SchedulingElement,
          slider: SliderElement,
          formula: FormulaElement,
        };
        return components[type] || null;
      };

      const isElementActive = (elementIndex) => {
        return elementIndex === props.activeElementIndex;
      };

      const setActiveElement = (elementIndex) => {
        emit('set-active-element', elementIndex);
      };

      const removeElement = (elementIndex) => {
        emit('remove-element', elementIndex);
      };

      const addNewElement = () => {
        formStore.unsetElements();
      };

      const cloneElement = (elementIndex) => {
        // Call formStore action instead of directly manipulating page elements
        formStore.cloneElement({
          elementIndex: elementIndex,
        });
      };

      const onDragEnd = (event) => {
        setActiveElement(event.newIndex);
      };

      const handleInput = (element, value, elementIndex) => {
        element.props.answer = value;

        emit('element-input', {
          elementIndex,
          element,
          value,
        });
      };

      return {
        getElementComponent,
        isElementActive,
        setActiveElement,
        activeElement,
        removeElement,
        addNewElement,
        handleInput,
        cloneElement,
        onDragEnd,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .funnel {
    max-width: 600px;
  }
  .fnnl-page {
    background-color: var(--cs-background-primary);
    &__heading {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }

    &__content {
      position: relative;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      transition: transform 0.2s ease;
    }
    &__title {
      margin-bottom: 2rem;
    }

    .sf-element {
      width: 100%;
      padding: 1rem 1rem 1rem 3rem;
      border: 1px dashed transparent;
      border-radius: 0.25rem;
      position: relative;
      margin-bottom: 1rem;

      @include hover {
        border-color: var(--slate-300);
        .sf-element__drag-handle {
          opacity: 1;
        }
      }
      &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        overflow: hidden;
        height: 0;
        opacity: 0;
        padding: 0;
        transition: all 0.2s ease;
      }
      &__drag-handle {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s ease;
        cursor: grab;
        color: var(--slate-400);
      }

      &.sortable-ghost {
        opacity: 0.8;
      }
      &.sortable-chosen,
      &.sortable-drag {
        transform: rotate(-2deg);
        opacity: 0.8;
        background-color: #ffffff;
      }

      &--active {
        border-color: var(--brand-500);
        // border-style: solid;
        cursor: default;
        @include hover {
          border-color: var(--brand-500);
          cursor: default;
        }

        .sf-element__heading {
          height: auto;
          opacity: 1;
          padding: 0.5rem 0;
        }
        .sf-element__actions {
          display: flex;
        }
        .sf-element__dropdown {
          display: flex;
        }
      }

      &__dropdown {
        display: flex;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        background-color: var(--white);
        border-radius: 2rem;
        padding: 0.25rem;
        align-items: center;
        gap: 0.25rem;
        height: 2rem;
        padding: 0 0.5rem;
        .sf-element__chevron {
          transform: rotate(180deg);
        }
        .sf-element__icon {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          &--category- {
            &text {
              color: map-get($text-controls-color, icon-color);
              background-color: map-get($text-controls-color, background-color);
              border-color: map-get($text-controls-color, border-color);
            }
            &numbers {
              color: map-get($numbers-color, icon-color);
              background-color: map-get($numbers-color, background-color);
              border-color: map-get($numbers-color, border-color);
            }
            &choices {
              color: map-get($choices-color, icon-color);
              background-color: map-get($choices-color, background-color);
              border-color: map-get($choices-color, border-color);
            }
            &options {
              color: map-get($options-color, icon-color);
              background-color: map-get($options-color, background-color);
              border-color: map-get($options-color, border-color);
            }
            &date {
              color: map-get($date-color, icon-color);
              background-color: map-get($date-color, background-color);
              border-color: map-get($date-color, border-color);
            }
            &special {
              color: map-get($special-color, icon-color);
              background-color: map-get($special-color, background-color);
              border-color: map-get($special-color, border-color);
            }
            &contact {
              color: map-get($contact-data-color, icon-color);
              background-color: map-get($contact-data-color, background-color);
              border-color: map-get($contact-data-color, border-color);
            }
            &content {
              color: map-get($content-color, icon-color);
              background-color: map-get($content-color, background-color);
              border-color: map-get($content-color, border-color);
            }
          }
        }
      }
      &__actions {
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        background-color: var(--white);
        border-radius: 2rem;
        padding: 0.25rem;
        display: flex;
        gap: 0.25rem;
      }
      &__action {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &--delete {
          color: var(--red-500);
        }
        &--active {
          color: var(--green-500);
        }
      }
    }

    &__add {
      padding: 1rem;
      display: flex;
      justify-content: center;
    }

    &__add-btn {
      width: 2rem;
      height: 2rem;
      border: 1px solid var(--slate-300);
      background-color: var(--slate-50);
      color: var(--slate-500);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      @include hover {
        background-color: var(--slate-100);
        border-color: var(--slate-400);
        color: var(--slate-700);
      }
    }
    &__element-placeholder {
      text-align: center;
    }
  }
</style>
