<template>
  <div class="form-integrations">
    <div class="container">
      <PageHeader
        title="Form Integrations"
        description="Enable or disable integrations for this specific form"
      />

      <EmptyState
        v-if="filteredIntegrations.length === 0"
        title="No integrations found"
        description="No integrations available. Add integrations in the Integrations section."
        icon="duotone-cubes"
      >
        <Btn
          text="Add integration"
          icon="plus"
          @click="$router.push('/settings/integrations')"
          variant="secondary"
        />
      </EmptyState>
      <div class="form-integrations__list" v-else>
        <IntegrationCard
          v-for="integration in filteredIntegrations"
          :key="integration.id"
          :integration="integration"
          variant="form"
          :formId="formId"
          @toggle="toggleIntegration(integration.id, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useIntegrationStore } from '@/store/integrationStore';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import IntegrationCard from '@/components/integrations/IntegrationCard.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import EmptyState from '@/components/partials/EmptyState.vue';

  export default {
    name: 'FormIntegrationsTab',

    components: {
      PageHeader,
      IntegrationCard,
      Btn,
      EmptyState,
    },

    setup(props) {
      const route = useRoute();
      const formId = route.params.id;

      const integrationStore = useIntegrationStore();
      const formStore = useFormStore();
      const loading = ref(false);

      // Get all active integrations
      // const integrations = computed(() => integrationStore.integrations.filter((i) => i.is_active));
      const integrations = computed(() => integrationStore.integrations);

      // Filter and sort integrations
      const filteredIntegrations = computed(() => {
        // Sort by enabled status first, then by name
        return [...integrations.value].sort((a, b) => {
          const aEnabled = isIntegrationEnabled(a.id);
          const bEnabled = isIntegrationEnabled(b.id);
          if (aEnabled !== bEnabled) return bEnabled ? 1 : -1;
          return a.name.localeCompare(b.name);
        });
      });

      // Check if integration is enabled for this form
      const isIntegrationEnabled = (integrationId) => {
        return integrationStore.isIntegrationEnabled(props.formId, integrationId);
      };

      // Toggle integration for this form
      const toggleIntegration = async (integrationId, enabled) => {
        try {
          await integrationStore.toggleIntegration(formId, integrationId, enabled);
        } catch (error) {
          console.error('Failed to toggle integration:', error);
        }
      };

      // Load integrations on mount
      onMounted(async () => {
        try {
          await integrationStore.fetchIntegrations();
        } catch (error) {
          console.error('Failed to fetch integrations:', error);
        }
      });

      return {
        filteredIntegrations,
        isIntegrationEnabled,
        toggleIntegration,
        formId,
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-integrations {
    .integration-card {
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--brand-500);
      }

      .integration-icon {
        border-color: var(--slate-200);
      }
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      margin-top: 24px;
    }
  }
</style>
