<template>
  <div class="sf-el scheduling">
    <div class="scheduling__header">
      <div class="scheduling__title">{{ title }}</div>
      <div class="scheduling__description">{{ description }}</div>
    </div>

    <template v-if="!loading">
      <template v-if="months.length > 0">
        <div class="scheduling__month">
          <select v-model="selectedMonth" class="scheduling__month-select">
            <option v-for="month in months" :key="month.name" :value="month.name">
              {{ month.name }}
            </option>
          </select>
          <div class="scheduling__nav">
            <button @click="previousDays" class="scheduling__nav-button" :disabled="loading">
              &larr;
            </button>
            <button @click="nextDays" class="scheduling__nav-button" :disabled="loading">
              &rarr;
            </button>
          </div>
        </div>
        <div class="scheduling__calendar">
          <div class="scheduling__days-container">
            <div class="scheduling__days" ref="daysContainer">
              <div
                v-for="(day, index) in getCurrentMonthDays"
                :key="index"
                class="scheduling__day"
                :class="{
                  'scheduling__day--has-slots': day.slotsCount > 0,
                  'scheduling__day--selected': selectedDay === day,
                  'scheduling__day--empty': day.slotsCount === 0,
                }"
                @click="selectDay(day)"
              >
                <div class="scheduling__day-header">{{ day.dayName }}</div>
                <div class="scheduling__day-number">{{ day.date }}</div>
                <div v-if="day.slotsCount && day.slotsCount > 0" class="scheduling__day-slots">
                  {{ day.slotsCount }} slots
                </div>
                <div v-else class="scheduling__day-empty">-</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedDay" class="scheduling__times">
          <div
            v-for="(slot, index) in timeSlots"
            :key="index"
            class="scheduling__time-slot"
            @click="selectTimeSlot(slot)"
            :class="{
              'scheduling__time-slot--selected': selectedSlot && selectedSlot.id === slot.id,
            }"
          >
            {{ formatToTimeZone(slot) }}
          </div>
        </div>
        <div class="scheduling__footer">
          <template v-if="selectedSlot">
            <span>Selected slot:</span>
            <span>{{ formatToTimeZone(selectedSlot) }}</span>
          </template>
          <template v-else>No slot selected</template>
        </div>
      </template>
      <div v-else class="scheduling__no-slots">
        No available slots found. Please come back later.
      </div>
    </template>

    <div v-if="loading" class="scheduling__loading">
      <LoadingSpinner size="lg" color="dark" />
    </div>

    <div v-else-if="error" class="scheduling__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  const getSchedulingService = async () => {
    // Check if we're in Nuxt or if env variable is set
    const useRealService =
      typeof useRuntimeConfig === 'function'
        ? useRuntimeConfig()?.public?.useRealScheduling
        : false;

    if (useRealService) {
      const { schedulingService } = await import('@shared/services/schedulingService');
      return schedulingService;
    }
    // Dummy service implementation
    return {
      getAvailableSlots: async () => {
        const today = dayjs().startOf('day');
        return {
          months: [
            {
              name: today.format('MMMM YYYY'),
              days: [
                {
                  date: today.format('YYYY-MM-DD'),
                  dayName: today.format('ddd'),
                  dayNumber: today.date(),
                  slotsCount: 2,
                  slots: [
                    {
                      id: 'dummy-slot-1',
                      startTime: today.add(11, 'hour').toISOString(),
                      endTime: today.add(12, 'hour').toISOString(),
                      status: 'available',
                    },
                    {
                      id: 'dummy-slot-2',
                      startTime: today.add(14, 'hour').toISOString(),
                      endTime: today.add(15, 'hour').toISOString(),
                      status: 'available',
                    },
                  ],
                },
                {
                  date: today.add(1, 'day').format('YYYY-MM-DD'),
                  dayName: today.add(1, 'day').format('ddd'),
                  dayNumber: today.add(1, 'day').date(),
                  slotsCount: 1,
                  slots: [
                    {
                      id: 'dummy-slot-3',
                      startTime: today.add(1, 'day').add(13, 'hour').toISOString(),
                      endTime: today.add(1, 'day').add(14, 'hour').toISOString(),
                      status: 'available',
                    },
                  ],
                },
              ],
            },
          ],
          currentMonth: today.format('MMMM YYYY'),
        };
      },
      reserveSlot: async (formId, slotId) => ({
        data: { id: `dummy-reservation-${slotId}` },
      }),
    };
  };
  import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import LoadingSpinner from '@shared/components/LoadingSpinner.vue';

  export default {
    name: 'SchedulingElement',
    components: {
      BaseIcon,
      LoadingSpinner,
    },
    props: {
      formId: {
        type: String,
        required: true,
      },
      fieldId: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: '30 minute call',
      },
      description: {
        type: String,
        default: "Let's schedule a quick call",
      },
      modelValue: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const daysContainer = ref(null);
      const schedulingService = getSchedulingService();
      const loading = ref(false);
      const isReserving = ref(false);
      const error = ref(null);
      const months = ref([]);
      const selectedMonth = ref(null);
      const selectedDay = ref(null);
      const timeSlots = ref([]);
      const selectedSlot = ref(null);

      // Initialize the service
      onMounted(async () => {
        schedulingService.value = await getSchedulingService();
        await fetchAvailableSlots();
      });

      const updateDayWidth = () => {
        const containerWidth = daysContainer.value?.offsetWidth;
        if (containerWidth) {
          const dayWidth = containerWidth / 5 - 12; // Subtract gap/padding
          daysContainer.value.style.setProperty('--day-width', `${dayWidth}px`);
        }
      };

      onMounted(() => {
        updateDayWidth();
        window.addEventListener('resize', updateDayWidth);

        onUnmounted(() => {
          window.removeEventListener('resize', updateDayWidth);
        });
      });

      const fetchAvailableSlots = async () => {
        if (!schedulingService.value) return;
        loading.value = true;
        error.value = null;
        try {
          const response = await schedulingService.value.getAvailableSlots(
            props.formId,
            props.fieldId
          );

          // Store months data
          months.value = response.months;

          // Set initial selected month
          if (response.currentMonth) {
            selectedMonth.value = response.currentMonth;
          } else if (months.value && months.value.length > 0) {
            selectedMonth.value = months.value[0].name;
          }

          // Clear any previously selected day and time slots
          selectedDay.value = null;
          timeSlots.value = [];
        } catch (err) {
          error.value = 'Failed to load available slots';
          console.error(err);
        } finally {
          loading.value = false;
        }
      };

      function formatToTimeZone(date) {
        // Convert UTC times to local timezone
        if (!date?.startTime || !date?.endTime) {
          return '';
        }
        const start = new Date(date.startTime);
        const end = new Date(date.endTime);

        const startTimeStr = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const endTimeStr = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        return `${startTimeStr} - ${endTimeStr}`;
      }

      const selectDay = async (day) => {
        if (day.slotsCount > 0) {
          selectedDay.value = day;
          timeSlots.value = day.slots.map((slot) => ({
            id: slot.id,
            time: slot.time,
            startTime: slot.startTime,
            endTime: slot.endTime,
            status: slot.status,
          }));
          selectedSlot.value = null;
          emit('update:modelValue', null);
        }
      };

      const selectTimeSlot = async (slot) => {
        if (slot.status === 'available') {
          try {
            isReserving.value = true;
            selectedSlot.value = slot;

            const reservation = await schedulingService.value.reserveSlot(props.formId, slot.id);
            const update = {
              slotId: slot.id,
              date: selectedDay.value.date,
              startTime: slot.startTime,
              endTime: slot.endTime,
              // ...reservation,
            };
            // console.log('update', update);
            // Emit the selected slot data
            emit('update:modelValue', slot.id);
          } catch (err) {
            error.value = 'Failed to reserve time slot';
            console.error(err);
            selectedSlot.value = null;
            emit('update:modelValue', null);
          } finally {
            isReserving.value = false;
          }
        }
      };
      const getCurrentMonthDays = computed(() => {
        if (!selectedMonth.value || !months.value.length) return [];
        const month = months.value.find((m) => m.name === selectedMonth.value);
        return month ? month.days : [];
      });

      const scrollAmount = computed(() => {
        return daysContainer.value?.offsetWidth || 0;
      });

      const nextDays = () => {
        if (daysContainer.value) {
          daysContainer.value.scrollBy({
            left: scrollAmount.value,
            behavior: 'smooth',
          });
        }
      };

      const previousDays = () => {
        if (daysContainer.value) {
          daysContainer.value.scrollBy({
            left: -scrollAmount.value,
            behavior: 'smooth',
          });
        }
      };

      watch(selectedMonth, () => {
        if (daysContainer.value) {
          daysContainer.value.scrollLeft = 0;
        }
      });

      return {
        loading,
        isReserving,
        error,
        months,
        selectedMonth,
        selectedDay,
        timeSlots,
        selectedSlot,
        getCurrentMonthDays,
        selectDay,
        formatToTimeZone,
        selectTimeSlot,
        daysContainer,
        nextDays,
        previousDays,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@shared/assets/css/base/mixins.scss';
  .scheduling {
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid var(--slate-100);
    border-radius: 0.5rem;
    background-color: var(--white);

    &__header {
      padding: 1rem 1rem 1.5rem 1rem;
      border-bottom: 1px solid var(--slate-200);
    }

    &__title {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
    }

    &__description {
      color: var(--text-secondary);
      font-size: 0.9rem;
    }

    &__no-slots {
      padding: 1rem;
      text-align: center;
      color: var(--slate-700);
    }

    &__month {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1rem 0 1rem;
    }

    &__month-select {
      padding: 0.5rem 1rem 0.5rem 0.25rem;
      border-radius: 0.5rem;
      // border: 1px solid var(--border-color);
      @include fontSize('sm');
      background: white;
      width: auto;
      height: 2.25rem;
    }

    &__nav {
      display: flex;
      gap: 0.5rem;
    }

    &__nav-button {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 0.5rem;
      border: 1px solid var(--slate-200);
      background: white;
      cursor: pointer;
      box-shadow:
        0 0 rgba(0, 0, 0, 0),
        0 0 rgba(0, 0, 0, 0),
        0 1px 2px 0 rgba(0, 0, 0, 0.05);

      &:hover {
        background: var(--slate-200);
      }
    }

    &__calendar {
      padding: 1rem;
    }

    &__weekdays {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }

    &__days-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding: 0 1px;
      --day-width: 100px;
      margin: 0 -0.75rem;
    }
    .scheduling__days {
      display: flex;
      overflow-x: auto;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      gap: 12px;
      padding: 12px;
    }

    .scheduling__days::-webkit-scrollbar {
      display: none;
    }

    &__day {
      flex: 0 0 auto;
      width: var(--day-width);
      padding: 1rem;
      border: 1px solid var(--slate-100);
      border-radius: 0.5rem;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &--has-slots {
        background: var(--primary-light);
        border-color: var(--slate-300);

        &:hover {
          background: var(--slate-100);
        }
      }

      &--empty {
        background: var(--slate-50);
        border-color: var(--slate-100);
        color: var(--slate-400);
        cursor: default;
        pointer-events: none;
      }

      &--selected {
        background: var(--slate-50);
        border-color: var(--brand-500);
        outline: 3px solid var(--brand-200);
      }
    }

    &__day-number {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    &__day-slots {
      @include fontSize('xs');
      color: var(--slate-800);
    }

    &__day-empty {
      font-size: 0.9rem;
      color: var(--text-secondary);
    }

    &__times {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 0.75rem;
      padding: 0 1rem 1rem 1rem;
    }

    &__time-slot {
      padding: 0.75rem;
      border: 1px solid var(--slate-300);
      border-radius: 0.5rem;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease;
      &--selected {
        background: var(--slate-50);
        border-color: var(--brand-500);
        outline: 3px solid var(--brand-200);
      }

      &:hover {
        background: var(--bg-hover);
        border-color: var(--primary);
      }
    }

    &__loading,
    &__error {
      text-align: center;
      padding: 2rem;
      color: var(--text-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__error {
      color: var(--error);
    }

    &__footer {
      padding: 1rem 1rem 1rem 1rem;
      border-top: 1px solid var(--slate-200);
      @include fontSize('md');
    }
  }
</style>
