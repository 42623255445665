import { ref } from 'vue';

export function useDebounce(fn, delay = 2000) {
  const timeoutRef = ref(null);

  const debouncedFn = (...args) => {
    clearTimeout(timeoutRef.value);
    timeoutRef.value = setTimeout(() => {
      fn(...args);
    }, delay);
  };

  return debouncedFn;
}
