<template>
  <div class="sf-el heading-item">
    <div class="sf-heading">
      <h2>{{ content }}</h2>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'HeadingElement',
    props: {
      content: String,
    },
    setup(props, { emit }) {},
  };
</script>

<style scoped lang="scss">
  .sf-heading {
  }
</style>
