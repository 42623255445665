<template>
  <div class="automation-builder">
    <div class="automation-builder__content">
      <VueFlow
        v-model="elements"
        @update:model-value="onElementsChange"
        :default-edge-type="SmoothStepEdge"
        :default-edges="{ type: 'smoothstep' }"
        :default-zoom="1.5"
        :min-zoom="0.2"
        :max-zoom="4"
        :auto-connect="true"
        class="automation-builder__flow"
        @nodesDragged="onNodesDragged"
        @nodesChanged="onNodesChanged"
        @edgesChanged="onEdgesChanged"
        @nodeClick="onNodeClick"
      >
        <template #node-trigger="nodeProps">
          <TriggerNode
            v-bind="nodeProps"
            @add-node="addAction"
            :active="activeNode?.id === nodeProps.id"
            :id="nodeProps.id"
          />
        </template>
        <template #node-action="nodeProps">
          <ActionNode
            v-bind="nodeProps"
            @add-node="addAction"
            :currentAutomation="automation"
            :active="activeNode?.id === nodeProps.id"
            :id="nodeProps.id"
          />
        </template>

        <Controls />
        <Background pattern-color="#aaa" gap="8" />
        <MiniMap />
      </VueFlow>
    </div>
    <Drawer
      v-if="activeNode"
      :show="!!activeNode"
      :props="{
        icon: activeNode?.type === 'trigger' ? 'zap' : 'bullseye',
        subtitle: activeNode?.type === 'trigger' ? 'Trigger' : 'Action',
        title: getNodeTitle(activeNode),
        description: 'Configure the settings for this block',
      }"
      @close="closeNodeSettings"
      @refresh="refreshNode"
      @delete="deleteNode"
    >
      <NodeSettings
        :type="activeNode?.type"
        :nodeData="activeNode?.data"
        :hasConnections="hasNodeConnections(activeNode?.id)"
        :currentAutomation="automation"
        @update="updateNodeData"
      />
      <template #footer v-if="activeNode.type === 'action'">
        <Btn variant="danger" @click="deleteNode">Delete node</Btn>
      </template>
    </Drawer>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { VueFlow, useVueFlow, SmoothStepEdge } from '@vue-flow/core';
  import { Background } from '@vue-flow/background';
  import { Controls } from '@vue-flow/controls';
  import { MiniMap } from '@vue-flow/minimap';
  import '@vue-flow/core/dist/style.css';
  import '@vue-flow/core/dist/theme-default.css';
  import TriggerNode from './nodes/TriggerNode.vue';
  import ActionNode from './nodes/ActionNode.vue';
  import { debounce } from 'lodash-es';
  import Drawer from '@/components/drawer/Drawer.vue';
  import { TRIGGER_TYPES, ACTION_TYPES } from '@/constants/automations';
  import NodeSettings from './NodeSettings.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'AutomationBuilder',

    components: {
      VueFlow,
      Background,
      Controls,
      MiniMap,
      TriggerNode,
      ActionNode,
      Drawer,
      NodeSettings,
      Btn,
      SmoothStepEdge,
    },

    props: {
      automation: {
        type: Object,
        required: true,
      },
    },

    emits: ['update', 'save'],

    setup(props, { emit }) {
      const { addNodes, addEdges, getOutgoers } = useVueFlow();
      const elements = ref([]);
      const lastEmittedAutomation = ref(null);
      const isInitializing = ref(true);
      const activeNode = ref(null);

      function getTriggerConfig(type, details) {
        switch (type) {
          case 'form_submission':
            return { formId: details?.formId || null, formDetails: details?.formDetails || null };
          case 'lead_status_change':
            return {
              from_status: details?.from_status || null,
              to_status: details?.to_status || null,
            };
          case 'scheduled':
            return {
              frequency: details?.frequency || 'daily',
              schedule_settings:
                details?.schedule_settings ||
                {
                  daily: { time: '09:00' },
                  weekly: { day: 1, time: '09:00' },
                  monthly: { day: 1, time: '09:00' },
                  custom: { cron: '0 9 * * *' },
                }[details?.frequency || 'daily'],
            };
          default:
            return {};
        }
      }

      function convertToElements(automation) {
        if (!automation) {
          return [
            {
              id: 'trigger-node',
              type: 'trigger',
              position: { x: 290, y: 50 },
              data: {
                type: 'form_submission',
                config: { formId: null },
              },
            },
          ];
        }

        const elements = [];

        // Add trigger node
        elements.push({
          id: 'trigger-node',
          type: 'trigger',
          position: { x: 290, y: 50 },
          data: {
            type: automation.trigger_type,
            config: {
              formId: automation.trigger_details?.formId,
              formDetails: automation.trigger_details?.formDetails,
            },
          },
        });

        // Add automation steps
        if (automation.steps?.length) {
          let lastY = 150;
          automation.steps.forEach((step, index) => {
            const node = {
              id: step.id,
              type: 'action',
              position: { x: 290, y: lastY },
              data: {
                type: step.type || 'task',
                config: step.configuration || {},
                previousSteps: [
                  step.previous_step_id ||
                    (index === 0 ? 'trigger-node' : automation.steps[index - 1].id),
                ],
              },
            };
            elements.push(node);

            // Add edge
            const sourceId =
              step.previous_step_id ||
              (index === 0 ? 'trigger-node' : automation.steps[index - 1].id);
            elements.push({
              id: `e-${sourceId}-${node.id}`,
              source: sourceId,
              target: node.id,
              type: 'smoothstep',
              style: { strokeWidth: 2 },
            });

            lastY += NODE_VERTICAL_SPACING;
          });
        }
        return elements;
      }

      const lastEmittedElements = ref(null);

      function convertToAutomation(elements) {
        const trigger = elements.find((el) => el.type === 'trigger');
        const steps = elements.filter((el) => el.type === 'action');

        // Preserve existing automation steps data
        const existingSteps = props.automation?.steps || [];

        return {
          ...props.automation,
          trigger_type: trigger?.data?.type || 'form_submission',
          trigger_details: trigger?.data?.config || { formId: null },
          form_id: trigger?.data?.config?.formId || null,
          steps: steps.map((step) => {
            // Find existing step to preserve action_details
            const existingStep = existingSteps.find((es) => es.id === step.id);
            return {
              id: step.id,
              action_type: step.data.type,
              action_details: step.data.config || existingStep?.action_details || {},
              configuration: step.data.config || existingStep?.configuration || {},
              previous_step_id: step.data.previousSteps?.[0] || null,
            };
          }),
        };
      }

      const NODE_VERTICAL_SPACING = 150;
      const NODE_START_X = 290;

      function arrangeNodes() {
        if (!elements.value.length) return;

        // Find trigger node (root)
        const triggerNode = elements.value.find((el) => el.type === 'trigger');
        if (!triggerNode) return;

        // Start with trigger node at top
        triggerNode.position = { x: NODE_START_X, y: 50 };

        // Helper function to recursively position nodes
        function positionChildren(nodeId, currentY) {
          const children = elements.value.filter((el) => el.data?.previousSteps?.[0] === nodeId);

          children.forEach((child, index) => {
            const previousNode = elements.value.find((el) => el.id === nodeId);
            const previousNodeElement = document.querySelector(
              `.vue-flow__node[data-id="${previousNode?.id}"]`
            );
            const previousNodeHeight = previousNodeElement?.offsetHeight || 0;

            child.position = {
              x: NODE_START_X,
              y: currentY + NODE_VERTICAL_SPACING, // Add 50px spacing after the node height
            };
            // Position this node's children
            positionChildren(child.id, currentY + NODE_VERTICAL_SPACING);
          });
        }

        // Start positioning from trigger node
        positionChildren(triggerNode.id, triggerNode.position.y);

        // Force a refresh of the elements
        elements.value = [...elements.value];
      }

      const onElementsChange = (newElements) => {
        if (isInitializing.value) {
          isInitializing.value = false;
          return;
        }
        elements.value = newElements;
        arrangeNodes(); // Add this line
        emitUpdate(newElements, 'onElementsChange');
      };

      const addAction = (action) => {
        const newNodeId = `action-${Date.now()}`;
        const newNode = {
          id: newNodeId,
          type: 'action',
          position: { x: NODE_START_X, y: 0 },
          data: {
            type: action.action,
            config: {},
            previousSteps: [action.afterId],
          },
        };

        addNodes(newNode);

        const previousNode = elements.value.find((el) => el.id === action.afterId);
        if (previousNode) {
          const newEdge = {
            id: `e${previousNode.id}-${newNodeId}`,
            source: previousNode.id,
            target: newNodeId,
          };
          addEdges(newEdge);
        }

        const newElements = [...elements.value, newNode];
        elements.value = newElements;
        arrangeNodes();
        emitUpdate(newElements, 'addAction');
      };

      // Initialize elements when component mounts
      elements.value = convertToElements(props.automation);
      arrangeNodes();

      // Watch for automation prop changes
      watch(
        () => props.automation,
        (newVal, oldVal) => {
          if (isInitializing.value || !newVal) return;

          // Only update if there's an actual change
          const newElements = convertToElements(newVal);
          if (JSON.stringify(newElements) !== JSON.stringify(elements.value)) {
            elements.value = newElements;
          }
        },
        { deep: true }
      );

      const getNodeTitle = (node) => {
        if (node?.type === 'trigger') {
          return TRIGGER_TYPES.find((t) => t.value === node.data.type)?.label || node.data.type;
        }
        return ACTION_TYPES.find((a) => a.value === node.data.type)?.label || node.data.type;
      };

      const hasNodeConnections = (nodeId) => {
        const node = elements.value.find((el) => el.id === nodeId);
        if (!node) return false;
        return getOutgoers({ id: nodeId, data: node.data }).length > 0;
      };

      const closeNodeSettings = () => {
        activeNode.value = null;
      };

      const refreshNode = () => {
        // Implement refresh logic
      };

      const deleteNode = () => {
        if (activeNode.value) {
          // Filter out the node and its connected edges
          elements.value = elements.value.filter((el) => {
            // Keep elements that aren't the active node
            if (el.id === activeNode.value.id) return false;
            // Remove any edges connected to this node
            if (
              'source' in el &&
              (el.source === activeNode.value.id || el.target === activeNode.value.id)
            )
              return false;
            return true;
          });

          // Emit the update
          emitUpdate(elements.value, 'deleteNode');
          closeNodeSettings();
        }
      };

      const updateNodeData = (newData, nodeId) => {
        // If nodeId is not provided, use activeNode.id
        const targetNodeId = nodeId || activeNode.value?.id;

        if (!targetNodeId) return;

        // Update node data logic
        elements.value = elements.value.map((el) => {
          if (el.id === targetNodeId) {
            // Create new node data preserving existing structure
            const updatedData = {
              ...el.data,
              type: newData.type,
              config: newData.config || {},
              previousSteps: newData.previousSteps || el.data.previousSteps,
            };

            return {
              ...el,
              data: updatedData,
            };
          }
          return el;
        });
        // Single emit after update
        emitUpdate(elements.value, 'updateNodeData');
      };

      const emitUpdate = debounce((elements, origin) => {
        // Skip if elements haven't changed
        if (
          lastEmittedElements.value &&
          JSON.stringify(elements) === JSON.stringify(lastEmittedElements.value)
        ) {
          return;
        }

        // Store current elements for future comparison
        lastEmittedElements.value = JSON.parse(JSON.stringify(elements));

        const newAutomation = convertToAutomation(elements);
        emit('update', newAutomation);
      }, 300);
      const onNodeClick = ({ node }) => {
        activeNode.value = node;
      };

      return {
        elements,
        onElementsChange,
        onNodesDragged: () => {},
        onNodesChanged: () => {},
        onEdgesChanged: () => {},
        addAction,
        activeNode,
        getNodeTitle,
        hasNodeConnections,
        closeNodeSettings,
        refreshNode,
        deleteNode,
        updateNodeData,
        onNodeClick,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .automation-builder {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    height: calc(100vh - 200px);
    border-top: 1px solid var(--slate-200);

    &__content {
      flex: 1;
      position: relative;
    }

    &__flow {
      height: 100%;
    }
    // &__drawer {
    //   position: relative;
    //   border-left: 1px solid var(--slate-200);
    //   min-width: 300px;
    // }
  }

  :deep(.vue-flow) {
    background: #f8f8f8;
  }
  :deep(.vue-flow__panel) {
    background: #ffffff;
  }
</style>
