<template>
  <div class="value-based-pricing">
    <div
      v-for="(rule, index) in mapping.valueRules"
      :key="index"
      class="rule-group mb-4 p-4 border rounded"
    >
      <div class="flex justify-between items-center">
        <h5 class="font-medium">Rule {{ index + 1 }}</h5>
        <Btn @click="removeRule(index)" variant="danger" size="sm">Remove Rule</Btn>
      </div>

      <!-- Conditions -->
      <div class="conditions mt-4">
        <h6 class="font-medium mb-2">Conditions</h6>
        <div v-for="(condition, condIndex) in rule.conditions" :key="condIndex" class="mb-4">
          <div class="grid grid-cols-3 gap-4">
            <SelectInput
              :label="'Field'"
              :options="formFields"
              v-model="condition.field"
              @update:model-value="emitUpdate"
            />
            <SelectInput
              :label="'Operator'"
              :options="operators"
              v-model="condition.operator"
              @update:model-value="emitUpdate"
            />
            <div>
              <TextInput
                v-if="condition.operator === 'equals' || condition.operator === 'greaterThanEqual'"
                :label="'Value'"
                v-model="condition.value"
                @update:model-value="emitUpdate"
              />
              <div v-if="condition.operator === 'between'" class="grid grid-cols-2 gap-2">
                <TextInput
                  label="Min"
                  type="number"
                  v-model="condition.minValue"
                  @update:model-value="emitUpdate"
                />
                <TextInput
                  label="Max"
                  type="number"
                  v-model="condition.maxValue"
                  @update:model-value="emitUpdate"
                />
              </div>
            </div>
          </div>
          <Btn @click="removeCondition(index, condIndex)" variant="danger" size="sm" class="mt-2">
            Remove Condition
          </Btn>
        </div>
        <Btn @click="addCondition(index)" variant="secondary" size="sm">Add Condition</Btn>
      </div>

      <!-- Calculation -->
      <div class="calculation mt-4">
        <h6 class="font-medium mb-2">Calculation</h6>
        <div class="grid grid-cols-3 gap-4">
          <TextInput
            label="Base Price"
            type="number"
            step="0.01"
            v-model="rule.calculation.basePrice"
            @update:model-value="emitUpdate"
          />
          <TextInput
            label="Multiplier"
            type="number"
            step="0.01"
            v-model="rule.calculation.multiplier"
            @update:model-value="emitUpdate"
          />
          <SelectInput
            label="Multiplier Field"
            :options="formFields"
            v-model="rule.calculation.multiplierField"
            @update:model-value="emitUpdate"
          />
        </div>
      </div>
    </div>
    <Btn @click="addRule" variant="secondary">Add Rule</Btn>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default defineComponent({
    name: 'ValueBasedPricing',

    components: {
      SelectInput,
      TextInput,
      Btn,
    },

    props: {
      mapping: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      formFields: {
        type: Array,
        required: true,
      },
    },

    setup(props, { emit }) {
      const operators = [
        { label: 'Equals', value: 'equals' },
        { label: 'Between', value: 'between' },
        { label: 'Greater Than or Equal', value: 'greaterThanEqual' },
      ];

      const addRule = () => {
        props.mapping.valueRules.push({
          conditions: [],
          calculation: {
            basePrice: 0,
            multiplier: 0,
            multiplierField: '',
          },
        });
        emitUpdate();
      };

      const removeRule = (index) => {
        props.mapping.valueRules.splice(index, 1);
        emitUpdate();
      };

      const addCondition = (ruleIndex) => {
        props.mapping.valueRules[ruleIndex].conditions.push({
          field: '',
          operator: 'equals',
          value: '',
        });
        emitUpdate();
      };

      const removeCondition = (ruleIndex, conditionIndex) => {
        props.mapping.valueRules[ruleIndex].conditions.splice(conditionIndex, 1);
        emitUpdate();
      };

      const emitUpdate = () => {
        emit('update', props.mapping);
      };

      return {
        operators,
        addRule,
        removeRule,
        addCondition,
        removeCondition,
        emitUpdate,
      };
    },
  });
</script>
