<template>
  <div class="pricing-view">
    <div class="container">
      <PageHeader title="Pricing Plans" description="Choose the plan that best fits your needs" />
      <div class="billing-toggle">
        <span :class="{ active: !isMonthly }">Yearly</span>
        <ToggleInput
          id="billing-period"
          label=""
          :modelValue="isMonthly"
          @update:modelValue="isMonthly = $event"
        />
        <span :class="{ active: isMonthly }">Monthly</span>
        <span class="savings-badge" v-if="!isMonthly">Save 20%</span>
      </div>
      <div class="pricing-table">
        <div v-for="tier in displayTiers" :key="tier.name" class="pricing-card">
          <!-- Tier Header -->
          <div class="pricing-card__header">
            <h3>{{ tier.name }}</h3>
            <div class="pricing-card__price">
              <span class="currency">€</span>
              <span class="amount">{{ tier.displayPrice }}</span>
              <span class="period">/{{ isMonthly ? 'month' : 'year' }}</span>
            </div>
            <div v-if="tier.duration" class="pricing-card__duration">
              {{ tier.duration }}
            </div>
            <Btn variant="primary" class="pricing-card__cta">
              {{ tier.price === 0 ? 'Start Free Trial' : 'Choose Plan' }}
            </Btn>
          </div>

          <!-- Tier Features -->
          <div class="pricing-card__features">
            <template v-for="(category, key) in tier.categories" :key="key">
              <div class="feature-category">
                <h4>{{ category.name }}</h4>
                <ul>
                  <li v-for="feature in category.features" :key="feature">
                    <div class="feature-category__icon"><BaseIcon name="check" size="12" /></div>
                    {{ feature }}
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import Btn from '@/components/partials/Btn.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';

  export default {
    name: 'SettingsBillingView',

    components: {
      PageHeader,
      Btn,
      BaseIcon,
      ToggleInput,
    },

    setup() {
      const isMonthly = ref(false);

      const baseTiers = ref([
        // {
        //   name: 'Free Trial',
        //   price: 0,
        //   duration: '14 days',
        //   categories: {
        //     formBuilding: {
        //       name: 'Form Building',
        //       features: ['All Pro features included'],
        //     },
        //   },
        // },
        {
          name: 'Starter',
          price: 49,
          categories: {
            formBuilding: {
              name: 'Form Building',
              features: [
                'Unlimited forms',
                'Multi-page forms',
                '50+ field types',
                'Basic field validation',
                'File upload fields',
                'Date & time fields',
                'Conditional logic',
                'Form templates',
                'Duplicate forms',
              ],
            },
            usage: {
              name: 'Usage',
              features: [
                '150 responses/month',
                'Unlimited users',
                'Basic form analytics',
                'CSV exports',
              ],
            },
            features: {
              name: 'Features',
              features: [
                'Email notifications',
                'Basic integrations',
                'Custom success page',
                'Basic support',
                'HTTPS encryption',
              ],
            },
          },
        },
        {
          name: 'Plus',
          price: 129,
          categories: {
            formBuilding: {
              name: 'Form Building',
              features: [
                'Custom CSS & fonts',
                'Custom domains',
                'Custom JavaScript',
                'Scheduling field',
                'Payment collection',
                'File attachments',
                'Password protection',
                'Hidden fields',
                'Custom redirects',
              ],
            },
            usage: {
              name: 'Usage',
              features: [
                '500 responses/month',
                'White labeling',
                'Advanced analytics',
                'JSON exports',
              ],
            },
            integrations: {
              name: 'Integrations',
              features: [
                'CRM integration',
                'Webhook support',
                'Zapier integration',
                'Priority support',
                'API access',
              ],
            },
          },
        },
        {
          name: 'Pro',
          price: 189,
          categories: {
            analytics: {
              name: 'Analytics',
              features: [
                'A/B testing',
                'Drop-off rates',
                'Form recovery',
                'Partial submissions',
                'User behavior tracking',
                'Custom reports',
                'Export scheduling',
              ],
            },
            advancedFeatures: {
              name: 'Advanced Features',
              features: [
                'Unlimited responses',
                'Automations & workflows',
                'Proposals generator',
                'Address autocomplete',
                'KVK autofill',
                'Custom API access',
                'Dedicated support',
                'SLA guarantee',
                'SAML SSO',
                'Advanced security features',
              ],
            },
          },
        },
      ]);
      const displayTiers = computed(() => {
        return baseTiers.value.map((tier) => ({
          ...tier,
          displayPrice: isMonthly.value
            ? Math.round(tier.price * 1.2) // 20% higher for monthly
            : tier.price,
        }));
      });
      return {
        baseTiers,
        isMonthly,
        displayTiers,
      };
    },
  };
</script>

<style scoped lang="scss">
  .pricing-view {
    padding: 40px 0;
  }

  .pricing-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 12px;
    margin-top: 40px;
    @include xl-down {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }

  .pricing-card {
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.2s;
    border: 1px solid var(--slate-200);

    &:hover {
      transform: translateY(-4px);
    }

    &__header {
      padding: 24px;
      text-align: center;
      background: var(--slate-50);
      border-bottom: 1px solid var(--slate-200);

      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
      }
    }

    &__price {
      font-size: 48px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 16px;

      .currency {
        font-size: 24px;
        vertical-align: super;
      }

      .period {
        font-size: 16px;
        font-weight: 400;
        color: var(--slate-500);
      }
    }

    &__duration {
      font-size: 14px;
      color: var(--slate-500);
      margin-bottom: 16px;
    }

    &__cta {
      width: 100%;
    }

    &__features {
      padding: 24px;
    }
  }

  .feature-category {
    & + & {
      margin-top: 24px;
    }
    &__icon {
      display: inline-block;
      margin-right: 8px;
      color: var(--green-500);
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background: var(--green-50);
      border: 1px solid var(--green-500);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
      color: var(--slate-700);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--slate-600);
      padding: 8px 0;

      i {
        color: var(--green-500);
        margin-right: 8px;
      }
    }
  }

  .billing-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 32px;

    span {
      font-size: 14px;
      color: var(--slate-500);

      &.active {
        color: var(--slate-900);
        font-weight: 500;
      }
    }

    .savings-badge {
      background: var(--green-100);
      color: var(--green-700);
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;
      margin-left: 8px;
    }
  }
</style>
