<template>
  <div class="message" :class="{ ['message--variant-' + variant]: variant }">
    <template v-if="message">{{ message }}</template>
    <slot v-else></slot>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        required: false,
      },
      variant: {
        type: String,
        required: false,
        default: 'info',
      },
    },
    setup() {},
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .message {
    padding: 0.25rem 0.5rem;
    background-color: var(--brand-100);
    border-radius: 4px;
    color: var(--brand-600);
    border: 1px solid var(--brand-500);
    @include fontSize('md');
    &--variant {
      &-neutral {
        background-color: var(--slate-100);
        color: var(--slate-600);
        border: 1px solid var(--slate-500);
      }
    }
  }
</style>
