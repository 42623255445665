<template>
  <div
    class="design-preset"
    :class="{ 'design-preset--active': isActive }"
    @click.stop="openConfirmModal"
  >
    <div class="design-preset__heading">
      <div class="design-preset__preview">
        <div class="design-preset__colors">
          <div
            class="design-preset__color"
            :style="{ backgroundColor: preset.colors.brand.primary }"
            title="Button"
          />
          <div
            class="design-preset__color"
            :style="{ backgroundColor: preset.colors.background.primary }"
            title="Background"
          />
          <div
            class="design-preset__color"
            :style="{ backgroundColor: preset.colors.text.default }"
            title="Text"
          />
          <div
            class="design-preset__color"
            :style="{ backgroundColor: preset.colors.text.element }"
            title="Choice"
          />
        </div>
        <div class="design-preset__font" :style="{ fontFamily: preset.font }">Aa</div>
      </div>
      <div class="design-preset__active">
        <BaseIcon name="check" size="12" />
      </div>
    </div>

    <div class="design-preset__info">
      <span class="design-preset__name">{{ preset.name }}</span>
      <BaseIcon name="edit" size="16" @click.stop="$emit('edit')" class="design-preset__edit" />
    </div>
  </div>
</template>

<script>
  import { useUIStore } from '@/store/uiStore';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  export default {
    name: 'DesignPreset',
    emits: ['click', 'edit', 'set-active'],
    components: {
      BaseIcon,
    },
    props: {
      preset: {
        type: Object,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['click', 'delete'],

    setup(props, { emit }) {
      const uiStore = useUIStore();

      const openConfirmModal = () => {
        if (props.isActive) return;
        const modalId = uiStore.addModal(false, {
          title: 'Set as active design',
          description: `Are you sure you want to set "${props.preset.name}" as the active design?`,
          submitText: 'Set as active',
          onSubmit: () => {
            emit('set-active');
            uiStore.closeModal(modalId);
          },
          onCancel: () => {
            uiStore.closeModal(modalId);
          },
          preset: props.preset,
        });
      };

      return {
        openConfirmModal,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .design-preset {
    padding: 0.75rem;
    border: 1px solid var(--slate-300);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border-color: var(--color-primary);
      background-color: var(--color-background-light);
    }

    &--active {
      border-color: var(--brand-500);
      box-shadow: 0 0 0 2px var(--color-primary-light);
      pointer-events: none;
    }

    &__preview {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    &__active {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 1px solid var(--slate-300);
      background-color: var(--slate-50);
      color: var(--slate-200);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: all 0.2s ease;

      .design-preset--active & {
        background-color: var(--brand-500);
        border-color: var(--brand-500);
      }
    }

    &__colors {
      display: flex;
      gap: 0.25rem;
    }

    &__color {
      width: 1rem;
      height: 1rem;
      border-radius: 0.25rem;
      border: 1px solid var(--color-border);
    }

    &__font {
      font-size: 1.25rem;
      min-width: 2rem;
      text-align: center;
    }

    &__radius {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid var(--color-primary);
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__name {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--color-text);
    }

    &__edit {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
</style>
