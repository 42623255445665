import { computed } from 'vue';

export function useDesignStyles(design) {
  const getBorderRadiusValue = (size) => {
    const radiusMap = {
      none: '0px',
      small: '4px',
      medium: '12px',
      large: '24px',
      xlarge: '40px',
      full: '9999px',
    };
    return radiusMap[size] || '8px';
  };

  // Create a style element for custom CSS
  const applyCustomCSS = (css) => {
    // Remove any existing custom style element
    const existingStyle = document.getElementById('custom-widget-styles');
    if (existingStyle) {
      existingStyle.remove();
    }

    let fontFaceCSS = '';
    // Add @font-face declaration if font object exists
    if (design?.font) {
      fontFaceCSS = `@font-face {
        font-family: '${design.font?.original_name?.split('.')[0]}';
        src: url('${design.font?.url}') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }\n\n`;
    }
    // Create and append new style element if CSS exists or if there's a font
    if (css || fontFaceCSS) {
      // Wrap all CSS with .fnnl and include font-face
      const wrappedCSS = `${fontFaceCSS}.fnnl {
    ${css || ''}
    ${design?.font ? `font-family: '${design.font?.original_name?.split('.')[0]}', sans-serif;` : ''}
  }`;

      const styleElement = document.createElement('style');
      styleElement.id = 'custom-widget-styles';
      styleElement.textContent = wrappedCSS;
      document.head.appendChild(styleElement);
    }
  };
  // Apply custom CSS if it exists
  if (design?.custom_css || design?.font) {
    applyCustomCSS(design?.custom_css);
  }

  // Helper function to determine text color based on background
  const getContrastColor = (hexColor) => {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return white for dark backgrounds, black for light backgrounds
    return luminance > 0.5 ? '#000000' : '#ffffff';
  };

  const mainColor = design?.colors?.brand?.primary || '#4f46e5';
  const backgroundColor = design?.colors?.background.primary || '#f8fafc';
  const textColor = design?.colors?.text.default || '#1e293b';
  const answersColor = design?.colors?.text.element || '#1e293b';
  // Mix a tiny bit of the main color with white for answers background
  const answersBackground = mainColor ? adjustColorBrightness(backgroundColor, 10) : '#ffffff';

  const buttonTextColor = getContrastColor(mainColor);
  const hoverMainColor = adjustColorBrightness(mainColor, -10);

  const designStyles = {
    // Brand color
    '--cs-brand-primary': mainColor,

    // Background colors
    '--cs-background-primary': backgroundColor,

    // Button colors
    '--cs-button-default-background': mainColor,
    '--cs-button-default-text': buttonTextColor,
    '--cs-button-hover-background': hoverMainColor,
    '--cs-button-hover-text': buttonTextColor,

    // Text colors
    '--cs-text-heading': textColor,
    '--cs-text-body': textColor,

    // Inputs
    '--cs-input-default-background': answersBackground,
    '--cs-input-default-text': answersColor,
    '--cs-input-default-border': adjustColorBrightness(backgroundColor, -5),

    // Choice/Answer colors
    '--cs-choice-default-background': answersBackground,
    '--cs-choice-default-text': answersColor,
    '--cs-choice-default-border': adjustColorBrightness(backgroundColor, -5), // 25% opacity of main color

    // '--cs-choice-hover-background': adjustColorBrightness(backgroundColor, 80),
    '--cs-choice-hover-background': mainColor,
    '--cs-choice-hover-text': buttonTextColor,
    '--cs-choice-hover-border': adjustColorBrightness(backgroundColor, -35),

    '--cs-choice-active-background': mainColor,
    '--cs-choice-active-text': buttonTextColor,
    '--cs-choice-active-border': mainColor,

    // Font and border radius
    '--cs-border-radius': getBorderRadiusValue(design?.border_radius || 'medium'),
    '--cs-font-family': design?.font
      ? `'${design.font?.original_name?.split('.')[0]}', sans-serif`
      : `${design?.font || 'Inter'}, sans-serif`,
  };

  return {
    designStyles,
  };
}

// Helper function to adjust color brightness
function adjustColorBrightness(hex, percent) {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Adjust brightness
  r = Math.max(0, Math.min(255, r + (r * percent) / 100));
  g = Math.max(0, Math.min(255, g + (g * percent) / 100));
  b = Math.max(0, Math.min(255, b + (b * percent) / 100));

  // Convert back to hex
  return `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g).toString(16).padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`;
}
