<template>
  <div class="form-viewer-page">
    <FormPreview :form-id="formId" />
  </div>
</template>

<script>
  import { useRoute } from 'vue-router';
  import FormPreview from '@/pages/public/FormPreview.vue';

  export default {
    components: { FormPreview },
    setup() {
      const route = useRoute();
      const formId = route.params.id;

      return { formId };
    },
  };
</script>

<style scoped lang="scss">
  .form-viewer-page {
    // Your styles here
  }
</style>
