// // src/constants/elements.js
// export const ELEMENTS = [
//   { type: 'single-choice', label: 'Single Choice', slug: 'single_choice', category: 'choices' },
//   {
//     type: 'multiple-choice',
//     label: 'Multiple Choice',
//     slug: 'multiple_choice',
//     category: 'choices',
//   },
//   { type: 'dropdown', label: 'Dropdown', slug: 'dropdown', category: 'choices', comingSoon: true },
//   {
//     type: 'short-answer',
//     label: 'Short Answer',
//     slug: 'short_answer',
//     category: 'collecting_data',
//   },
//   {
//     type: 'long-answer',
//     label: 'Long Answer',
//     slug: 'long_answer',
//     category: 'collecting_data',
//     comingSoon: true,
//   },
//   { type: 'number', label: 'Number', slug: 'number', category: 'collecting_data' },
//   {
//     type: 'currency',
//     label: 'Currency',
//     slug: 'currency',
//     category: 'collecting_data',
//     comingSoon: true,
//   },
//   { type: 'name', label: 'Name', slug: 'name', category: 'contact_data' },
//   { type: 'phone', label: 'Phone', slug: 'phone', category: 'contact_data' },
//   { type: 'email', label: 'Email', slug: 'email', category: 'contact_data' },
//   {
//     type: 'address',
//     label: 'Address',
//     slug: 'address',
//     category: 'contact_data',
//     comingSoon: true,
//   },
//   { type: 'heading', label: 'Heading', slug: 'heading', category: 'content' },
//   { type: 'text', label: 'Text', slug: 'text', category: 'content' },
// ];

export const ELEMENTS = [
  // Input Controls
  { type: 'short-answer', label: 'Short Answer', slug: 'short_answer', category: 'text' },
  { type: 'long-answer', label: 'Long Answer', slug: 'long_answer', category: 'text' },

  // Numbers
  { type: 'number', label: 'Number', slug: 'number', category: 'numbers' },
  { type: 'slider', label: 'Slider', slug: 'slider', category: 'numbers' },
  { type: 'currency', label: 'Currency', slug: 'currency', category: 'numbers', comingSoon: true },

  // Choices
  { type: 'single-choice', label: 'Single Choice', slug: 'single_choice', category: 'choices' },
  {
    type: 'multiple-choice',
    label: 'Multiple Choice',
    slug: 'multiple_choice',
    category: 'choices',
  },
  {
    type: 'picture-choice',
    label: 'Picture Choice',
    slug: 'picture_choice',
    category: 'choices',
  },
  { type: 'dropdown', label: 'Dropdown', slug: 'dropdown', category: 'choices', comingSoon: true },

  // Selection
  { type: 'switch', label: 'Switch', slug: 'switch', category: 'options', comingSoon: true },
  { type: 'checkbox', label: 'Checkbox', slug: 'checkbox', category: 'options', comingSoon: true },

  // Date & Time
  { type: 'date', label: 'Date', slug: 'date', category: 'date', comingSoon: true },
  { type: 'time', label: 'Time', slug: 'time', category: 'date', comingSoon: true },

  // Special Inputs
  { type: 'formula', label: 'Formula', slug: 'formula', category: 'special' },
  {
    type: 'color-picker',
    label: 'Color Picker',
    slug: 'color_picker',
    category: 'special',
    comingSoon: true,
  },
  {
    type: 'file-upload',
    label: 'File Upload',
    slug: 'file_upload',
    category: 'special',
    comingSoon: true,
  },
  {
    type: 'scheduling',
    label: 'Scheduling',
    slug: 'scheduling',
    category: 'special',
    // comingSoon: true,
  },
  {
    type: 'payment',
    label: 'Payment',
    slug: 'payment',
    category: 'special',
    comingSoon: true,
  },

  // Contact Information
  { type: 'name', label: 'Name', slug: 'name', category: 'contact' },
  { type: 'phone', label: 'Phone', slug: 'phone', category: 'contact' },
  { type: 'email', label: 'Email', slug: 'email', category: 'contact' },
  {
    type: 'address',
    label: 'Address',
    slug: 'address',
    category: 'contact',
    comingSoon: true,
  },

  // Content Elements
  { type: 'heading', label: 'Heading', slug: 'heading', category: 'content' },
  { type: 'text', label: 'Text', slug: 'text', category: 'content' },
];
