<template>
  <div
    class="form-group"
    :class="{
      'form-group--has-error': localError,
      'form-group--has-success': !localError && touched,
    }"
  >
    <label :for="id">{{ label }}</label>
    <div class="currency-input">
      <money3 :id="id" v-model="localValue" v-bind="money3Options" @blur="handleBlur" />
    </div>
    <span v-if="localError" class="form-group__error">{{ localError }}</span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { Money3 } from 'v-money3';
  import { validateInput } from '@/utils/validation';

  export default {
    name: 'CurrencyInput',
    components: {
      Money3,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: [Number, String],
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: () => [],
      },
    },
    emits: ['update:modelValue', 'validate'],

    setup(props, { emit }) {
      const localError = ref(props.error);
      const touched = ref(false);
      const localValue = ref(props.modelValue);

      const money3Options = {
        prefix: '€ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        masked: false,
        disableNegative: true,
        min: 0,
      };

      watch(
        () => localValue.value,
        (newValue) => {
          emit('update:modelValue', parseFloat(newValue));
          validate();
        }
      );

      const handleBlur = () => {
        touched.value = true;
        validate();
      };

      const validate = () => {
        const errorMessage = validateInput(localValue.value, props.rules);
        localError.value = errorMessage;
        emit('validate', errorMessage);
      };

      watch(
        () => props.modelValue,
        (newValue) => {
          localValue.value = newValue;
        }
      );

      watch(
        () => props.error,
        (newError) => {
          localError.value = newError;
        }
      );

      return {
        localValue,
        localError,
        touched,
        money3Options,
        handleBlur,
      };
    },
  };
</script>

<style scoped lang="scss"></style>
