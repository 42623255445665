import { defineStore } from 'pinia';
import { crmService } from '@/services/crmService';

export const useCrmStore = defineStore('crm', {
  state: () => ({
    leads: [],
    currentLead: null,
    loading: false,
    error: null,
    pagination: {
      total: 0,
      page: 1,
      limit: 20,
      totalPages: 0,
    },
    proposals: [],
  }),

  getters: {
    getLeadById: (state) => (id) => {
      return state.leads.find((lead) => lead.id === id);
    },
  },

  actions: {
    async fetchLeads(params = {}) {
      try {
        this.loading = true;
        const response = await crmService.getLeads(params);
        this.$patch((state) => {
          state.leads = response.leads;
          state.pagination = response.pagination;
          state.error = null;
        });
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async loadLead(id) {
      try {
        this.loading = true;
        const lead = await crmService.getLeadById(id);
        this.$patch((state) => {
          state.currentLead = lead;
          state.error = null;
        });
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateLead(id, leadData) {
      try {
        this.loading = true;
        const updatedLead = await crmService.updateLead(id, leadData);

        const index = this.leads.findIndex((l) => l.id === id);
        if (index !== -1) {
          this.leads[index] = updatedLead;
        }

        if (this.currentLead?.id === id) {
          this.currentLead = updatedLead;
        }

        this.error = null;
        return updatedLead;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteLead(id) {
      try {
        this.loading = true;
        await crmService.deleteLead(id);

        this.$patch((state) => {
          state.leads = state.leads.filter((lead) => lead.id !== id);
          if (state.currentLead?.id === id) {
            state.currentLead = null;
          }
          state.error = null;
        });
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    clearCurrentLead() {
      this.currentLead = null;
    },

    async addLeadActivity(leadId, activity) {
      try {
        this.loading = true;
        const newActivity = await crmService.addLeadActivity(leadId, activity);
        this.$patch((state) => {
          state.currentLead.activities.push(newActivity);
          state.error = null;
        });
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
});
