import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useToastStore = defineStore('toast', () => {
  const toasts = ref([]);

  const addToast = ({ message, type = 'info', duration = 3000 }) => {
    const id = Date.now();
    toasts.value.push({
      id,
      message,
      type,
      show: true,
    });

    // Auto remove toast after duration
    setTimeout(() => {
      closeToast(id);
    }, duration);

    return id;
  };

  const removeToast = (id) => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  };

  const closeToast = (id) => {
    const toast = toasts.value.find((toast) => toast.id === id);
    if (toast) {
      toast.show = false;
      setTimeout(() => {
        removeToast(id);
      }, 300);
    }
  };

  return {
    toasts,
    addToast,
    removeToast,
    closeToast,
  };
});
