<template>
  <div class="sf-el name-input">
    <div class="sf-name">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      {{ modelValue }}
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'FormulaElement',
    props: {
      label: String,
      placeholder: String,
      name: String,
      modelValue: String,
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      return {};
    },
  };
</script>

<style scoped lang="scss">
  .sf-short-answer {
  }
</style>
